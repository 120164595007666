import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import CardPanel from '../../components/CardPanel';
import Login from '../../components/Login/Login';
import ScrollToTop from '../../components/ScrollToTop';

export const LoginPage = () => {
    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <ScrollToTop />
            <Container className="my-5">
                <Row>
                    <Col sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
                        <CardPanel title="Log in to your account">
                            <Login />
                        </CardPanel>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default LoginPage;
