import React from 'react';
import { Container, Button } from 'react-bootstrap';
import Logo from './components/Logo';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    // componentDidCatch(error, errorInfo) {
    //     // You can also log the error to an error reporting service
    //     logErrorToMyService(error, errorInfo);
    // }

    onClearCache() {
        setTimeout(() => {
            sessionStorage.removeItem('redux');
            window.location.reload();
        }, 100);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Container>
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="text-center">
                            <p>
                                <Logo height={50} />
                            </p>
                            <p className="h2 mt-4">We are sorry but we have encountered an error.</p>

                            <p className="mb-4">
                                Please click the button below to clear out any cached data and try again.
                            </p>
                            <Button onClick={this.onClearCache}>Clear cache and retry</Button>

                            <p className="mt-4">If the problem persists, please contact us:</p>
                            <p>
                                <a href="tel:03448484534">0344 84 84 534</a>
                                <br />
                                <a href="mailto:enquiries@breakfreeholidays.co.uk">enquiries@breakfreeholidays.co.uk</a>
                            </p>
                        </div>
                    </div>
                </Container>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
