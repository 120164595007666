import { createSelector } from 'reselect';

import { operators } from '../data/operators/selectors';
import { parkDetails } from '../data/park/details/selectors';
import { parkResults } from '../data/park/results/selectors';
import { parkReviews } from '../data/park/reviews/selectors';
import { extraData, unitTypes } from '../data/unitTypes/selectors';

import { getOperatorById } from '../data/operators/utils';
import { getParkDetailsById } from '../data/park/details/utils';
import { getParkResultsById } from '../data/park/results/utils';
import { getParkReviewsById } from '../data/park/reviews/utils';
import { getAllUnitTypeDataById } from '../data/unitTypes/utils';

import { dateToDDMMYYYY, DDMMYYYYToDate } from '../../utils/date';

function getResultsData(result, date, unitTypes, extraData) {
    const nights = Object.keys(result)[0];
    const units = result[nights].map((unit) => ({
        ...unit,
        ...(getAllUnitTypeDataById(unitTypes, extraData, unit.unitType) || {}),
        arrivalDate: date,
        nights,
    }));

    return {
        date,
        nights,
        lowestPrice: units.map((unit) => +unit.unitPrice).sort((a, b) => a - b)[0],
        units,
    };
}

export const channelId = (state) => state.park.channelId;
export const parkId = (state) => state.park.parkId;
export const selectedDate = (state) => state.park.selectedDate;

export const details = (state) => getParkDetailsById(parkDetails(state), parkId(state));
export const results = (state) => getParkResultsById(parkResults(state), parkId(state));
export const reviews = (state) => getParkReviewsById(parkReviews(state), parkId(state));

export const operator = createSelector(details, operators, (_details, _operators) => {
    return getOperatorById(_operators, _details?.opId);
});

export const processedResults = createSelector(
    results,
    selectedDate,
    unitTypes,
    extraData,
    (_parkResults, _selectedDateStr, _unitTypes, _extraData) => {
        const _selectedDate = new Date(_selectedDateStr);
        const results = _parkResults?.results;
        if (!results) return undefined;

        const all = {};
        const alternativeDates = {};

        Object.keys(results).forEach((dateKey) => {
            all[dateKey] = getResultsData(results[dateKey], DDMMYYYYToDate(dateKey), _unitTypes, _extraData);
            if (isNaN(_selectedDate.getTime()) || DDMMYYYYToDate(dateKey).getTime() !== _selectedDate.getTime()) {
                alternativeDates[dateKey] = all[dateKey];
            }
        });

        return {
            all: Object.keys(all).length ? all : undefined,
            alternativeDates: Object.keys(alternativeDates).length ? alternativeDates : undefined,
            selectedDate: !isNaN(_selectedDate.getTime()) ? all[dateToDDMMYYYY(_selectedDate)] : undefined,
        };
    },
);
