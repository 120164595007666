import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';

import AccommodationModal from './AccommodationModal';
import t from '../../../../text';
import { config } from '../../../../config';
import { Alert } from 'react-bootstrap';
import Icon from '../../Icon';
import Slider from 'react-slick';
function AccommodationListing({ holiday, onBook }) {
    const [show, setShow] = useState(false);
    const [images, setImages] = useState();

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false
    };

    let unitFeatures = [];
    if (Array.isArray(holiday.features)) {
        holiday.features.forEach((feature) => {
            unitFeatures.push(
                <div key={feature}>
                    <i className="icon-check text-secondary mr-1"></i>
                    {feature}
                </div>,
            );
        });
    }

    let unitDescription = false;
    if (holiday.desc) {
        let descWordArray = holiday.desc.replace(/\s\s+/g, ' ').split(' ');
        let wordsLength = descWordArray.length;
        unitDescription = descWordArray
            .splice(0, 24)
            .join(' ')
            .replace(/[^a-zA-Z0-9]*$/g, '');
        unitDescription += wordsLength > 24 ? '...' : '.';
    }

    useEffect(() => {
        if (holiday.image !== undefined) {
            setImages(
                holiday.image.map((img, i) => (
                    <img
                        key={i + 1}
                        className="img-fluid"
                        src={`https://content.breakfreeholidays.co.uk/images/accom-types/${img.replace("https://content.breakfreeholidays.co.uk/images/accom-types/", '')}`}
                        alt={`Accommodation ${i}`}
                        loading="lazy"
                    />
                )),
            );
        }
    }, [holiday.image]);

    return (
        <Row noGutters className="border accommodation-listing mb-3">
            <Col
                md={6}
                lg={5}
                className={`accommodation-listing-image ${images !== undefined ? '' : 'd-none'}`}
            >
                <Slider {...settings}>{images}</Slider>

                {+holiday.unitQuantity < 5 && (
                    <span className="accommodation-listing-remaining bg-secondary text-sm p-2">
                        {t('park.accommodation.onlyXLeft', holiday.unitQuantity)}
                    </span>
                )}
            </Col>

            <Col>
                {images === undefined && +holiday.unitQuantity < 5 && (
                    <span className="accommodation-listing-remaining bg-secondary text-sm p-2">
                        {t('park.accommodation.onlyXLeft', holiday.unitQuantity)}
                    </span>
                )}
                <div className="p-3">
                    <h3 className="mb-3">{holiday.unitDesc}</h3>
                    <p>
                        {/* <i className={`icon-bed text-${config['global.textHighlight']} h4`}></i>{' '}
                        {t('park.accommodation.sleepsX', holiday.unitMaxOccupancy)} */}
                        {holiday.unitDisabled === 'Y' && (
                            <span>
                                <i
                                    className={`icon-disabled-access h4 text-${config['global.textHighlight']} ml-3`}
                                ></i>{' '}
                                {t('park.accommodation.disabledAccess')}
                            </span>
                        )}
                        {holiday.unitAdultsOnly === 'Y' && (
                            <Alert variant="warning" className="p-2 mt-3 text-sm">
                                <Icon icon="attention-alt" theme="secondary" size="h4" className="mr-2" />
                                Please Note: This unit is adults only.
                            </Alert>
                        )}
                    </p>

                    {unitDescription && <p>{unitDescription}</p>}

                    {holiday.desc && (
                        <>
                            <p>
                                <Button variant="alt-primary" size="sm" className="border" onClick={() => setShow(true)}>
                                    {t('buttons.readMore')}
                                </Button>
                            </p>
                            <AccommodationModal
                                holiday={holiday}
                                unitFeatures={unitFeatures}
                                onHide={() => setShow(false)}
                                show={show}
                                images={images}
                            />
                        </>
                    )}
                </div>

                <Row
                    noGutters
                    className="d-flex align-self-end align-items-center bg-lighter border-top  p-3"
                >
                    <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                        {+holiday.unitPriceSave ? (
                            <div>
                                <p className="text-uppercase text-danger font-weight-bold mb-0 old-price">
                                    {t('park.accommodation.wasX', parseFloat(holiday.unitPrice) + parseFloat(holiday.unitPriceSave))}
                                </p>
                                <p className="text-uppercase text-success font-weight-bold saving mb-0">
                                    <i className="icon-check h5 text-success"></i>{' '}
                                    {t('park.accommodation.saveX', holiday.unitPriceSave)}
                                </p>
                            </div>
                        ) : null}
                    </Col>
                    <Col xs={6} lg={3} className="mb-3 mb-lg-0"></Col>
                    <Col lg={6}>
                        <Button className="btn btn-primary btn-block py-2 mt-3 mt-md-0 text-uppercase" onClick={onBook}>
                            {t('buttons.book', holiday.unitPrice)}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

AccommodationListing.propTypes = {
    holiday: PropTypes.object,
    onBook: PropTypes.func,
};

AccommodationListing.defaultProps = {};

export default AccommodationListing;
