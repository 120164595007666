import React from 'react';
import { useSelector } from 'react-redux';

import RedEye from './RedEye';

import { regions } from '../../redux/data/regions/selectors';
import { pendingParams } from '../../redux/search/results/selectors/selectors';
import { dateToDDMMYYYY } from '../../utils/date';
import { config } from '../../../config';

import { getRegionById } from '../../redux/data/regions/utils';

export const RedEyeStartSearchTag = () => {
    const _pendingParams = useSelector(pendingParams);
    const _region = getRegionById(useSelector(regions), _pendingParams.regionId);
    const searchInProgress = Object.keys(_pendingParams).length > 0;

    if (searchInProgress) {
        return (
            <RedEye
                params={{
                    nourl: 'search-confirm-selections',
                    search_event: 'search_event',
                    search_type: _pendingParams.channelId,
                    search_url: window.location.href.split('//')[1],
                    // search_destinationa: _pendingParams.channelId,
                    search_destinationb: _region?.name,
                    search_datea: dateToDDMMYYYY(new Date(_pendingParams.arrivalDate)),
                    search_nights: _pendingParams.duration,
                    search_pax: _pendingParams.adults + _pendingParams.children + _pendingParams.under5s,
                    search_adults: _pendingParams.adults,
                    search_children: _pendingParams.children,
                    search_under5: _pendingParams.under5s,
                    last_search: config['siteCode'] === 'BF' ? 'direct' : config['siteCode'],
                }}
            />
        );
    }

    return null;
};

export default RedEyeStartSearchTag;
