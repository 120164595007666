import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePayPalScriptReducer } from '@paypal/react-paypal-js';

import PaypalCardPaymentForm from '../../../components/Forms/Booking/PaypalCardPaymentForm/PaypalCardPaymentForm';
import Spinner from '../../../components/Spinner';

import countries from '../../../Data/Countries.json';
import { details } from '../../../redux/user/selectors';
import { usePaypalCardPaymentFormState } from '../../../components/Forms/Booking/PaypalCardPaymentForm/usePaypalCardPaymentForm';

import { config } from '../../../../config';
import t from '../../../../text';

const checkPaypalComponentsAreLoaded = (options) => {
    const components = (options?.components || '').split(',');
    if (!components.includes('hosted-fields') || !components.includes('funding-eligibility')) {
        let neededComponents = [];
        if (!components.includes('hosted-fields')) neededComponents.push('hosted-fields');
        if (!components.includes('funding-eligibility')) neededComponents.push('funding-eligibility');
        neededComponents = neededComponents.join(',');

        console.error(
            `[CardPayment] - Paypal setup error: You need to add ${neededComponents} to the options parameter of your <PayPalScriptProvider/> tag.`,
        );
        return false;
    }

    return true;
};

const get3DSecureOutcome = ({ authenticationReason, authenticationStatus, liabilityShifted }) => {
    // See https://developer.paypal.com/docs/business/checkout/add-capabilities/3d-secure/#deprecated-3d-secure-parameters
    const states = [
        { lShifted: undefined, authStatus: undefined, authReason: undefined, result: true },
        { lShifted: true, authStatus: 'YES', authReason: 'SUCCESSFUL', result: true },
        { lShifted: false, authStatus: 'ERROR', authReason: 'ERROR', result: false },
        { lShifted: false, authStatus: 'NO', authReason: 'SKIPPED_BY_BUYER', result: false },
        { lShifted: false, authStatus: 'NO', authReason: 'FAILURE', result: false },
        { lShifted: false, authStatus: 'NO', authReason: 'BYPASSED', result: true },
        { lShifted: false, authStatus: 'NO', authReason: 'ATTEMPTED', result: true },
        { lShifted: false, authStatus: 'NO', authReason: 'UNAVAILABLE', result: true },
        { lShifted: false, authStatus: 'NO', authReason: 'CARD_INELIGIBLE', result: true },
        { lShifted: true, authStatus: 'YES', authReason: 'ERROR', result: true },
    ];

    const currentState = states.find((state) => {
        return (
            state.lShifted === liabilityShifted &&
            state.authStatus === authenticationStatus &&
            state.authReason === authenticationReason
        );
    });

    return currentState ? currentState : false;
};

const getErrorMessage = (err) => {
    if (Array.isArray(err?.details)) {
        return (
            <ul className="mt-3 text-danger">
                {err.details.map((detail) => (
                    <li key={`${typeof detail.field !== 'undefined' ? detail.field : 'ERROR'}${detail.issue}`}>
                        {`${typeof detail.field !== 'undefined' ? detail.field.replace('/payment_source/card/', '') : 'ERROR'}`} - {detail.description} ({detail.issue})
                    </li>
                ))}
            </ul>
        );
    }

    const msg = typeof err === 'string' ? err : t('booking.payment.errorSubmittingForm');

    return <p className="text-danger mt-3">{msg}</p>;
};

export const CardPayment = ({ disabled, onSuccess }) => {
    const [firstRun, setFirstRun] = useState(true);
    const [paymentError, setPaymentError] = useState(undefined);
    const [paypalForm, setPaypalForm] = useState(undefined);
    const [processing, setProcessing] = useState(false);

    const userDetails = useSelector(details);
    const { values, onChange, validate, isValid, errors, amount, bookingId, paypal } = usePaypalCardPaymentFormState(
        paypalForm,
    );
    const [{ isResolved, options }] = usePayPalScriptReducer();

    // Event handlers
    const handleCardFormSubmit = () => {
        validate();
        if (isValid() && paypalForm) {
            submitPaypalForm();
        }
    };

    const handlePaypalInit = useCallback(() => {
        if (isResolved) {
            if (checkPaypalComponentsAreLoaded(options)) {
                window.paypal.HostedFields.render({
                    createOrder: function () {
                        return paypal.orderId;
                    },
                    styles: {
                        input: {
                            'font-size': '16px',
                            'font-family':
                                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            color: '#5B5B5A',
                        },
                    },
                    fields: {
                        number: {
                            selector: '#cardNumContainer',
                            internalLabel: t('booking.payment.cardNumber'),
                            maskInput: true,
                            placeholder: 'Card Number',
                        },
                        expirationDate: {
                            selector: '#expiryDateContainer',
                            internalLabel: t('booking.payment.expirationDate'),
                            maskInput: true,
                            placeholder: 'Expiry Date - mm/yyyy',
                        },
                        cvv: {
                            selector: '#cvvContainer',
                            internalLabel: t('booking.payment.cardSecurityNumber'),
                            type: 'password',
                            placeholder: '3 Digit Security Number',
                        },
                    },
                }).then(function (hf) {
                    setPaypalForm(hf);
                });
            }
        }
    }, [options, isResolved, paypal.orderId]);

    const submitPaypalForm = () => {
        const address = values.billingAddress ? values : userDetails;
        const country = values.billingAddress ? values.country : countries[userDetails.country].iso2;

        console.info(config['payment.enable3DSecure'] ? '✔ 3DSecure enabled!' : '❌ 3DSecure disabled.');

        setPaymentError(undefined);
        setProcessing(true);
        paypalForm
            .submit({
                // Cardholder Name
                cardholderName: values.name,
                // Billing Address
                billingAddress: {
                    streetAddress: address.addrLn1,
                    // extendedAddress: address.addrLn1, // address_line_2 - unit
                    region: address.county, // admin_area_1 - state
                    locality: address.town, // admin_area_2 - town / city
                    postalCode: address.postCode, // postal_code - postal_code
                    countryCodeAlpha2: country, // country_code - country
                },
                contingencies: config['payment.enable3DSecure'] ? ['3D_SECURE'] : [],
            })
            .then((resp) => {
                setFirstRun(false);
                setProcessing(false);

                if (config['payment.enable3DSecure']) {
                    const passed3DSecure = get3DSecureOutcome(resp);

                    console.info('3Dsecure response: ', resp);

                    if (passed3DSecure.result === true) {
                        console.info('3DSecure passed.');
                        onSuccess(resp);
                    } else {
                        console.info('3DSecure failed.');
                        switch (passed3DSecure.authReason) {
                            //case undefined:
                            //    setPaymentError('3D Secure did not respond correctly, please try again.');
                            //    break;
                            case 'ERROR':
                                setPaymentError('There was an error with 3D Secure, please try again.');
                                break;
                            case 'FAILURE':
                                setPaymentError('3D Secure authentication failed, please try again.');
                                break;
                            case 'SKIPPED_BY_BUYER':
                                setPaymentError('3D Secure cannot be skipped, please try again.');
                                break;
                            default:
                                setPaymentError(t('booking.payment.error3DSecure'));
                                break;
                        }
                    }
                } else {
                    console.info('3DSecure skipped.  Is 3DSecure enabled?');
                    onSuccess(resp);
                }
            })
            .catch(function (err) {
                setPaymentError(err);
                setFirstRun(false);
                setProcessing(false);
            });
    };

    // Mark as first run if booking changes / on page refresh
    useEffect(() => setFirstRun(true), [amount, bookingId]);

    // Re-init the form if the booking changes
    useEffect(() => handlePaypalInit(), [amount, bookingId, isResolved, handlePaypalInit]);

    if (isResolved) {
        return (
            <>
                <PaypalCardPaymentForm
                    amount={amount}
                    busy={disabled || processing}
                    errors={errors}
                    onChange={onChange}
                    onSubmit={handleCardFormSubmit}
                    values={values}
                />
                {!firstRun && paymentError && getErrorMessage(paymentError)}
            </>
        );
    } else {
        return <Spinner message={t('booking.payment.initialisingCardForm')} />;
    }
};

export default CardPayment;
