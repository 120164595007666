import React from 'react';
import ReactDOM from 'react-dom';
import './base/index.css';
import App from './base/App';
import ErrorBoundary from './base/ErrorBoundary';
import Maintenance from './base/maintenance';
import * as serviceWorker from './base/serviceWorker';

import { Amplify } from 'aws-amplify';
import config from './base/assets/js/aws-config';

import Reducer from './base/Reducers/Reducer';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import { initGTM } from './base/utils/tracking';
import { config as siteConfig } from './config';

import { HelmetProvider } from 'react-helmet-async';

if (typeof siteConfig['google.gtm'] !== 'undefined') initGTM(siteConfig['google.gtm']);

const EnableMaintenanceMode = false;
const store = createStore(Reducer);

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
    API: {
        endpoints: [
            {
                name: 'notes',
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION,
            },
        ],
    },
});

ReactDOM.render(
    document.cookie.indexOf('GrantUserAccess=') < 0 && EnableMaintenanceMode ? (
        <React.StrictMode>
            <Maintenance />
        </React.StrictMode>
    ) : (
        <React.StrictMode>
            <HelmetProvider>
                <Provider store={store}>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </Provider>
            </HelmetProvider>
        </React.StrictMode>
    ),
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
