import { APP_INIT } from '../init/action-types';
import { CANCELLED, FAILURE, REQUEST, RESET, SUCCESS } from '../constants';

const initialState = {
    fetching: [],
    errors: {},
};

export const apiReducerFactory = (actionType) => (state = initialState, action) => {
    switch (action.type) {
        case APP_INIT:
        case actionType + RESET:
            return initialState;
        case actionType + REQUEST:
            const errors = { ...state.errors };
            delete errors[action.payload.requestId];

            return {
                fetching: [...state.fetching, action.payload.requestId],
                errors,
            };
        case actionType + SUCCESS:
        case actionType + CANCELLED:
            return {
                ...state,
                fetching: state.fetching.filter((id) => id !== action.payload.requestId),
            };
        case actionType + FAILURE:
            const output = {
                fetching: state.fetching.filter((id) => id !== action.payload.requestId),
                errors: {
                    ...state.errors,
                    [action.payload.requestId]: action.payload,
                },
            };
            delete output.errors[action.payload.requestId].params.passwd; // Do not store passwords
            return output;
        default:
            return state;
    }
};

export default apiReducerFactory;
