import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import t from '../../../text';
import starWhite from '../../assets/images/white-star.svg';

export const HeaderBar = ({ loggedIn, onLogout, onShowLogin }) => {
    const location = useLocation();
    return (
        <div className="header-bar bg-primary border-bottom p-2 pl-4 d-flex justify-content-between flex-wrap">
            {!loggedIn ? (
                <>
                    <button onClick={onShowLogin} className="remove-button-styling text-white cursor-pointer mr-3">
                        <i className="icon-sign-in"></i> {t('header.myAccount')}
                    </button>
                </>
            ) : (
                <>
                    <button onClick={onLogout} className="remove-button-styling text-white cursor-pointer">
                        <i className="icon-previous"></i> {t('header.logout')}
                    </button>
                    {location.pathname.includes('/booking/') ? (
                        <button onClick={() => window.open("/my-account")} className="remove-button-styling text-white ml-3">
                            <i className="icon-user"></i> {t('header.myAccount')}
                        </button>
                    ) : (
                        <NavLink to="/my-account" className="ml-3 text-decoration-none text-white">
                            <i className="icon-user"></i> {t('header.myAccount')}
                        </NavLink>
                    )}
                </>
            )}

            {location.pathname.includes('/booking/') && (
                <a href={`tel:${t('header.cs.tel')}`} className="ml-auto text-white">
                    <i className="icon-phone"></i> {t('header.cs.tel')}
                </a>
            )}

            <a href="https://uk.trustpilot.com/review/www.breakfreeholidays.co.uk?stars=5" target="_blank" rel="noopener" className='d-none d-md-flex align-items-center ml-4 text-white'>
                See our reviews on <img src={starWhite} alt="Trustpilot star" width="22px" className='mx-1 mb-1' /> Trustpilot
            </a>
        </div>
    )
};

HeaderBar.propTypes = {
    loggedIn: PropTypes.bool,
    onLogout: PropTypes.func,
    onShowLogin: PropTypes.func,
};

export default HeaderBar;
