import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import t from '../../../text';
import PageTitle from '../../components/PageTitle';
import ScrollToTop from '../../components/ScrollToTop';
import SearchBox from '../../components/SearchBox/SearchBox';
import TruncatedTextAlt from '../../components/TruncatedTextAlt';
import useSeoText from '../../hooks/useSeoText';
import { channels } from '../../redux/data/channels/selectors';
import { channelOperatorIds, operators } from '../../redux/data/operators/selectors';
import OperatorRow from './OperatorRow';

function Operators() {
    const { channelCode } = useParams();

    let _allChannels = useSelector(channels);
    const _allChannelOperatorIds = useSelector(channelOperatorIds);
    const _allOperators = useSelector(operators);

    let channelsToShow = _allChannels;
    // If channelCode is all or undefined show all data, otherwise filter them
    if (channelCode !== 'all' && channelCode !== undefined) {
        channelsToShow = _allChannels.filter((channel) => channel.code === channelCode);
    }

    const [topSeoText, bottomSeoText] = useSeoText(window.location.pathname.slice(1));

    return (
        <>
            <Helmet>
                <title>{t('operators.document.title')}</title>
                <link
                    rel="canonical"
                    href={`https://www.breakfreeholidays.co.uk/brands/${channelCode !== undefined ? channelCode : ''}`}
                />
            </Helmet>
            <ScrollToTop />
            <Container fluid className="bg-dark py-4">
                <Container>
                    <SearchBox />
                </Container>
            </Container>
            <PageTitle title={t('operators.page.title')} />
            <Container>
                <TruncatedTextAlt text={topSeoText} className="mb-4" />
                {channelsToShow.length < 1 && (
                    <Alert variant="warning" className="p-3 mt-3">
                        <span role="img" aria-label="Attention">
                            ⚠️
                        </span>{' '}
                        You have entered an invalid channel name.
                    </Alert>
                )}
                {channelsToShow.map((channel) => {
                    return (
                        <OperatorRow
                            key={channel.code}
                            channelCode={channel.code}
                            channelName={channel.name}
                            allChannelOperatorIds={_allChannelOperatorIds}
                            allOperators={_allOperators}
                        />
                    );
                })}
                <TruncatedTextAlt text={bottomSeoText} className="mb-4" />
            </Container>
        </>
    );
}

export default Operators;
