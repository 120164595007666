import { useDispatch, useSelector } from 'react-redux';

import { requestPasswordReset } from '../../../../redux/user/actions';
import {
    email,
    loggedIn,
    requestPasswordResetError,
    requestPasswordResetFailed,
    requestPasswordResetPending,
} from '../../../../redux/user/selectors';

import { validateFields } from './PasswordResetForm';
import useLocalFormState from '../../../../hooks/useLocalFormState';

export const usePasswordResetFormActions = () => {
    const dispatch = useDispatch();
    return {
        requestPasswordReset: (email) => {
            return requestPasswordReset(dispatch, email);
        },
    };
};

export const usePasswordResetFormState = () => {
    const loggedInEmail = useSelector(email);
    const lfs = useLocalFormState(validateFields);
    const isLoggedIn = useSelector(loggedIn);

    if (isLoggedIn && lfs.values.email !== loggedInEmail) {
        lfs.onChange('email', loggedInEmail);
    }

    return {
        ...lfs,
        loggedIn: isLoggedIn,
        requestPasswordResetError: useSelector(requestPasswordResetError),
        requestPasswordResetFailed: useSelector(requestPasswordResetFailed),
        requestPasswordResetPending: useSelector(requestPasswordResetPending),
    };
};

export default usePasswordResetFormState;
