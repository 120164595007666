import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import NoImage from '../../assets/images/no-image.png';
import PageTitle from '../../components/PageTitle';
import ScrollToTop from '../../components/ScrollToTop';

import { decodeHtml, slugify } from '../../utils/string';
import { urls } from '../../../config';

import useSeoText from '../../hooks/useSeoText';
import TruncatedTextAlt from '../../components/TruncatedTextAlt';
import SearchPopup from '../../components/Browse/SearchPopup';
import t from '../../../text';

function TravelGuideHome() {
    const [travelGuides, setTravelGuides] = useState([]);

    useEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/travel-guides?per_page=100&_fields=title.rendered,acf.travel_guide_images,acf.region-id`,
            { signal: controller.signal },
        )
            .then((response) => response.json())
            .then((data) => {
                data.sort((a, b) => {
                    return a.title.rendered.localeCompare(b.title.rendered);
                });
                setTravelGuides(data);
            })
            .catch((e) => {
                console.error('Could not fetch the content.', e);
            });
        return () => {
            controller.abort();
        };
    }, []);

    const [topSeoText, bottomSeoText] = useSeoText();

    const removeLastTwoWords = (str) => {
        return str.split(' ').slice(0, -2).join(' ');
    };

    return (
        <>
            <Helmet>
                <title>Browse all Travel Guides</title>
                <link rel="canonical" href="https://www.breakfreeholidays.co.uk/inspiration" />
            </Helmet>
            <ScrollToTop />
            <PageTitle title={'Holiday Inspiration'} />
            <Container className="mb-5">
                <TruncatedTextAlt text={topSeoText} className="mb-4" />
                <Row xs={1} sm={2} md={3}>
                    {travelGuides.map((guide) => (
                        <Col key={guide.title.rendered}>
                            <Card className="border-0">
                                <Card.Img
                                    src={guide.acf.travel_guide_images[0].image_url}
                                    loading="lazy"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = NoImage;
                                    }}
                                />
                                <Card.Body>
                                    <Card.Title className="text-primary text-center" as="h4">
                                        {removeLastTwoWords(decodeHtml(guide.title.rendered))}
                                    </Card.Title>
                                </Card.Body>
                                <Card.Footer>
                                    <SearchPopup variant="alt-primary" regionId={guide.acf['region-id']} className="smallText mb-3" />
                                    <Link
                                        to={`/travel-guide/${slugify(removeLastTwoWords(guide.title.rendered))}/${guide.acf['region-id']}`}
                                        className="btn btn-primary py-2 btn-block smallText"
                                    >
                                        {t('buttons.viewX', removeLastTwoWords(decodeHtml(guide.title.rendered)))}
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <TruncatedTextAlt text={bottomSeoText} className="mb-4" />
            </Container>
        </>
    );
}

export default TravelGuideHome;
