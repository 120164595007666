import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import {
    amountPaid,
    arrivalDate,
    channelId,
    discountValue,
    depositAvailable,
    dueDate,
    duration,
    park,
    operator,
    totalCost as existingTotalCost,
    type,
    unit,
    unitPriceNum,
    bookingFeeNum,
} from '../../redux/booking/booking/selectors';
import { paymentOption } from '../../redux/booking/form/selectors';
import {
    remainingBalance,
    selectedExtrasWithValues,
    totalCost,
    totalCostRemaining,
} from '../../redux/booking/form/selectors';

import HolidaySummary from './HolidaySummary';
import PriceBreakdown from './PriceBreakdown';
import RemainingBalanceBreakdown from './RemainingBalanceBreakdown';
import t from '../../../text';
import payment from '../../assets/images/payment-methods.png';

export const WithHolidaySummary = (props) => {
    const booking = {
        park: useSelector(park),
        operator: useSelector(operator),
        unit: useSelector(unit),
        arrivalDate: useSelector(arrivalDate),
        duration: useSelector(duration),
        channelId: useSelector(channelId),
    };

    const _depositAvailble = useSelector(depositAvailable);
    const _dueDate = useSelector(dueDate);
    const _paymentOption = useSelector(paymentOption);
    const _unitPrice = useSelector(unitPriceNum);
    const _bookingFee = useSelector(bookingFeeNum);
    const _useDeposit = _depositAvailble && _paymentOption === 'deposit';
    const _selectedExtrasWithValues = useSelector(selectedExtrasWithValues);
    const _totalCost = useSelector(totalCost);
    const _totalCostRemaining = useSelector(totalCostRemaining);
    const _type = useSelector(type);
    const _discountValue = useSelector(discountValue);

    const _amountPaid = useSelector(amountPaid);
    const _remainingBalance = useSelector(remainingBalance);
    const _existingTotalCost = useSelector(existingTotalCost);

    return (
        <Container className="my-4">
            <Row>
                <Col xs={12} md={7} lg={8}>
                    {props.children}
                </Col>
                <Col xs={12} md={5} lg={4}>
                    <HolidaySummary
                        bookingType={_type}
                        confirmation={props.confirmation}
                        park={booking.park}
                        operator={booking.operator}
                        unit={booking.unit}
                        arrivalDate={booking.arrivalDate}
                        duration={booking.duration}
                        channel= {booking.channelId}
                    />
                    {!props.confirmation && (
                        <div className="stick-to-top-of-column">
                            {_type === 'new' ? (
                                <PriceBreakdown
                                    extras={_selectedExtrasWithValues}
                                    total={_totalCost}
                                    totalRemaining={_totalCostRemaining}
                                    unitPrice={_unitPrice}
                                    useDeposit={_useDeposit}
                                    dueDate={_dueDate}
                                    channel={booking.channelId}
                                    discValue={_discountValue}
                                    bookingFee={_bookingFee}
                                />
                            ) : (
                                <RemainingBalanceBreakdown
                                    amountPaid={_amountPaid}
                                    totalCost={_existingTotalCost}
                                    remainingBalance={_remainingBalance}
                                />
                            )}

                            {props.onNext && (
                                <>
                                    {props.nextButtonTerms && <p>{props.nextButtonTerms}</p>}
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-block btn-lg"
                                        onClick={props.onNext}
                                        disabled={props.isSaving || props.disableNext}
                                    >
                                        {props.isSaving ? t('global.pleaseWait') : props.nextButtonText}
                                    </button>
                                </>
                            )}
                            {!props.payment && <img src={payment} className="d-block w-50 mx-auto mt-3" alt="Secure Payment" />}   
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

WithHolidaySummary.defaultProps = {
    confirmation: false,
    disableNext: false,
    isSaving: false,
    nextButtonText: t('buttons.continue'),
};

WithHolidaySummary.propTypes = {
    confirmation: PropTypes.bool,
    disableNext: PropTypes.bool,
    isSaving: PropTypes.bool,
    onNext: PropTypes.func,
    nextButtonText: PropTypes.string,
};

export default WithHolidaySummary;
