import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl } from 'react-bootstrap';

export const SortPanel = (props) => {
    return (
        <Form.Group className={props.className}>
            <Form.Label className="lead mb-2">
                <i className="icon-list mr-2"></i> {props.title}
            </Form.Label>
            <FormControl
                as="select"
                className="border-light bf-filter"
                onChange={(e) => props.onChange(e)}
                value={`${props.appliedSort?.field},${props.appliedSort?.direction}`}
            >
                {props.sortOptions.map((opt) => (
                    <option key={`${opt.field},${opt.direction}`} value={`${opt.field},${opt.direction}`}>
                        {opt.label}
                    </option>
                ))}
            </FormControl>
        </Form.Group>
    );
};

SortPanel.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    sortOptions: PropTypes.array,
    appliedSort: PropTypes.object,
    onChange: PropTypes.func,
};

export default SortPanel;
