import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { channelId, details, operator, parkId, processedResults, reviews } from '../../redux/park/selectors';
import { urls } from '../../../config';
import Accommodation from '../../components/ParkPage/ParkInformation/Accommodation';
import AlternativeDatesSection from './AlternativeDatesSection';
import Facilities from '../../components/ParkPage/ParkInformation/Facilities';
import ImportantInfo from '../../components/ParkPage/ParkInformation/ImportantInfo';
//import ParkInformationMenu from '../../components/ParkPage/ParkInformation/ParkInformationMenu';
import RedEyeParkResultsTag from '../../components/RedEye/RedEyeParkResultsTag';
import Reviews from '../../components/ParkPage/ParkInformation/Reviews';
import WithLoadingStates from '../../components/Booking/WithLoadingStates';
import WithParkResults from '../../components/HOC/WithParkResults';
import WithParkReviews from '../../components/HOC/WithParkReviews';
import WithUnitTypesExtraData from '../../components/HOC/WithUnitTypesExtraData';
import WithUpdatedSearchParams from '../../components/HOC/WithUpdatedSearchParams';
import t from '../../../text';
import ThingsToDo from '../../components/ParkPage/ParkInformation/ThingsToDo';
import Videos from '../../components/ParkPage/ParkInformation/Videos';
import QandA from '../../components/ParkPage/ParkInformation/QandA';
import Location from '../../components/ParkPage/ParkInformation/Location';
import AltAccommodation from '../../components/ParkPage/ParkInformation/AltAccommodation';
import ChannelExtra from '../../components/ParkPage/ParkInformation/ChannelExtra';
import TruncatedTextAlt from '../../components/TruncatedTextAlt';
import ParkInformationTabs from '../../components/ParkPage/ParkInformation/ParkInformationTabs';
import SearchPopup from '../../components/Browse/SearchPopup';
import { config } from '../../../config';
import Charges from '../../components/ParkPage/ParkInformation/Charges';

const ParkInformation = forwardRef(({ onNext, searchResult }, ref) => {
    const _channelId = useSelector(channelId);
    const _operator = useSelector(operator);
    const _parkDetails = useSelector(details);
    const _parkId = useSelector(parkId);
    const _processedResults = useSelector(processedResults);
    const _reviews = useSelector(reviews);
    const RegionName = useSelector((state) => state.data.regions.regions);
    const selectedResult = _processedResults?.selectedDate;
    const isBoat = _channelId === 'boats';
    const showAccommodation = searchResult;
    const parkObject = useSelector(details);
    const showImportantInfo = parkObject.importantInfo.length > 0;

    // Video
    const videos = parkObject.vid ? parkObject.vid.split(/\r?\n/) : [];
    const videoCount = videos.length;

    // Q&A
    const showQA = parkObject.qa.length > 0;
    const qaContent = parkObject.qa;

    // Location
    const position = parkObject.pos;
    const directions = parkObject.directions;
    const showLocation = position.length > 0 || directions.length > 0;

    // Charges
    const showCharges = parkObject.parkCharges.length > 0;
    const chargesContent = parkObject.parkCharges;

    // Alt Accommodation
    let showAltAccommodation = !showAccommodation;
    if (_parkDetails.accommodation.length === 0) showAltAccommodation = false;

    // State
    const [activeTab, setActiveTab] = useState();
    const [showReviewsTab, setShowReviewsTab] = useState(false);
    const [ttdData, setTtdData] = useState([]);
    const [ttdPosts, setTtdPosts] = useState([]);
    const handleMenuClick = (key) => {
        setActiveTab(key);
    };

    // Available tabs
    const doChannelExtra = ['boats'];
    const availableTabs = [];

    if (_parkDetails.facilities.length > 0) {
        availableTabs.push({
            name: _channelId === 'ukcottages' ? 'Property Information' : `${t('park.info.facilities')}`,
            key: 'facilities',
        })
    }

    if (config['global.feefoReviews'] && config['parks.showReviews'] && _channelId !== 'boats' && showReviewsTab) {
        availableTabs.push({ name: t('park.info.reviews'), key: 'reviews' });
    }
    if (showAccommodation)
        availableTabs.unshift({
            name: _channelId === 'boats' ? 'Boats' : 'Accommodation',
            key: 'accommodation',
        });
    if (showAltAccommodation)
        availableTabs.push({
            name: _channelId === 'boats' ? 'Boats' : 'Accommodation',
            key: 'altaccommodation',
        });
    if (showImportantInfo) {
        availableTabs.push({ name: t('park.info.importantInfo'), key: 'importantInfo' });
    }

    if (showLocation) availableTabs.push({ name: 'Location', key: 'location' });
    if (videoCount > 0) availableTabs.push({ name: `Video${videoCount > 1 ? 's' : ''}`, key: 'videos' });
    if (ttdPosts.length > 0) availableTabs.push({ name: 'Things to Do', key: 'ttd' });
    if (showQA) availableTabs.push({ name: 'Q & A', key: 'qa' });
    if (showCharges) availableTabs.push({ name: 'Charges', key: 'charges' });
    if (doChannelExtra.includes(_channelId)) availableTabs.push({ name: 'New to Boating', key: 'channelExtra' });

    useEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/travel-guides/?filter[meta_key]=region-id&filter[meta_value]=${RegionName[_parkDetails.regn].id
            }&_fields=acf`,
            { signal: controller.signal },
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    const wpData = data[0];
                    setTtdData(wpData.acf['things-to-do']);
                }
            })
            .catch((e) => {
                console.error('Could not fetch the content.', e);
            });
        return () => {
            controller.abort();
        };
    }, [RegionName, _parkDetails.regn]);

    useEffect(() => {
        const controller = new AbortController();
        // And now, we get the things to do:
        let ttdUrl = `${urls.cms}/things-to-do/?_fields=title,acf`;
        ttdData.forEach((ttd) => {
            ttdUrl = ttdUrl + '&include[]=' + ttd;
        });
        if (ttdData.length > 0) {
            fetch(ttdUrl, { signal: controller.signal })
                .then((response) => response.json())
                .then((data) => {
                    setTtdPosts(data);
                })
                .catch((e) => {
                    console.error('Could not fetch the content.', e);
                });
        }
        return () => {
            controller.abort();
        };
    }, [ttdData]);

    useEffect(() => {
        setActiveTab(availableTabs[0].key);
    }, [])

    useEffect(() => {
        setShowReviewsTab(_reviews ? _reviews.length > 0 : false);
    }, [_reviews])

    if (!isBoat) {
        document.getElementsByTagName('meta')['description'].content = t(
            'park.info.metaDescription',
            _parkDetails.name,
            RegionName[_parkDetails.regn].name,
        );
    }

    const getContent = () => {
        switch (activeTab) {
            case 'accommodation':
                return (
                    <WithParkResults parkId={_parkId}>
                        <WithUnitTypesExtraData
                            parkId={_parkId}
                            parkName={_parkDetails.name}
                            operatorName={_operator.name}
                        >
                            <WithLoadingStates spinnerMessage={t('park.info.loadingAccommodation')}>
                                <Accommodation
                                    result={selectedResult}
                                    parkDetails={_parkDetails}
                                    onBook={(unit) => onNext({ unit })}
                                />
                            </WithLoadingStates>
                        </WithUnitTypesExtraData>
                    </WithParkResults>
                );
            case 'altaccommodation':
                return <AltAccommodation content={_parkDetails.accommodation} channel={_channelId} />;
            case 'facilities':
                return (
                    <Facilities
                        facilities={_parkDetails.facilities}
                        importantInfo={!isBoat ? _parkDetails.covidDets : undefined}
                        overview={_parkDetails.facilOverview}
                        title={_channelId === 'ukcottages' ? 'Property Information' : `${t('park.info.facilities')}`}
                    />
                );
            case 'reviews':
                return (
                    <WithLoadingStates spinnerMessage={t('park.info.loadingReviews')}>
                        <Reviews parkDetails={_parkDetails} reviews={_reviews} />
                    </WithLoadingStates>
                );
            case 'importantInfo':
                return <ImportantInfo content={_parkDetails.importantInfo} />;
            case 'ttd':
                return <ThingsToDo ttdPosts={ttdPosts} />;
            case 'videos':
                return <Videos content={videos} count={videoCount} />;
            case 'qa':
                return <QandA content={qaContent} />;
            case 'charges':
                return <Charges content={chargesContent} />;
            case 'location':
                return (
                    <Location
                        position={position}
                        directions={directions}
                        name={parkObject.name}
                        opid={parkObject.opid}
                    />
                );
            case 'channelExtra':
                return <ChannelExtra channel={_channelId} />;
            default:
                return <div></div>;
        }
    };

    return (
        <div ref={ref}>
            {searchResult && (
                <AlternativeDatesSection
                    clicked={handleMenuClick}
                    active={activeTab}
                    channel={_channelId}
                    pid={_parkId}
                />
            )}

            {!searchResult && (
                <Container fluid className="bg-secondary mb-3">
                    <Container>
                        <Row className="d-flex align-items-center">
                            <Col>
                                <p className="h3 my-3 text-center text-md-left">Book your next {_parkDetails.name} break</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            )}

            <Container className="mb-5">
                <Row id="park-information">
                    <Col md={9}>
                        <WithUpdatedSearchParams channelId={_channelId} regionId={_parkDetails.regn} />
                        <ParkInformationTabs
                            active={activeTab}
                            availableTabs={availableTabs}
                            clicked={handleMenuClick}
                        />
                        <WithParkReviews parkId={_parkId}>
                            {getContent()}
                        </WithParkReviews>
                        {/* Redeye tag */}
                        {showAccommodation && (
                            <div className="d-none">
                                <WithParkResults parkId={_parkId}>
                                    <WithUnitTypesExtraData
                                        parkId={_parkId}
                                        parkName={_parkDetails.name}
                                        operatorName={_operator.name}
                                    >
                                        <WithLoadingStates>
                                            <RedEyeParkResultsTag />
                                        </WithLoadingStates>
                                    </WithUnitTypesExtraData>
                                </WithParkResults>
                            </div>
                        )}
                    </Col>
                    <Col md={3}>
                        <h2>Overview</h2>
                        <TruncatedTextAlt text={_parkDetails.overview} isHTML />
                    </Col>
                </Row>
            </Container>
        </div>
    );
});

export default ParkInformation;
