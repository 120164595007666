import React from 'react';
import { Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';

export const MapMarker = (props) => (
    <Marker
        position={props.position}
        icon={{
            path:
                'M16,0C9.6,0,4.4,5.2,4.4,11.6c0,7.9,10.4,19.6,10.8,20.1c0.4,0.5,1.1,0.5,1.6,0c0.4-0.5,10.8-12.1,10.8-20.1C27.6,5.2,22.4,0,16,0z M16,17.4c-3.2,0-5.8-2.6-5.8-5.8s2.6-5.8,5.8-5.8s5.8,2.6,5.8,5.8S19.2,17.4,16,17.4z',
            fillColor: props.colour,
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: '#fff',
            size: new window.google.maps.Size(32, 32, 'px', 'px'),
            origin: new window.google.maps.Point(0, 0, 'px', 'px'),
            anchor: new window.google.maps.Point(16, 32, 'px', 'px'),
            scaledSize: new window.google.maps.Size(32, 32, 'px', 'px'),
        }}
        onLoad={props.onLoad}
        onClick={props.onClick}
    >
        {props.children}
    </Marker>
);

MapMarker.propTypes = {
    colour: PropTypes.string,
    onClick: PropTypes.func,
    onLoad: PropTypes.func,
    position: PropTypes.object,
};

MapMarker.defaultProps = {
    colour: '#000',
};

export default MapMarker;
