import { createSelector } from 'reselect';

import { channels } from '../../data/channels/selectors';
import { operators } from '../../data/operators/selectors';
import { regions, operatorChannelRegionIds } from '../../data/regions/selectors';

import { getChannelById } from '../../data/channels/utils';
import { getOperatorById } from '../../data/operators/utils';
import { getOperatorRegionsById } from '../../data/regions/utils';

// Utils
// const filterResultsWithHiddenOperators = (operators, results) => {
//     if (!operators || !results) return results;

//     return results.filter((park) => operators[park.opId]?.list === 'true');
// };

// Selectors
export const channelId = (state) => state.pages.browseOperatorRegions.channelId;
export const operatorId = (state) => state.pages.browseOperatorRegions.operatorId;

export const processedResults = createSelector(
    regions,
    operatorChannelRegionIds,
    channelId,
    operatorId,
    (_regions, _operatorChannelRegionIds, _channelId, _operatorId) => {
        let results = getOperatorRegionsById(_regions, _operatorChannelRegionIds, _channelId, _operatorId);

        // if (_channelId === 'ukcaravan') {
        //     _regionParks = filterResultsWithHiddenOperators(_operators, _regionParks);
        // }

        return results;
    },
);

export const channel = createSelector(channels, channelId, (_channels, _channelId) => {
    return getChannelById(_channels, _channelId);
});

export const operator = createSelector(operators, operatorId, (_operators, _operatorId) => {
    return getOperatorById(_operators, _operatorId);
});
