import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LoginForm, { validateFields as loginValidator } from '../../Forms/User/LoginForm/LoginForm';
import { useLoginFormActions, useLoginFormState } from '../../Forms/User/LoginForm/useLoginForm';

function LoginFormView(props) {
    const { values, onChange, validate, isValid, errors, loggingIn, loginError } = useLoginFormState(loginValidator);
    const { login } = useLoginFormActions();

    const [firstRun, setFirstRun] = useState(true); // Used to hide error messages if just opened
    useEffect(() => {
        setFirstRun(true);
    }, []);

    const handleLogin = (event) => {
        event.preventDefault();
        setFirstRun(false);
        validate();
        if (isValid()) {
            login(values.email, values.password).request.finally(() => props.isBookingPage && window.location.reload());
        }
    };

    return (
        <LoginForm
            busy={loggingIn}
            errors={firstRun ? undefined : errors}
            failure={firstRun ? undefined : loginError}
            onChange={onChange}
            onLogin={handleLogin}
            onViewRegister={props.onViewRegister}
            onViewReset={props.onViewReset}
            values={values}
            isBookingPage={props.isBookingPage}
        />
    );
}

LoginFormView.propTypes = {
    onViewRegister: PropTypes.func,
    onViewReset: PropTypes.func,
};

export default LoginFormView;
