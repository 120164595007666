import { combineReducers } from 'redux';
import apiReducerFactory from './apiReducerFactory';

import * as booking from '../booking/booking/action-types';
import * as bookingExtras from '../booking/extras/action-types';
import * as bookingForm from '../booking/form/action-types';
import * as bookingPayment from '../booking/payment/action-types';

import * as dataChannels from '../data/channels/action-types';
import * as dataFilters from '../data/filters/action-types';
import * as dataOperators from '../data/operators/action-types';
import * as dataParks from '../data/parks/action-types';
import * as dataParkDetails from '../data/park/details/action-types';
import * as dataParkResults from '../data/park/results/action-types';
import * as dataParkReviews from '../data/park/reviews/action-types';
import * as dataRegions from '../data/regions/action-types';
import * as dataUnitTypes from '../data/unitTypes/action-types';

import * as header from '../header/action-types';
import * as init from '../init/action-types';

import * as searchForm from '../search/form/action-types';
import * as searchResults from '../search/results/action-types';

import * as user from '../user/action-types';

export const reducer = combineReducers({
    [booking.CREATE_BOOKING]: apiReducerFactory(booking.CREATE_BOOKING),
    [booking.GET_DEPOSIT]: apiReducerFactory(booking.GET_DEPOSIT),
    [booking.SET_DISCOUNT_CODE]: apiReducerFactory(booking.SET_DISCOUNT_CODE),
    [bookingExtras.GET_EXTRAS]: apiReducerFactory(bookingExtras.GET_EXTRAS),
    [bookingExtras.SET_EXTRAS]: apiReducerFactory(bookingExtras.SET_EXTRAS),
    [bookingForm.SET_PARTY_DETAILS]: apiReducerFactory(bookingForm.SET_PARTY_DETAILS),
    [bookingPayment.PRE_PAYMENT]: apiReducerFactory(bookingPayment.PRE_PAYMENT),
    [bookingPayment.POST_PAYMENT]: apiReducerFactory(bookingPayment.POST_PAYMENT),
    [dataChannels.GET_CHANNELS]: apiReducerFactory(dataChannels.GET_CHANNELS),
    [dataFilters.GET_FILTERS]: apiReducerFactory(dataFilters.GET_FILTERS),
    [dataOperators.GET_OPERATORS]: apiReducerFactory(dataOperators.GET_OPERATORS),
    [dataParks.GET_PARKS]: apiReducerFactory(dataParks.GET_PARKS),
    [dataParkDetails.GET_PARK_DETAILS]: apiReducerFactory(dataParkDetails.GET_PARK_DETAILS),
    [dataParkResults.GET_PARK_RESULTS]: apiReducerFactory(dataParkResults.GET_PARK_RESULTS),
    [dataParkReviews.GET_PARK_REVIEWS]: apiReducerFactory(dataParkReviews.GET_PARK_REVIEWS),
    [dataRegions.GET_OPERATOR_REGIONS]: apiReducerFactory(dataRegions.GET_OPERATOR_REGIONS),
    [dataRegions.GET_REGIONS]: apiReducerFactory(dataRegions.GET_REGIONS),
    [dataUnitTypes.GET_EXTRA_DATA]: apiReducerFactory(dataUnitTypes.GET_EXTRA_DATA),
    [header.GET_NAV]: apiReducerFactory(header.GET_NAV),
    [init.GET_INIT_DATA]: apiReducerFactory(init.GET_INIT_DATA),
    [searchForm.START_SEARCH]: apiReducerFactory(searchForm.START_SEARCH),
    [searchResults.GET_RESULTS]: apiReducerFactory(searchResults.GET_RESULTS),
    [user.CHANGE_PASSWORD]: apiReducerFactory(user.CHANGE_PASSWORD),
    [user.CREATE_USER]: apiReducerFactory(user.CREATE_USER),
    [user.GET_BOOKINGS]: apiReducerFactory(user.GET_BOOKINGS),
    [user.LOGIN]: apiReducerFactory(user.LOGIN),
    [user.LOGOUT]: apiReducerFactory(user.LOGOUT),
    [user.REQUEST_PASSWORD_RESET]: apiReducerFactory(user.REQUEST_PASSWORD_RESET),
    [user.UPDATE_USER]: apiReducerFactory(user.UPDATE_USER),
})

export default reducer;
