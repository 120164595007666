export const getParkById = (parks, parkId) => {
    return parks[parkId];
};

export const getParksByRegionId = (parks, regionParkIds, regionId) => {
    return regionParkIds[regionId]?.map((id) => parks[id]);
};

export const processPark = (park) => ({
    ...park,
    pos: {
        lat: parseFloat(park.lat),
        lng: parseFloat(park.lon),
    },
});
