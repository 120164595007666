import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Button, Row, Col } from 'react-bootstrap';

import PartyMembersForm from '../Forms/Search/PartyMembersForm/PartyMembersForm';
import {
    usePartyMembersFormActions,
    usePartyMembersFormState,
} from '../Forms/Search/PartyMembersForm/usePartyMembersForm';
import t from '../../../text';

export const PartyMembersModal = ({ show, onClose }) => {
    const actions = usePartyMembersFormActions();
    const history = useHistory();
    const location = useLocation();
    const state = usePartyMembersFormState();

    const handleSubmit = () => {
        actions.cancelGetResults();
        actions.startSearch(state.params, state.requestParams);

        const params = new URLSearchParams(location.search);
        Object.keys(state.requestParams).forEach((key) => {
            params.set(key, state.requestParams[key]);
        });
        history.push({ pathname: '/results', search: params.toString() });

        onClose();
    };

    return (
        <>
            <Modal centered show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <strong>{t('forms.party.checkYourPartySize')}</strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PartyMembersForm
                        adults={state.adults}
                        children={state.children}
                        under5s={state.under5s}
                        onChangeAdults={actions.selectGuestsAdults}
                        onChangeChildren={actions.selectGuestsChildren}
                        onChangeUnder5s={actions.selectGuestsUnder5s}
                    />
                    <Row>
                        <Col>
                            <Button block onClick={handleSubmit}>
                                {t('buttons.done')}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

PartyMembersModal.propTypes = {
    handleSearch: PropTypes.func,
};

PartyMembersModal.defaultProps = {};

export default PartyMembersModal;
