import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import CardPanel from '../../../components/CardPanel';
import RedEyeBookingConfirmationTag from '../../../components/RedEye/RedEyeBookingConfirmationTag';
import WithHolidaySummary from '../../../components/Booking/WithHolidaySummary';
import { totalCostFull } from '../../../redux/booking/form/selectors';
import t from '../../../../text';
import { config } from '../../../../config';
import { park, operator } from '../../../redux/booking/booking/selectors';

export const Confirmation = ({ bookingType, confirmationId }) => {
    const isNewBooking = bookingType === 'new';
    const _totalCostFull = useSelector(totalCostFull);

    const booking = {
        park: useSelector(park),
        operator: useSelector(operator)
    };

    if (confirmationId) {
        if (config['awin.enable']) {
            const triggerAWIN = () => {
                if (!window.AWIN) {
                    console.info('No AWIN on window yet, trying again...');
                    return;
                } else {
                    console.info('Running AWIN.Tracking.Sale...');
                    clearInterval(CheckForAWIN);
                    window.AWIN.Tracking.Sale = {};
                    window.AWIN.Tracking.Sale.amount = _totalCostFull;
                    window.AWIN.Tracking.Sale.channel = 'aw';
                    window.AWIN.Tracking.Sale.orderRef = confirmationId;
                    window.AWIN.Tracking.Sale.parts = `default:${_totalCostFull}`;
                    window.AWIN.Tracking.Sale.currency = 'GBP';
                    window.AWIN.Tracking.Sale.voucher = '';
                    window.AWIN.Tracking.Sale.test = '0';
                    window.AWIN.Tracking.run();
                }
            };
            let CheckForAWIN = setInterval(triggerAWIN, 99);
        }

        return (
            <>
                <WithHolidaySummary confirmation>
                    <CardPanel title={t('booking.confirmation.holidayBooked')} className="confirmation">
                        <div>
                            <p>{t('booking.confirmation.youAreGoingTo', booking.operator.name, booking.park.name)}</p>
                            <h4 className="my-4">
                                {t('booking.confirmation.yourBookingReferenceIs')} <strong>{confirmationId}</strong>
                            </h4>
                            <h2 className="text-dark mb-4">{t('booking.confirmation.planAhead')}</h2>
                            <p>{t('booking.confirmation.planAheadText')}</p>
                            <Row className='px-3'>
                                <Col sm={12} md={4} className="px-1 mb-3">
                                    <a className="btn btn-primary d-block" target = "_blank" href="https://www.viator.com/?pid=P00038787&mcid=42383&medium=link&campaign=confscreen">
                                        {t('booking.confirmation.bookAttractions')}
                                    </a>
                                </Col>
                                <Col sm={12} md={4} className="px-1 mb-3">
                                    <Link className="btn btn-primary d-block" to="/">
                                        {t('booking.confirmation.bookAgain')}
                                    </Link>
                                </Col>
                                <Col sm={12} md={4} className="px-1 mb-3">
                                    <Link className="btn btn-primary d-block" to="/my-account">
                                        {t('booking.confirmation.visitMyAccount')}
                                    </Link>
                                </Col>
                            </Row>

                        </div>

                        {/* <div className='mt-4'>
                        <h2 className='text-dark'>Rate your experience</h2>
                        <p>How was your booking experience today? Please rate your experience below and send us any comments you may have.</p>
                        <Link className='btn btn-primary' to='/review'>Send a review</Link>
                    </div> */}

                    </CardPanel>
                </WithHolidaySummary>

                {isNewBooking && <RedEyeBookingConfirmationTag />}

                {config['awin.enable'] && (
                    <img
                        alt="awin"
                        border={0}
                        height={0}
                        src={`https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=${config['awin.trackingId']
                            }&amount=${_totalCostFull}&ch=aw&parts=DEFAULT:${_totalCostFull}&ref=${confirmationId}&cks=${document.cookie.match('(^|;)\\s*_AWCToken\\s*=\\s*([^;]+)')?.pop() || ''
                            }&cr=GBP&vc=&testmode=0`}
                        style={{ display: 'none' }}
                        width={0}
                    />
                )}
            </>
        );
    } else {
        return (
            <>
                <Container>
                    <Row>
                        <Col>
                            <p className="mt-5">{t('booking.confirmation.noBookingConfirmation')}</p>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};

export default Confirmation;
