import { PRE_PAYMENT, POST_PAYMENT } from './action-types';
import { getRequestStatus } from '../../requests/selectors';

export const prePaymentNeedsLoading = (state) => typeof state.booking.payment.paymntRecId === 'undefined';
export const [prePaymentIsFetching, prePaymentHasError, prePaymentErrorData] = getRequestStatus(PRE_PAYMENT);
export const [postPaymentIsFetching, postPaymentHasError, postPaymentErrorData] = getRequestStatus(POST_PAYMENT);

export const paypal = (state) => state.booking.payment.paypal;
export const paymntRecId = (state) => state.booking.payment.paymntRecId;
export const paymntUsed = (state) => state.booking.payment.paymntUsed;
export const postPayment = (state) => state.booking.payment.postPayment;
export const cardinal = (state) => state.booking.payment.cardinal;
export const payit = (state) => state.booking.payment.payit;
