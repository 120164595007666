import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ParkPage from './HolidayPark/ParkPage';
import WithAutoSearch from '../components/SearchResults/WithAutoSearch';
import WithPartyMembersModal from '../components/SearchResults/WithPartyMembersModal';
import WithSearchResults from '../components/SearchResults/WithSearchResults';
import WithSearchResultsLoadingStates from '../components/SearchResults/WithSearchResultsLoadingStates';
import WithUpdatedSearchParams from '../components/HOC/WithUpdatedSearchParams';

import t from '../../text';

const ParkFromSearch = () => {
    const { channelId, regionId, parkId } = useParams();

    return (
        <>
            <Helmet>
                <title>{t('dotw.pageTitle')}</title>
            </Helmet>
            <WithUpdatedSearchParams channelId={channelId} regionId={regionId}>
                <WithPartyMembersModal>
                    <WithAutoSearch parkId={parkId}>
                        <WithSearchResults>
                            <WithSearchResultsLoadingStates>
                                <ParkPage ParkFromSearch />
                            </WithSearchResultsLoadingStates>
                        </WithSearchResults>
                    </WithAutoSearch>
                </WithPartyMembersModal>
            </WithUpdatedSearchParams>
        </>
    );
};

export default ParkFromSearch;
