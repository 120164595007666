import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getOperators } from '../../redux/data/operators/actions';
import { getHasFailed, getIsLoading, getIsLoaded } from '../../redux/data/operators/selectors';

import useDataLoader from '../../hooks/useDataLoader';

export function WithOperators(props) {
    const getData = (dispatch, force) => getOperators(dispatch, props.channelId, force);
    const _hasFailed = useSelector((state) => getHasFailed(state, props.channelId));
    const _isLoaded = useSelector((state) => getIsLoaded(state, props.channelId));
    const _isLoading = useSelector((state) => getIsLoading(state, props.channelId));
    const _isReady = !!props.channelId;

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady);
}

WithOperators.propTypes = {
    channelId: PropTypes.string,
};

export default WithOperators;
