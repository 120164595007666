import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';

export const InputRadioButton = (props) => {
    const handleChange = () => {
        if (props.onChange && !props.checked) {
            props.onChange(props.name, props.value);
        }
    };

    return (
        <InputGroup className={props.className}>
            <InputGroup.Prepend className="cursor-pointer" onClick={handleChange}>
                <InputGroup.Radio
                    id={props.name + '-' + props.value}
                    name={props.name}
                    value={props.value}
                    onChange={() => {}}
                    className="cursor-pointer"
                    checked={props.checked}
                    aria-label={props.ariaLabel || props.label}
                />
            </InputGroup.Prepend>
            <label
                htmlFor={props.name + '-' + props.value}
                className="form-control h-auto"
                dangerouslySetInnerHTML={{ __html: props.label }}
            />
        </InputGroup>
    );
};

InputRadioButton.defaultProps = {};

InputRadioButton.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export default InputRadioButton;
