import React from 'react';
import { Card, Container, Row, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { bookings, details } from '../../redux/user/selectors';

import Bookings from './Bookings';
import PageTitle from '../../components/PageTitle';
import PasswordResetForm from '../../components/Login/views/PasswordResetFormView';
import PreferencesCentre from './PreferencesCentre';
import ScrollToTop from '../../components/ScrollToTop';
import WithLogin from '../../components/HOC/WithLogin';
import YourDetails from './YourDetails';
import t from '../../../text';
import TabNavigation from '../../components/Common/TabNavigation';

function MyAccount() {
    const allBookings = useSelector(bookings);
    const futureBoookings = allBookings?.future || [];
    const pastBoookings = allBookings?.past || [];
    const user = useSelector(details);

    const MyAccountTabs = () => {
        return (

            <Tab.Content>
                <Tab.Pane eventKey="details">
                    <YourDetails />
                </Tab.Pane>

                <Tab.Pane eventKey="preferences-centre">
                    <PreferencesCentre />
                </Tab.Pane>

                <Tab.Pane eventKey="change-password">
                    <Card>
                        <Card.Header>
                            <h2>{t('myAccount.password.title')}</h2>
                            <p className="m-0">{t('myAccount.password.text')}</p>
                        </Card.Header>
                        <Card.Body className="p-3">
                            <PasswordResetForm />
                        </Card.Body>
                    </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="c-bookings">
                    <Bookings bookings={futureBoookings} />
                </Tab.Pane>

                <Tab.Pane eventKey="p-bookings">
                    <Bookings bookings={pastBoookings} />
                </Tab.Pane>
            </Tab.Content>
        );

    }

    const availableTabs = [
        { name: t('myAccount.menu.details'), key: 'details' },
        { name: t('myAccount.menu.prefs'), key: 'preferences-centre' },
        { name: t('myAccount.menu.changePassword'), key: 'change-password' },
        { name: t('myAccount.menu.currentBookings'), key: 'c-bookings' },
        { name: t('myAccount.menu.pastBookings'), key: 'p-bookings' },
    ];

    return (
        <WithLogin>
            <Helmet>
                <title>{t('myAccount.page.title')}</title>
            </Helmet>
            <ScrollToTop />
            <PageTitle title={t('myAccount.page.title')} subTitle={t('myAccount.page.subtitle', user.firstName)} />
            <Container>
                <Row>
                    <TabNavigation active={availableTabs[0].key} Tabs={availableTabs} content={MyAccountTabs} />
                </Row>
            </Container>
        </WithLogin>
    );
}

MyAccount.defaultProps = {
    firstname: 'Firstname',
};

export default MyAccount;
