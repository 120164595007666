import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../../../redux/user/actions';
import { changePasswordError, changePasswordFailed, changePasswordPending } from '../../../../redux/user/selectors';
import { validateFields } from './ChangePasswordForm';
import useLocalFormState from '../../../../hooks/useLocalFormState';

export const useChangePasswordFormActions = () => {
    const dispatch = useDispatch();
    return {
        changePassword: (email, passwd, resetCode) => {
            return changePassword(dispatch, email, passwd, resetCode);
        },
    };
};

export const useChangePasswordFormState = () => ({
    ...useLocalFormState(validateFields),
    changePasswordError: useSelector(changePasswordError),
    changePasswordFailed: useSelector(changePasswordFailed),
    changePasswordPending: useSelector(changePasswordPending),
});

export default useChangePasswordFormState;
