import React, { useEffect, lazy, Suspense } from 'react';
import { Container } from 'react-bootstrap';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import ParkCard from '../../components/ParkCard/ParkCard';
import ParksListFilterView from '../../components/ParksListFilterView/ParksListFilterView';
import ScrollToTop from '../../components/ScrollToTop';
import SearchBox from '../../components/SearchBox/SearchBox';
import WithLoadingStates from '../../components/HOC/WithLoadingStates';
import WithOperators from '../../components/HOC/WithOperators';
import WithParks from '../../components/HOC/WithParks';
import WithRegions from '../../components/HOC/WithRegions';
import WithUpdatedSearchParams from '../../components/HOC/WithUpdatedSearchParams';

import { selectChannel, selectRegion } from '../../redux/pages/browseParks/actions';
import {
    channelId as selectedChannelId,
    regionId as selectedRegionId,
    processedResults,
    region,
} from '../../redux/pages/browseParks/selectors';
import t from '../../../text';
import { resetBFilters, resetNFilters } from '../../redux/appState/actions';
import { accommodationType } from '../../utils/utils';
//import { town } from '../../redux/user/selectors';

const FetchTravelGuide = lazy(() => import('../../components/SearchResults/FetchTravelGuide'));

export const Parks = () => {
    const dispatch = useDispatch();
    const { channelId, regionId, townId } = useParams();
    const _parkResults = useSelector(processedResults);
    const _region = useSelector(region);
    const _selectedChannelId = useSelector(selectedChannelId);
    const _selectedRegionId = useSelector(selectedRegionId);
    const isTownPage = townId !== undefined;
    const parkRoute = channelId === 'boats' ? 'boat' : 'holidays';
    const townName = useSelector((state) => state.data.parks.townList[townId]);
    const location = useLocation();

    useEffect(() => {
        if (channelId !== _selectedChannelId) selectChannel(dispatch, channelId);
        if (regionId !== _selectedRegionId) selectRegion(dispatch, regionId);
    }, [dispatch, channelId, regionId, _selectedChannelId, _selectedRegionId]);

    useEffect(() => {
        resetNFilters(dispatch);
        resetBFilters(dispatch);
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>{_region?.name && t('browse.parks.title', _region.name, channelId)}</title>
                <link
                    rel="canonical"
                    href={`https://www.breakfreeholidays.co.uk${location.pathname}${location.search}`}
                />
            </Helmet>
            <ScrollToTop />
            <Container fluid className="py-4 bg-dark">
                <SearchBox />
            </Container>
            <WithUpdatedSearchParams channelId={channelId} regionId={regionId} />
            <WithRegions channelId={channelId}>
                <WithOperators channelId={channelId}>
                    <WithParks regionId={regionId}>
                        <WithLoadingStates spinnerMessage={t('browse.parks.loadingParks')} useContainer>
                            <ParksListFilterView
                                id="parks"
                                title={`${accommodationType(channelId)} ${isTownPage ? 'near' : 'in'} ${
                                    isTownPage ? townName + ', ' : ''
                                }
                                    ${_region?.name}`}
                                channelId={channelId}
                                regionId={regionId}
                                townId={townId}
                                isTown={isTownPage ? townId : false}
                                results={_parkResults}
                                removeMapCardMid={true}
                                parkRoute={parkRoute}
                                generateOutput={(result, viewMode) => (
                                    <ParkCard
                                        key={result.park.parkId}
                                        channelId={channelId}
                                        regionId={_region?.id}
                                        parkRoute={parkRoute}
                                        viewMode={viewMode}
                                        {...result}
                                    />
                                )}
                                sortByName={true}
                            />
                            <Suspense
                                fallback={<div className="text-center">{t('travelGuide.checkingForTravelGuide')}</div>}
                            >
                                <FetchTravelGuide region={_region} />
                            </Suspense>
                        </WithLoadingStates>
                    </WithParks>
                </WithOperators>
            </WithRegions>
        </>
    );
};

export default Parks;
