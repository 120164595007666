import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import MapStyle from '../../Data/GoogleMapStyle';

import MapMarker from './MapMarker';
import MultipleLocationsInfoWindow from './MultipleLocationsInfoWindow';
import MultipleLocationsOperatorKey from './MultipleLocationsOperatorKey';

import { config } from '../../../config';

const containerStyle = {
    width: '100%',
    height: '640px',
};

const operatorData = {
    0: {
        colour: '#000000',
        name: '---',
    },
    2: {
        colour: '#28a789',
        name: 'Haven',
    },
    8: {
        colour: '#9c151c',
        name: 'Park Holidays UK',
    },
    119: {
        colour: '#bb2e53',
        name: 'Parkdean Resorts',
    },
};

function MultipleLocations(props) {
    const [mapRef, setMapRef] = useState(null);
    const [selectedResult, setSelectedResult] = useState(null);
    const [markerMap, setMarkerMap] = useState({});
    const [infoOpen, setInfoOpen] = useState(false);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: config.googleMapsApiKey,
    });

    if (loadError) console.error(loadError);

    const fitBounds = useCallback(
        (map) => {
            const bounds = new window.google.maps.LatLngBounds();
            props.results.map((result) => {
                bounds.extend(result.park.pos);
                return result.park.parkId;
            });
            map.fitBounds(bounds);
        },
        [props.results],
    );

    const loadHandler = (map) => {
        setMapRef(map);
        fitBounds(map);
    };

    const markerLoadHandler = (marker, result) => {
        return setMarkerMap((prevState) => {
            return { ...prevState, [result.park.parkId]: marker };
        });
    };

    const markerClickHandler = (result) => {
        setSelectedResult(result);
        if (infoOpen) setInfoOpen(false);
        setInfoOpen(true);
    };

    const closeInfoWindow = () => {
        setInfoOpen(false);
    };

    useEffect(() => {
        if (mapRef) {
            fitBounds(mapRef);
        }
    }, [props.results, fitBounds, mapRef]);

    const renderMap = () => {
        return (
            <>
                <GoogleMap
                    id="park-map"
                    mapContainerStyle={containerStyle}
                    zoom={props.zoom}
                    //center={props.results[0].park.pos}
                    options={{
                        styles: MapStyle,
                        streetViewControl: false,
                        maxZoom: 14,
                        mapTypeControlOptions: {
                            mapTypeIds: [],
                        },
                    }}
                    onLoad={loadHandler}
                    mapContainerClassName="content-box border"
                    maxZoom="10"
                >
                    {props.results.map((result) => (
                        <MapMarker
                            key={result.park.parkId}
                            position={result.park.pos}
                            colour={operatorData[result.park.opId]?.colour || '#000'}
                            onLoad={(marker) => markerLoadHandler(marker, result)}
                            onClick={() => markerClickHandler(result)}
                        />
                    ))}

                    {infoOpen && selectedResult && (
                        <MultipleLocationsInfoWindow
                            anchor={markerMap[selectedResult.park.parkId]}
                            channelId={props.channelId}
                            regionId={props.regionId}
                            result={selectedResult}
                            parkRoute={props.parkRoute}
                            onClose={closeInfoWindow}
                            removeMapCardMid={props.removeMapCardMid}
                        />
                    )}
                </GoogleMap>
                <MultipleLocationsOperatorKey operatorData={operatorData} results={props.results} />
            </>
        );
    };

    return isLoaded ? renderMap() : 'Loading...';
}

MultipleLocations.propTypes = {
    channelId: PropTypes.string,
    regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    results: PropTypes.array,
    parkRoute: PropTypes.string,
    removeMapCardMid: PropTypes.bool,
};

MultipleLocations.defaultProps = {
    parkRoute: 'park-result',
};

export default MultipleLocations;
