import React, { useState, useEffect } from 'react';
import { Accordion, Card, Tab, Tabs } from 'react-bootstrap';
import { urls } from '../../../config';

function FAQ() {
    const [faqsData, setFaqsData] = useState();
    const [activeBlock, setActiveBlock] = useState();
    const [activeAccordion, setActiveAccordion] = useState('0');

    useEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/faq/?_fields=title.rendered,content.rendered,acf.order,acf.block&status=publish&per_page=100`,
            { signal: controller.signal },
        )
            .then((response) => response.json())
            .then((data) => {
                data.sort((a, b) => {
                    return a.acf.order - b.acf.order;
                });
                const blocks = new Map();
                const defaultBlock = {value: "general", label: "General"};
                blocks.set(defaultBlock.value, {faqs: [], label: defaultBlock.label});
                data.forEach((element) => {
                    const currentBlock = element.acf.block ?? defaultBlock;
                    if (!blocks.has(currentBlock.value)) {
                        blocks.set(currentBlock.value, {faqs: [], label: currentBlock.label});
                    }
                    blocks.get(currentBlock.value).faqs.push(element);
                });
                const values = Array.from(blocks.values());
                setFaqsData(values);
            })
            .catch((e) => {
                console.error('Could not fetch the content.', e);
            });
        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        faqsData && setActiveBlock(Object.keys(faqsData)[0]);
    }, [faqsData]);

    return (
        <>
            <Tabs
                className="faqs-tabs"
                onSelect={(k) => setActiveBlock(k)}
            >
                {faqsData && Object.keys(faqsData).map((key, i) => (
                    <Tab
                        key={i}
                        eventKey={key}
                        onClick={() => setActiveBlock(key)}
                        title={faqsData[key].label}
                    />
                ))}
            </Tabs>
            <p>
                The list below should answer most of the commonly raised questions. If you cannot find the
                information you’re looking for here, please check out the Terms and Conditions, which may answer
                your question. If you still need further help, click on the Contact Us button at the bottom of this
                page. Please note: Separate terms and conditions apply to our promotional offers.
            </p>
            <Accordion activeKey={activeAccordion}>
                {faqsData && faqsData[activeBlock]?.faqs.map((faq, i) => {
                    return (
                        <Card key={i} className={activeAccordion === '' + i ? 'active' : ''} data-order={faq.acf.order}>
                            <Accordion.Toggle as={Card.Header} eventKey={'' + i} onClick={() => setActiveAccordion('' + i)}>
                                {faq.title.rendered.replace(/&#8217;/g, "'")}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={'' + i}>
                                <Card.Body
                                    dangerouslySetInnerHTML={{ __html: faq.content.rendered }}
                                    className="pt-3 px-3"
                                ></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    );
                })}
            </Accordion>
        </>
    );
}

export default FAQ;
