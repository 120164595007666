import React, { useState } from 'react';
import { Dropdown, Col, Tab, Nav } from "react-bootstrap";
const TabNavigation = (props) => {
    const itemClasses = ['cursor-pointer', 'd-flex', 'justify-content-between', 'text-lg', 'p-3'];
    const [activeTab, setActiveTab] = useState(props.active || props.Tabs[0]);
    const handleMenuClick = (key) => {
        setActiveTab(key);
    };
    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey={props.Tabs[0].key}>
                <Col md={3}>
                    <Nav className="vertical-tab-menu stick-to-top-of-column d-none d-md-block" as="ul">
                        {props.Tabs.map((item) => (
                            <Nav.Link key={item.key} as="li" onClick={() => handleMenuClick(item.key)} eventKey={item.key} className={itemClasses.join(' ')} > {item.name}
                                <i className="text-lg icon-right text-white d-none d-md-inline-block"></i>
                            </Nav.Link>
                        ))}
                    </Nav>
                    {/* Visible only on xs */}
                    <Dropdown className="vertical-tab-menu-sm mb-5 d-block d-md-none">
                        <Dropdown.Toggle as="button" type="button" className="toggle-popup is-dropdown border" role="button">
                            <span>{props.Tabs.filter((obj) => obj.key === activeTab)[0].name}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {props.Tabs.map((item) => (
                                <Dropdown.Item key={item.key} onClick={() => handleMenuClick(item.key)} eventKey={item.key}>
                                    {item.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown></Col>
                <Col md={9}>
                        {props.content(activeTab)}
                    </Col>
            </Tab.Container>
        </>


    );
}

export default TabNavigation;