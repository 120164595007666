import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import HolidayDateCard from './HolidayDateCard';
import { getDayNameFromDate, getDayAndMonthNameFromDate } from '../../../utils/date';
import t from '../../../../text';
import { requestPayload } from '../../../redux/search/results/selectors/selectors';
import Slider from 'react-slick';
//import AltDatesCalendar from './AltDatesCalendar';
//import { config } from '../../../../config';

const sortByDate = (arr) => {
    return arr.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    });
};

function AlternativeDates(props) {
    //const [cottageAltDates, setCottageAltDates] = useState(false);
    const searchDate = useSelector(requestPayload).date;

    // Find the preferred date park data
    const allParks = props.results;
    const preferredDate = allParks[searchDate];

    // Find the alternative date parks data
    const allDates = Object.values(props.results);
    const filteredDates = allDates.filter((date) => date !== preferredDate);
    const alternativeDates = sortByDate(filteredDates);
    preferredDate && alternativeDates.splice(1, 0, preferredDate)

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        className: 'alt-dates-slider w-100',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        alternativeDates.length !== 0 && (
            <Container fluid className="bg-blue border mb-3">
                <Container className="py-1">
                    <Row className="align-items-center single-date" noGutters>
                        <Col md={4} sm={12}>
                            <h4 className="text-white">{t('park.dates.alternativeDates')}</h4>
                        </Col>
                        <Col md={8} sm={12}>
                            {/*
                                {alternativeDates.map((result) => (
                                    <Col
                                        md={6}
                                        lg={!preferredDate ? { span: 3 } : { span: 4 }}
                                        xl={!preferredDate && { span: 3 }}
                                        key={result.date}
                                    >
                                        <HolidayDateCard
                                            selected={result === props.selectedResult}
                                            day={getDayNameFromDate(result.date)}
                                            date={getDayAndMonthNameFromDate(result.date)}
                                            year={result.date.toString().slice(11, 15)}
                                            price={result.lowestPrice}
                                            clicked={() => props.onSelect(result)}
                                        />
                                    </Col>
                                ))}
                                */}
                            <Slider {...settings}>
                                {alternativeDates.map((result, i) => (
                                    <HolidayDateCard
                                        key={i}
                                        selected={result === props.selectedResult}
                                        day={getDayNameFromDate(result.date, true)}
                                        date={getDayAndMonthNameFromDate(result.date)}
                                        year={result.date.toString().slice(11, 15)}
                                        price={result.lowestPrice}
                                        clicked={() => props.onSelect(result)}
                                    />
                                ))}
                            </Slider>
                            {/*cottageAltDates && (
                                <Row>
                                    <Col>
                                        <AltDatesCalendar pid={props.parkId} duration={4} data={cottageAltDates} />
                                    </Col>
                                </Row>
                            )*/}
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    );
}

AlternativeDates.propTypes = {
    onSelect: PropTypes.func,
    results: PropTypes.object,
    selectedResult: PropTypes.object,
};

AlternativeDates.defaultProps = {};

export default AlternativeDates;
