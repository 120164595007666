import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getRegions } from '../../redux/data/regions/actions';
import { getRegionsHasFailed, getRegionsIsLoading, getRegionsIsLoaded } from '../../redux/data/regions/selectors';

import useDataLoader from '../../hooks/useDataLoader';

export function WithRegions(props) {
    const getData = (dispatch, force) => getRegions(dispatch, props.channelId, force);
    const _hasFailed = useSelector((state) => getRegionsHasFailed(state, props.channelId));
    const _isLoaded = useSelector((state) => getRegionsIsLoaded(state, props.channelId));
    const _isLoading = useSelector((state) => getRegionsIsLoading(state, props.channelId));
    const _isReady = !!props.channelId;

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady);
}

WithRegions.propTypes = {
    channelId: PropTypes.string,
};

export default WithRegions;
