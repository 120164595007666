import { apiRequest, callbackApiRequest } from '../../apiRequest';
import { GET_EXTRAS, SET_EXTRAS } from './action-types';
import selfCancellingAction from '../../selfCancellingAction';
import { urls } from '../../../../config';

export const [getExtras, cancelGetExtras] = selfCancellingAction((dispatch, bookingId) => {
    return apiRequest(dispatch, GET_EXTRAS, urls.bookingGetExtras, { bookId: bookingId });
});

export const [setExtras, cancelSetExtras] = selfCancellingAction((dispatch, bookingId, extras, dCode, dApplied) => {
    let obj = {
        bookId: bookingId,
        ...extras,
    };
    if (!dApplied) obj.discountCode = dCode;
    return callbackApiRequest(dispatch, SET_EXTRAS, urls.bookingSetExtras, obj);
});
