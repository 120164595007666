import { createSelector } from 'reselect';

import { channels } from '../../data/channels/selectors';
import { regions, channelRegionIds } from '../../data/regions/selectors';

import { getChannelById } from '../../data/channels/utils';
import { getRegionsByChannelId } from '../../data/regions/utils';

// utils
function sortAlphabetically(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
}

export const channelId = (state) => state.pages.browseRegions.channelId;

export const channelRegions = createSelector(
    regions,
    channelRegionIds,
    channelId,
    (_regions, _channelRegionIds, _channelId) => {
        let results = getRegionsByChannelId(_regions, _channelRegionIds, _channelId);
        if (!Array.isArray(results)) return undefined;

        results.filter((region) => region.id !== '15'); // Lose Ireland
        results.sort(sortAlphabetically);

        return results;
    },
);

export const channel = createSelector(channels, channelId, (_channels, _channelId) => {
    return getChannelById(_channels, _channelId);
});
