import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import SortPanel from '../SortPanel';

import { setState } from '../../redux/appState/actions';

export const AppStateSortPanel = (props) => {
    const dispatch = useDispatch();

    const onChange = (event) => {
        const [field, direction] = event.target.value.split(',');
        const selectedSortOption = props.sortOptions.find((opt) => opt.field === field && opt.direction === direction);
        setState(dispatch, props.stateKey, selectedSortOption);
    };

    // If there are params given in the URL, set them
    useEffect(() => {
        if (props.urlKey) {
            const urlFilterValues = new URLSearchParams(document.location.search).get(props.urlKey);
            if (urlFilterValues) {
                setState(dispatch, props.stateKey, urlFilterValues.split(','));
            }
        }
    }, [dispatch, props.urlKey, props.stateKey]);

    return <SortPanel {...props} onChange={onChange} />;
};

AppStateSortPanel.propTypes = {
    ...SortPanel.propTypes,
    selectedFilters: PropTypes.array,
    stateKey: PropTypes.string,
};

AppStateSortPanel.defaultProps = {
    selectedFilters: [],
};

export default AppStateSortPanel;
