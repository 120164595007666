import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import NoImage from '../../assets/images/no-image.png';

export const OperatorCard = ({ channelId, operator }) => (
    <Link to={`/${operator.opId}/region/${channelId}`} className="text-decoration-none">
        <Card className="border-0">
            <Card.Img
                src={`//content.breakfreeholidays.co.uk/assets/images/operators/${operator.opId}/operator-image.jpg`}
                loading="lazy"
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = NoImage;
                }}
            />
            <Card.Body className="text-center ">
                <h4 className="text-primary m-0">{operator.name}</h4>
            </Card.Body>
        </Card>
    </Link>
);

OperatorCard.propTypes = {
    channelId: PropTypes.string,
    operator: PropTypes.object,
};

export default memo(OperatorCard);
