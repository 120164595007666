import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

function PayItPayment(props) {
    return (
        <Container>
            <Row>
                <Col>
                    <p>
                        Payit™ is a quick and easy journey with improved control over your finances, reduced exposure to
                        fraud and control over data sharing. Payit™ is securely developed by NatWest, but you don’t need
                        to bank with NatWest to use it.
                    </p>
                    <Button href={props.redirectUrl} variant="secondary" size="lg" className="mb-3">
                        Redirect to Payit™
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default PayItPayment;
