import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { loggedIn } from '../../redux/user/selectors';

import Login from './Login';

export const LoginModal = (props) => {
    const _loggedIn = useSelector(loggedIn);

    useEffect(() => {
        if (props.show && _loggedIn && props.onClose) props.onClose();
    }, [_loggedIn, props]);

    return (
        <>
            <Modal show={props.show} onHide={props.onClose} centered>
                <Modal.Header closeButton className="bg-lighter">
                    <Modal.Title>
                        <strong>Are you a member?</strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Login redirectOnLogin={props.redirectOnLogin} handleClose={props.onClose} />
                </Modal.Body>
            </Modal>
        </>
    );
};

LoginModal.propTypes = {
    onClose: PropTypes.func,
    redirectOnLogin: PropTypes.bool,
    show: PropTypes.bool,
};

export default LoginModal;
