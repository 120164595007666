import { GET_INIT_DATA } from './action-types';
import { SUCCESS } from '../constants';

const initialState = {
    ready: false,
    promotion: {}
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INIT_DATA + SUCCESS:
            return {
                ready: true,
                promotion: {
                    ...state.promotion,
                    ...action.payload.response.data.data.promotion,
                }
            };
        default:
            return state;
    }
};

export default reducer;
