import { GET_PARK_RESULTS } from './action-types';
import { apiRequest, checkRequestNeeded } from '../../../apiRequest';
import { urls } from '../../../../../config';
import { getIsLoaded, getIsLoading, getHasFailed } from './selectors';

export const getParkResults = (dispatch, parkId, force = false) => {
    dispatch((d, getState) => {
        const state = getState();
        const isLoaded = getIsLoaded(state, parkId);
        const isLoading = getIsLoading(state, parkId);
        const hasFailed = getHasFailed(state, parkId);

        if (checkRequestNeeded(true, isLoading, isLoaded, hasFailed, force)) {
            apiRequest(d, GET_PARK_RESULTS, urls.parkResults, { parkId }, parkId);
        }
    });
};
