import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import HeaderNavLoginButtons from './HeaderNavLoginButtons';
import Logo from '../Logo';
import { config, images } from '../../../config';
import { channels } from '../../redux/data/channels/selectors';
import MegaMenuColumn from './MegaMenuColumn';
import HeaderNavLink from './HeaderNavLink';

export const HeaderNav = ({ loggedIn, onLogout, onShowLogin, menuData }) => {
    const [showBackground, setShowBackground] = useState(config['header.static']);
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();
    const backgroundStatus = useRef();
    const theNavBar = useRef();

    // channel data and sort
    const Channels = useSelector(channels);
    let menuItems = ['ukpromo','hotels','uklodge','boats'];
    const sortOrder = [ 'eupromo', 'ukpromo', 'ukcaravan', 'eurocaravan', 'uklodge', 'hotels', 'uktouring', 'boats', 'ukcottages'];

    Channels.map((channel) => {
        if (channel.code !== 'uktouring') return menuItems.push(channel.code);
        else return false;
    });

    menuItems.sort(function (a, b) {
        return sortOrder.indexOf(a) - sortOrder.indexOf(b);
    });

    // Handle changing of nav background when scrolling
    backgroundStatus.current = showBackground;
    useEffect(() => {
        if (!config['header.static']) {
            const handleScroll = () => {
                const show = window.scrollY > 97;
                if (backgroundStatus.current !== show) {
                    backgroundStatus.current = show;
                    setShowBackground(show);
                }
            };
            document.addEventListener('scroll', handleScroll);
            return () => document.removeEventListener('scroll', handleScroll);
        }
    }, []);

    // Collapse nav whenever we change route
    useEffect(() => {
        setExpanded(false);
        theNavBar.current.click();
    }, [location]);

    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            bg={config['header.style']}
            id="main-navigation"
            variant={config['header.style']}
            sticky="top"
            data-style={showBackground ? config['header.style'] : 'light'}
            expanded={expanded}
            ref={theNavBar}
        >
            <Container>
                <NavLink to="/" exact>
                    <Navbar.Brand className="mr-md-3">
                        <Logo
                            variant={showBackground ? config['header.style'] : 'light'}
                            logoDark={images.headerLogoDark}
                            logoLight={images.headerLogoLight}
                            height={config['header.logo.height']}
                        />
                    </Navbar.Brand>
                </NavLink>

                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    id="nav-toggle"
                    onClick={() => setExpanded(!expanded)}
                >
                    <span></span>
                </Navbar.Toggle>

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {menuItems.map((item, i) => {
                            return (
                                <NavDropdown key={i} title={menuData[item].title} className="megamenu">
                                    <Container className="d-block p-3 rounded-bottom megamenu-panel">
                                        <Row noGutters className="w-100">
                                            <Col className="d-flex flex-column">
                                                <Row>
                                                    {menuData[item].columns.map((col, i) => {
                                                        return (
                                                            <Col key={i} xs={12} md={6} lg={4}>
                                                                {col.map((section, i) => (
                                                                    <MegaMenuColumn
                                                                        section={section}
                                                                        setExpanded={setExpanded}
                                                                        channelId={item}
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </Col>
                                                        );
                                                    })}
                                                </Row>
                                                <Row className="mt-auto">
                                                    <Col>
                                                        {menuData[item].isChannel && (
                                                            <Link to={`/region/${item}`}>
                                                                <Button
                                                                    className="browse-all mb-3 px-3"
                                                                    variant={
                                                                        showBackground ? 'secondary' : 'primary'
                                                                    }
                                                                >
                                                                    Browse all {menuData[item].title}
                                                                </Button>
                                                            </Link>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {menuData[item]?.image && (
                                                // Has image column
                                                <Col md={5} lg={4} className="d-flex align-items-start">
                                                    <Link to={`/region/${item}`}>
                                                        <img
                                                            src={menuData[item].image}
                                                            className="w-100 rounded"
                                                            alt={menuData[item].title}
                                                        />
                                                    </Link>
                                                </Col>
                                            )}
                                        </Row>
                                    </Container>
                                </NavDropdown>
                            );
                        })}
                        {config['header.showOffersLink'] && <HeaderNavLink label="Offers" href="/offers" />}
                        {config['header.showPromoLink'] && <HeaderNavLink label="Promo Info" href="/how-it-works" />}
                        {config['header.showFAQLink'] && <HeaderNavLink label="FAQs" href="/frequently-asked-questions" />}
                        {config['header.showFerriesLink'] && <HeaderNavLink label="Ferries" href="/ferry-deals" />}
                        {config['header.showMoreLink'] && <HeaderNavLink label="More Offers" href="/offers" />}
                    </Nav>
                    <HeaderNavLoginButtons {...{ loggedIn, onLogout, onShowLogin }} showBackground={showBackground} />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

HeaderNav.propTypes = {
    loggedIn: PropTypes.bool,
    onLogout: PropTypes.func,
    onShowLogin: PropTypes.func,
};

export default HeaderNav;
