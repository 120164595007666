import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getExtraData } from '../../redux/data/unitTypes/actions';
import {
    getExtraDataHasFailed,
    getExtraDataIsLoading,
    getExtraDataIsLoaded,
} from '../../redux/data/unitTypes/selectors';
import useDataLoader from '../../hooks/useDataLoader';

export function WithUnitTypesExtraData(props) {
    const { parkId, parkName, operatorName } = props;

    const getData = (dispatch, force) => getExtraData(dispatch, parkId, parkName, operatorName, force);
    const _hasFailed = useSelector((state) => getExtraDataHasFailed(state, parkId));
    const _isLoaded = useSelector((state) => getExtraDataIsLoaded(state, parkId));
    const _isLoading = useSelector((state) => getExtraDataIsLoading(state, parkId));
    const _isReady = parkId && parkName && operatorName;

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady, undefined, true);
}

WithUnitTypesExtraData.propTypes = {
    allowFailure: PropTypes.bool,
    parkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    parkName: PropTypes.string,
    operatorName: PropTypes.string,
};

WithUnitTypesExtraData.defaultProps = {
    allowFailure: true,
};

export default WithUnitTypesExtraData;
