import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePayPalScriptReducer } from '@paypal/react-paypal-js';

import PaypalPaymentForm from '../../../components/Forms/Booking/PaypalPaymentForm/PaypalPaymentForm';
import Spinner from '../../../components/Spinner';

import { usePaypalPaymentFormState } from '../../../components/Forms/Booking/PaypalPaymentForm/usePaypalPaymentForm';
import t from '../../../../text';

export const PaypalPayment = ({ onSuccess }) => {
    const { amount, bookingId, paypal } = usePaypalPaymentFormState();
    const [firstRun, setFirstRun] = useState(true);
    const [paypalError, setPaypalError] = useState(undefined);
    const [{ isResolved }] = usePayPalScriptReducer();

    const handleError = (err) => {
        setFirstRun(false);
        setPaypalError(err);
    };

    const handleSuccess = (resp) => {
        setPaypalError(undefined);
        onSuccess(resp);
    };

    // Mark as first run if booking changes / on page refresh
    useEffect(() => setFirstRun(true), [amount, bookingId]);

    if (isResolved) {
        return (
            <>
                {paypal.clientId && (
                    <PaypalPaymentForm
                        clientId={paypal.clientId}
                        onCreateOrder={() => paypal.orderId}
                        onError={handleError}
                        onSuccess={handleSuccess}
                    />
                )}
                {!firstRun && paypalError && <p className="text-danger">{paypalError.toString()}</p>}
            </>
        );
    } else {
        return <Spinner message={t('booking.payment.initialisingPaypal')} />;
    }
};

PaypalPayment.propTypes = {
    onSuccess: PropTypes.func,
};

export default PaypalPayment;
