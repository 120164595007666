import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { config } from '../config';
import ReactGa from 'react-ga';

// Redux store
import { store } from './redux/store';

// Stylesheets
import './assets/scss/libs.scss';
import '../skin.scss';

// Page routes
import Routes from './Routes';

// Components
import Header from './components/Header/Header';
import WithInit from './components/HOC/WithInit';
import WithUrlParams from './components/HOC/WithUrlParams';
import OnRouteChange from './components/OnRouteChange';

function App() {
    const Footer = lazy(() => import('../base/components/Footer/NewFooter/Footer'));

    useEffect(() => {
        const awin = new URLSearchParams(window.location.search).get('awc');
        if (awin !== null && awin.length > 0) {
            fetch(`${config['api.url']}/api/awin?awc=${awin}`)
                .then((response) => response.json())
                .then((data) => console.info(data));
        }
    }, []);

    useEffect(() => {
        ReactGa.initialize(config['google.ua']);
    }, []);

    return (
        <Provider store={store}>
            <div className="App">
                <Helmet>
                    <title>{config['global.brandName']}</title>
                    <meta name="description" content={config['global.brandName']} />
                    <meta name="theme-color" content="#008f68" />
                    {config['awin.enable'] && (
                        <script
                            defer="defer"
                            src={`https://www.dwin1.com/${config['awin.trackingId']}.js`}
                            type="text/javascript"
                        ></script>
                    )}
                </Helmet>
                <Router>
                    <OnRouteChange />
                    <Header />
                    <WithInit>
                        <WithUrlParams>
                            <main>
                                <Routes />
                            </main>
                            <Suspense fallback={<div></div>}>
                                <Footer />
                            </Suspense>
                        </WithUrlParams>
                    </WithInit>
                </Router>
            </div>
        </Provider>
    );
}

export default App;
