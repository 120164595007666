import { useState, useEffect } from 'react';
import { urls } from '../../config';

export const useSeoText = (format) => {
    const [topText, setTopText] = useState(null);
    const [bottomText, setBottomText] = useState(null);

    const currentURLPath = window.location.pathname.slice(1);

    useEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/seo-text/?filter[meta_key]=url_path&filter[meta_value]=${currentURLPath}&_fields=acf.top_text,acf.bottom_text`,
            { signal: controller.signal },
        )
            .then((data) => data.json())
            .then((data) => {
                const seoText = data[0].acf;
                if ('top_text' in seoText && seoText.top_text.length > 0) {
                    setTopText(seoText.top_text);
                }
                if ('bottom_text' in seoText && seoText.bottom_text.length > 0) {
                    setBottomText(seoText.bottom_text);
                }
            })
            .catch((e) => {
                console.error('Could not fetch the content.', e);
            });
            return () => {
                controller.abort();
            };
    }, [currentURLPath, format]);

    return [topText, bottomText];
};

export default useSeoText;
