/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { startSearch } from '../../redux/search/form/actions';
import { cancelGetResults } from '../../redux/search/results/actions';
import { params as newParams, requestParams } from '../../redux/search/form/selectors';
import { isSearchRequestPending } from '../../redux/search/results/selectors/data';
import { params as oldParams, pendingParams as oldPendingParams } from '../../redux/search/results/selectors/selectors';

import { datesAreEqual } from '../../utils/date';

export const WithAutoSearch = (props) => {
    const dispatch = useDispatch();

    const _isSearchRequestPending = useSelector(isSearchRequestPending);
    const _oldParams = useSelector(oldParams);
    const _oldPendingParams = useSelector(oldPendingParams);
    const _newParams = useSelector(newParams);
    const _newRequestParams = useSelector(requestParams);

    const autoSearchNeeded = () => {
        const fields = ['channelId', 'regionId', 'arrivalDate', 'duration', 'adults', 'children', 'under5s'];
        const params = _isSearchRequestPending ? _oldPendingParams : _oldParams;

        let allFieldsPresent = true;
        fields.forEach((field) => {
            if (typeof _newParams[field] === 'undefined') allFieldsPresent = false;
        });

        if (allFieldsPresent) {
            return (
                params.channelId !== _newParams.channelId ||
                params.regionId !== _newParams.regionId ||
                !datesAreEqual(params.arrivalDate, _newParams.arrivalDate) ||
                params.duration !== _newParams.duration ||
                params.adults !== _newParams.adults ||
                params.children !== _newParams.children ||
                params.under5s !== _newParams.under5s ||
                (props.parkId && params.parkId !== props.parkId)
            );
        }

        return false;
    };

    useEffect(() => {
        if (autoSearchNeeded()) {
            cancelGetResults();
            startSearch(dispatch, _newParams, _newRequestParams, props.parkId);
        }
    }, []);

    return props.children;
};

WithAutoSearch.propTypes = {
    parkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WithAutoSearch;
