import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';

import InputRadioButton from './InputRadioButton';

export const InputRadioGroup = (props) => (
    <Form.Row className={`inputRadioGroup ${props.invalid ? 'invalid' : ''} ${props.className}`}>
        <Form.Group as={Col} className={props.groupClassName}>
            {props.text && <p className="mb-2">{props.text}</p>}

            {props.options.map((option, i) => {
                const checked = props.values[props.name] === option.value;
                const isLast = i !== props.options.length - 1;

                return (
                    <div
                        className={`d-inline-block ${!isLast || props.noSpacing ? '' : 'mb-2 mr-2 pr-1'}`}
                        key={`${props.name}-${option.value}`}
                    >
                        <InputRadioButton
                            name={props.name}
                            value={option.value}
                            label={option.label}
                            checked={checked}
                            onChange={props.onChange}
                        />
                    </div>
                );
            })}

            {props.validationMessage && <div className="invalid-feedback d-block">{props.validationMessage}</div>}
        </Form.Group>
    </Form.Row>
);

InputRadioGroup.propTypes = {
    className: PropTypes.string,
    groupClassName: PropTypes.string,
    invalid: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array, // [{label: '', value ''}, ...]
    text: PropTypes.string,
    validationMessage: PropTypes.string,
    values: PropTypes.object,
};

InputRadioGroup.defaultProps = {
    className: '',
    groupClassName: '',
    options: [],
    values: {},
};

export default InputRadioGroup;
