import React from 'react';
import PropTypes from 'prop-types';

import CardPanel from '../CardPanel';
import Spinner from '../Spinner';
import t from '../../../text';
import { Alert } from 'react-bootstrap';

export const WithLoadingStates = (props) => (
    <>
        {props.isFetching ? (
            <Spinner message={props.spinnerMessage} />
        ) : (
            <>
                {props.errorResponse && Array.isArray(props.errorResponse.errors) && (
                    <Alert variant="danger" className="light text-danger p-3">
                        {props.errorResponse.errors.map((error) => (
                            <p key={error.code} className="mb-0">
                                {`${error.message}${error.additional ? ' (' + error.additional + ')' : ''}`}
                            </p>
                        ))}
                    </Alert>
                )}
                {!props.hasErrorFetching && props.children}
                {props.hasErrorFetching && props.isExtras && (
                    <CardPanel noTitle={true}>
                        <p>{props.loadingErrorMessage || t('global.errorLoading')}</p>
                        <button className="btn btn-primary" onClick={props.onRetry}>
                            Next step
                        </button>
                    </CardPanel>
                )}
                {props.hasErrorFetching && !props.isExtras && (
                    <CardPanel title="We encountered a problem">
                        <p className="text-danger">{props.loadingErrorMessage || t('global.errorLoading')}</p>
                        <button className="btn btn-primary" onClick={props.onRetry}>
                            {props.isExtras ? 'Next step' : t('buttons.retry')}
                        </button>
                        {props.isPayment && <a className={`btn btn-primary ml-3`} href='/'>
                            Search for another holiday
                        </a>}
                    </CardPanel>
                )}
                {!props.isFetching &&
                    !props.isSaving &&
                    !props.hasErrorFetching &&
                    props.hasErrorSaving &&
                    typeof props.errorResponse !== 'object' && (<p className="text-danger">{props.savingErrorMessage || t('global.errorSaving')}</p>)}
            </>
        )}
    </>
);

WithLoadingStates.defaultProps = {
    hasErrorFetching: false,
    hasErrorSaving: false,
    isFetching: false,
    isSaving: false,
};

WithLoadingStates.propTypes = {
    errorResponse: PropTypes.object,
    hasErrorFetching: PropTypes.bool,
    hasErrorSaving: PropTypes.bool,
    isFetching: PropTypes.bool,
    isSaving: PropTypes.bool,
    loadingErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    savingErrorMessage: PropTypes.string,
    onRetry: PropTypes.func,
};

export default WithLoadingStates;
