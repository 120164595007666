import React, { useEffect, useState, useRef } from 'react';
import { Alert, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { config, urls } from '../../../config';
import t from '../../../text';

function ContactUs() {
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [hideText, setHideText] = useState(false);

    const [apiFeedback, setFeedback] = useState([]);
    const [apiFeedbackStatus, setFeedbackStatus] = useState('');
    const [ticketNumber, setTicketNumber] = useState('');
    const [bookingReference, setBookingReference] = useState('');
    const [email, setEmail] = useState('');
    const [enquiry, setEnquiry] = useState('');
    const [title, setTitle] = useState('');
    const [initials, setFirstName] = useState('');
    const [lastName, setSurname] = useState('');
    const [address, setAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [comment, setComment] = useState('');
    const [checkbox, setCheckbox] = useState(false);

    // Refs
    const contactForm = useRef(null);

    const resetState = () => {
        setTicketNumber('');
        setBookingReference('');
        setEmail('');
        setEnquiry('');
        setTitle('');
        setFirstName('');
        setSurname('');
        setAddress('');
        setPostcode('');
        setComment('');
        setCheckbox(false);
        setValidated(false);
        setHideText(false);
    };

    const handleReset = () => {
        setFeedback([]);
    };

    const sendFormData = () => {
        if (validated) {
            let url = urls.contact;

            url += '?tickNum=' + encodeURIComponent(ticketNumber);
            url += '&bookRef=' + encodeURIComponent(bookingReference);
            url += '&email=' + encodeURIComponent(email);
            url += '&queryType=' + encodeURIComponent(enquiry);
            url += '&title=' + encodeURIComponent(title);
            url += '&initials=' + encodeURIComponent(initials);
            url += '&lastName=' + encodeURIComponent(lastName);
            url += '&addrLn1=' + encodeURIComponent(address);
            url += '&postCode=' + encodeURIComponent(postcode);
            url += '&comments=' + encodeURIComponent(comment);

            fetch(url)
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success === 0) {
                        data.errors.map((e) => setFeedback([...apiFeedback, e.message]));
                        setFeedbackStatus('danger');
                    } else {
                        if (data.data.infoMsg) {
                            setFeedback([
                                'We previously recieved your email and we will be in touch as soon as possible.',
                            ]);
                            setFeedbackStatus('secondary');
                            contactForm.current.reset();
                            resetState();
                        } else {
                            setFeedbackStatus('success');
                            setFeedback(['Thank you, we will be in touch as soon as possible.']);
                            contactForm.current.reset();
                            resetState();
                        }
                    }
                })
                .catch((e) => {
                    console.error('There was a problem', e);
                });
        }
        if (!validated) {
            setHideText(false);
        }
    };

    useEffect(sendFormData, [isValid]);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setFeedback([]);
        setValidated(true);
        setIsValid(event.currentTarget.checkValidity());
    };

    const handleSelect = () => {
        setHideText(true);
    };

    return (
        <Row>
            {/* Start of Form */}
            <Col lg={6}>
                <Form
                    noValidate
                    validated={validated}
                    className="bg-lighter p-4 border"
                    onSubmit={handleSubmit}
                    ref={contactForm}
                >
                    <h3>Fill in our contact form</h3>
                    <p className="m-0">You can contact us using our enquiry form, it’s easy and quick.</p>
                    <p className="mb-3">We will respond to all enquiries as soon as possible.</p>

                    <Form.Row>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formTicket">
                                <Form.Label>Ticket Number (if known)</Form.Label>
                                <Form.Control
                                    type="number"
                                    onChange={(e) => setTicketNumber(e.target.value)}
                                    value={ticketNumber}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formBookingRef">
                                <Form.Label>Booking Reference</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e) => setBookingReference(e.target.value)}
                                    value={bookingReference}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                        />
                        <Form.Control.Feedback type="invalid">Enter your email</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formEnquiry">
                        <Form.Label>Type of Enquiry</Form.Label>
                        <Form.Control
                            as="select"
                            defaultValue={enquiry}
                            onChange={(handleSelect, (e) => setEnquiry(e.target.value))}
                            required
                        >
                            <option value="" disabled>
                                Please choose one
                            </option>
                            <option value="GENR">General Enquiry</option>
                            <option value="BOOKING">Online Booking Enquiry</option>
                            <option value="PAYMENT">Online Payment Enquiry</option>
                            <option value="CHASING">Chasing Conf Enquiry</option>
                            <option value="EUROPE">European Enquiry</option>
                            <option value="OTHER">Other</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please select a type of enquiry
                        </Form.Control.Feedback>
                        {!hideText && (
                            <Form.Text>
                                In order to help us answer your query more efficiently, please select a type of
                                query from the list above.
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Row>
                        <Col xs={3}>
                            <Form.Group controlId="formTitle">
                                <Form.Control
                                    as="select"
                                    placeholder="Title"
                                    required
                                    defaultValue={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                >
                                    <option value="" disabled>
                                        Title
                                    </option>
                                    <option value="Dr">Dr</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Ms">Ms</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">Pick your title</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={9}>
                            <Form.Group controlId="formFirstname">
                                <Form.Control
                                    type="text"
                                    placeholder="First Name"
                                    value={initials}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Group controlId="formSurname">
                        <Form.Control
                            type="text"
                            placeholder="Surname"
                            value={lastName}
                            onChange={(e) => setSurname(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">Enter your surname</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formAddress">
                        <Form.Control
                            type="text"
                            placeholder="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formPostcode">
                        <Form.Control
                            type="text"
                            placeholder="Postcode"
                            value={postcode}
                            onChange={(e) => setPostcode(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formComment">
                        <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Comments (max 500 characters)"
                            maxLength="500"
                            resize="none"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            onChange={() => setCheckbox(!checkbox)}
                            label="I am happy to receive marketing materials"
                        />
                    </Form.Group>
                    <Button variant="primary" className="mb-3" block type="submit">
                        Submit
                    </Button>
                    {apiFeedback && apiFeedbackStatus && (
                        <Alert
                            className={`mb-0${apiFeedbackStatus !== 'secondary' && ' text-white'}`}
                            variant={apiFeedbackStatus}
                            show={apiFeedback.length > 0}
                            onClose={handleReset}
                            dismissible
                        >
                            {apiFeedbackStatus === 'success' && <i className="icon-check mr-1"></i>}
                            {apiFeedback}
                        </Alert>
                    )}
                </Form>
            </Col>
            {/* End of Form */}

            {/* Start of Contact Info */}
            <Col lg={6} className="mt-5 mt-lg-0">
                <h3>Other ways to contact us</h3>
                <p>
                    There are a number of ways you can contact us, but we recommend you refer to our FAQs first.
                    If you cannot find the answer you are looking for here then you can chat to us online, email
                    us or send us a letter. We will respond to all enquiries as soon as possible.
                </p>

                <h3 className="mt-5">Email</h3>
                <p>
                    Have a question that cannot be found in our FAQs section? Our Customer Service Team are
                    always ready to answer your questions by email.
                </p>
                <Row>
                    <Col lg={6}>
                        <a className="btn btn-primary btn-block" href={`mailto:${config['global.email']}`}>
                            Email Us
                        </a>
                    </Col>
                </Row>
                <h3 className="mt-5">Talk to us now</h3>
                <p>
                    Looking to book? Or have any questions about your holiday? Call one of our expert team now
                    on <a href={config['socialMedia.telephone']}>{t('header.cs.tel')}</a>
                </p>
                <h3 className="mt-5">Write to us</h3>
                <p>
                    When writing to us please enclose as much information about your booking as possible
                    including your contact details (email &amp; contact number), so we can respond to you
                    quickly.
                </p>
                <address>
                    <p>
                        BreakFree Holidays, <br />
                        42 Phoenix Court, <br />
                        Hawkins Road, <br />
                        Colchester, <br />
                        CO2 8JY
                    </p>
                </address>

                <h3 className="mt-5">Live Chat</h3>
                <p>We are open Monday to Friday, 9am - 5pm.</p>
                <Row>
                    <Col lg={6}>
                        <Link to="/my-account" className="btn btn-primary btn-block">
                            Manage Your Booking
                        </Link>
                    </Col>
                </Row>
            </Col>
            {/* End of Contact Info */}
        </Row>
    );
}

export default ContactUs;
