/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { startSearch } from '../../redux/search/form/actions';
import { cancelGetResults } from '../../redux/search/results/actions';
import { params as formParams, requestParams as formRequestParams } from '../../redux/search/form/selectors';
import { isSearchRequestPending } from '../../redux/search/results/selectors/data';
import {
    params as searchParams,
    pendingParams as pendingSearchParams,
} from '../../redux/search/results/selectors/selectors';
import { DDMMYYYYToDate, datesAreEqual } from '../../utils/date';

export const WithAutoSearchFromParams = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const _formParams = useSelector(formParams);
    const _formRequestParams = useSelector(formRequestParams);

    const _isSearchRequestPending = useSelector(isSearchRequestPending);
    const _searchParams = useSelector(searchParams);
    const _pendingSearchParams = useSelector(pendingSearchParams);

    const query = new URLSearchParams(location.search);
    const parkParam = query.get('park') || undefined;

    const autoSearchNeeded = () => {
        const fields = ['channel', 'region', 'date', 'nights', 'adults', 'children', 'infants'];

        let allFieldsPresent = true;
        fields.forEach((field) => {
            if (!query.has(field)) allFieldsPresent = false;
        });

        if (allFieldsPresent) {
            const p = _isSearchRequestPending ? _pendingSearchParams : _searchParams;

            return (
                query.get('channel') !== p.channelId?.toString() ||
                query.get('region') !== p.regionId?.toString() ||
                !datesAreEqual(DDMMYYYYToDate(query.get('date')), p.arrivalDate) ||
                query.get('nights') !== p.duration?.toString() ||
                query.get('adults') !== p.adults?.toString() ||
                query.get('children') !== p.children?.toString() ||
                query.get('infants') !== p.under5s?.toString() ||
                !!((parkParam || p.parkId?.toString()) && parkParam !== p.parkId?.toString())
            );
        }

        return false;
    };

    useEffect(() => {
        if (autoSearchNeeded()) {
            cancelGetResults();
            startSearch(dispatch, _formParams, _formRequestParams, parkParam);
        }
    }, []);

    return props.children;
};

export default WithAutoSearchFromParams;
