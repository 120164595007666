import React from 'react';
import PropTypes from 'prop-types';

function ImportantInfo(props) {
    return (
        <div className="content-box p-3">
            <p>{props.content}</p>
        </div>
    );
}

ImportantInfo.propTypes = {
    content: PropTypes.string,
};

export default ImportantInfo;
