import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { Col, Container, Dropdown, Row, Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { urls } from '../../../config';
import { channels } from '../../redux/data/channels/selectors';
import { getSpecificParks } from '../../redux/data/parks/selectors';
import t from '../../../text';
import PageTitle from '../../components/PageTitle';
import ScrollToTop from '../../components/ScrollToTop';
import { getParks } from '../../redux/data/parks/actions';
import { decodeHtml } from '../../utils/string';
import PopularHolidays from './TravelGuideChildren/PopularHolidays';
import PopularLocations from './TravelGuideChildren/PopularLocations';
import TruncatedTextAlt from '../../components/TruncatedTextAlt';
import SearchPopup from '../../components/Browse/SearchPopup';
import { selectChannel, selectRegion } from '../../redux/search/form/actions';

function TravelGuide() {
    const dispatch = useDispatch();
    const { regionId } = useParams();
    const [descriptionData, setDescriptionData] = useState('Just getting your content...');
    const [popLocData, setPopLocData] = useState([]);
    const [popHolsData, setPopHolsData] = useState([]);
    const [blogData, setBlogData] = useState([]);
    const [ttdData, setTtdData] = useState([]);
    const [expertInsight, setExpertInsight] = useState([]);
    const [sliderItemsRaw, setSliderItemsRaw] = useState([]);
    const [titleData, setTitleData] = useState('Loading...');
    const [metaTitle, setMetaTitle] = useState('Loading...');
    const [bottomText, setBottomText] = useState('');
    const [metaDesc, setMetaDesc] = useState('');
    const ExpertInsight = lazy(() => import('./TravelGuideChildren/ExpertInsight'));
    const ThingsToDo = lazy(() => import('./TravelGuideChildren/ThingsToDo'));
    const [activeTab, setActiveTab] = useState('popular-holidays');
    const availableTabs = [
        { name: 'Popular Holidays', key: 'popular-holidays' },
        { name: 'Popular Locations', key: 'popular-locations' },
        { name: 'Things to Do', key: 'things-to-do' },
        { name: 'Expert Insight', key: 'expert-insight' }
    ];
    const location = useLocation();
    const RegionName = useSelector((state) => state.data.regions.regions[regionId].name);

    useLayoutEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/travel-guides/?filter[meta_key]=region-id&filter[meta_value]=${regionId}&_fields=title,content,acf,bottom_text`,
            { signal: controller.signal },
        )
            .then((response) => response.json())
            .then((data) => {
                const wpData = data[0];
                setDescriptionData(wpData.content.rendered);
                setTitleData(wpData.title.rendered);
                setMetaTitle(wpData.acf.document_title || wpData.title.rendered);
                setMetaDesc(wpData.acf.meta_description || t('global.page.defaultMetaDesc'));
                setPopLocData(wpData.acf['popular-locations']);
                setPopHolsData(wpData.acf['popular_holidays'] || []);
                setTtdData(wpData.acf['things-to-do']);
                setExpertInsight(wpData.acf['expert-insight']);
                setBlogData(wpData.acf['travel-blogs'] || []);
                setSliderItemsRaw(wpData.acf['travel_guide_images'] || []);
                if (wpData.acf['bottom_text']) {
                    setBottomText(decodeHtml(wpData.acf['bottom_text']).replace(/\n/g, '<br />'));
                }
            })
            .catch((e) => {
                console.error('Could not fetch the content.', e);
                setDescriptionData('Sorry, there was a problem fetching the content.  Please try again.');
                setTitleData('Could not fetch the content.');
            });

        return () => {
            controller.abort();
        };
    }, [regionId]);

    useEffect(() => {
        selectChannel(dispatch, 'ukpromo');
        selectRegion(dispatch, regionId);
    }, [dispatch, regionId]);

    // Following code is to determine data and channel codes for popular holidays,
    // done in the parent so the tab can be hidden if there are no results

    // This makes sure all the parks for this region are in redux
    getParks(dispatch, regionId, true);

    // Get available channels for this skin, filter out bad ones
    const availableChannels = useSelector(channels);
    const channelCodes = availableChannels.map((channel) => channel.code);
    const dataWithCorrectChannels = popHolsData.filter((park) => channelCodes.includes(park.channel_id));

    // Get the id's of the park we want and then fetch the parks from redux
    const parkIds = dataWithCorrectChannels.map((park) => parseInt(park.park_id));
    const _parksData = useSelector((state) => getSpecificParks(state, parkIds));

    // Get the channels of the parks we have
    const parkChannels = popHolsData.map((park) => park.channel_id);

    // Filter and merge the data and channels into an array of objects rather than two arrays
    let mergedData = [];
    _parksData.forEach((park, i) => {
        if (typeof park !== 'undefined' && park.regn === regionId) {
            mergedData.push({ park, channel: parkChannels[i] });
        }
    });

    let SliderItems = [];

    if (sliderItemsRaw.length > 0) {
        SliderItems = sliderItemsRaw.map((img, i) => (
            <img
                key={i + 1}
                className="img-fluid"
                src={img.image_url}
                alt={`${titleData.split(' ').slice(0, -2).join(' ')} ${i + 1}`}
                loading="lazy"
            />
        ));
    }

    const gallerySettings = {
        className: "park-page-gallery",
        lazyLoad: true,
        infinite: true,
        centerMode: true,
        centerPadding: "0",
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    className: 'park-page-gallery-unset',
                    centerMode: false,
                    slidesToShow: 1,
                }
            }
        ]
    };

    const getContent = (activeTab) => {
        switch (activeTab) {
            case 'popular-holidays':
                return <PopularHolidays data={mergedData} />;
            case 'popular-locations':
                return  <PopularLocations popLocData={popLocData} />;
            case 'things-to-do':
                return <Suspense fallback={<div></div>}><ThingsToDo ttdData={ttdData} /></Suspense>;
            case 'expert-insight':
                return <Suspense fallback={<div></div>}><ExpertInsight expertInsight={expertInsight} blogData={blogData} /></Suspense>;
            default:
                return <div></div>;
        }
    };

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDesc.length > 0 ? metaDesc : ''} />
                <link
                    rel="canonical"
                    href={`https://www.breakfreeholidays.co.uk${location.pathname}${location.search}`}
                />
            </Helmet>
            <ScrollToTop />
            <PageTitle title={titleData} />

            <Container className="mb-3" fluid>
                <Row>
                    <Col style={{ minHeight: '246px' }}>
                        <Suspense fallback={<img src="/img-loading.svg" width="100%" alt="Loading" />}>
                            <Slider className="full-height-slides" {...gallerySettings}>
                                {SliderItems}
                            </Slider>
                        </Suspense>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="bg-secondary mb-3">
                <Container>
                    <Row className="d-flex align-items-center">
                        <Col>
                            <p className="h3 my-3 text-center text-md-left">Book your next holiday to {RegionName} today...</p>
                        </Col>
                        <Col xs={12} md="auto">
                            <SearchPopup variant="alt-primary" className="my-3" />
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container>
                <Row>
                    <Col lg={9}>
                        <Tabs
                            className="park-page-tabs d-none d-lg-grid mb-3 border"
                            activeKey={activeTab}
                            onSelect={(k) => setActiveTab(k)}
                        >
                           {availableTabs.map((tab, i) =>
                                <Tab
                                    key={i}
                                    eventKey={tab.key}
                                    onClick={() => setActiveTab(tab.key)}
                                    title={tab.name}
                                />
                            )}
                        </Tabs>

                        <Dropdown className="vertical-tab-menu-sm mb-5 d-block d-lg-none">
                            <Dropdown.Toggle
                                as="button"
                                type="button"
                                className="toggle-popup is-dropdown border"
                                role="button"
                            >
                                <span>
                                    {availableTabs.filter((obj) => obj.key === activeTab)[0].name}
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {availableTabs.map((item) => (
                                    <Dropdown.Item key={item.key} onClick={() => setActiveTab(item.key)}>
                                        {item.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        {getContent(activeTab)}
                    </Col>
                    <Col lg={3}>
                        <TruncatedTextAlt text={descriptionData.replace(/[\n]+/g, '')} isHTML lines={5} />
                    </Col>
                </Row>
            </Container>


            {bottomText.length > 0 && (
                <Container className="mt-5">
                    <Row>
                        <Col>
                            <p
                                dangerouslySetInnerHTML={{ __html: bottomText }}
                                style={{
                                    overflowWrap: 'anywhere',
                                }}
                            ></p>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
}

export default TravelGuide;
