import { GET_PARKS } from './action-types';
import { apiRequest, checkRequestNeeded } from '../../apiRequest';
import { urls } from '../../../../config';
import { getHasFailed, getIsLoading, getIsLoaded } from './selectors';

export const getParks = (dispatch, regionId, force = false) => {
    dispatch((d, getState) => {
        const state = getState();
        const isLoaded = getIsLoaded(state, regionId);
        const isLoading = getIsLoading(state, regionId);
        const hasFailed = getHasFailed(state, regionId);

        if (checkRequestNeeded(true, isLoading, isLoaded, hasFailed, force)) {
            return apiRequest(d, GET_PARKS, urls.parks, { region: regionId }, regionId);
        }
    });
};
