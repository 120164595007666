import React from 'react';
import PropTypes from 'prop-types';

import InputCheckbox from '../../../FormElements/InputCheckbox';
import InputNumber from '../../../FormElements/InputNumber';
import CardPanel from '../../../CardPanel';

export const ExtrasFormOption = (props) => {
    const parsedCost = (cost) => {
        return parseFloat(cost).toFixed(2);
    }

    return (
        <CardPanel accordion accordionId={props.values[0].code} title={props.values[0].group} opened={props.values[0].group === "Insurance" || props.values[0].group === "Pets"} className="mb-3 extras-card">
            {props.values.map((extra, index) => (
                <div key={index}>
                    {extra.desc && <div className="mb-2" dangerouslySetInnerHTML={{ __html: extra.desc }} />}
                    <div
                        className={`mb-3 form-elements-wrapper ${extra.type === 'boolean' ? 'form-elements-wrapper--can-grow' : ''}`}
                    >
                        {extra.type === 'boolean' ? (
                            <InputCheckbox
                                id={extra.code}
                                label={`${extra.name}: <b>£${parsedCost(extra.cost)}</b>`}
                                ariaLabel={`${extra.name}: £${parsedCost(extra.cost)}`}
                                checked={!!extra.value}
                                onChange={(val) => props.onChange(extra.code, Number(val))}
                            />
                        ) : (
                            <>
                                <p className="mb-1">
                                    {extra.name} : <b>{`${extra.code === 'extLUXPET' ? '' : `£${parsedCost(extra.cost)}`}`}</b>
                                </p>
                                <InputNumber
                                    id={extra.code}
                                    min={parseInt(extra.min, 10)}
                                    max={parseInt(extra.max, 10)}
                                    value={extra.value}
                                    onChange={(val) => props.onChange(extra.code, val)}
                                />
                            </>
                        )}
                    </div>
                </div>
            ))
            }

        </CardPanel>
    );
};

ExtrasFormOption.defaultProps = {};

ExtrasFormOption.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    code: PropTypes.string,
    name: PropTypes.string,
    cost: PropTypes.string,
    dep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    desc: PropTypes.string,
    value: PropTypes.number,
};

export default ExtrasFormOption;
