import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import persistState from 'redux-sessionstorage';
import thunk from 'redux-thunk';

import appStateReducer from './appState/reducer';
import headerReducer from './header/reducer';
import homepageReducer from './homepage/reducer';
import initReducer from './init/reducer';
import requestsReducer from './requests/reducer';

import bookingReducer from './booking/booking/reducer';
import bookingFormReducer from './booking/form/reducer';
import bookingExtrasReducer from './booking/extras/reducer';
import bookingPaymentReducer from './booking/payment/reducer';

import channelsReducer from './data/channels/reducer';
import regionsReducer from './data/regions/reducer';
import filtersReducer from './data/filters/reducer';
import operatorsReducer from './data/operators/reducer';
import parkDetailsReducer from './data/park/details/reducer';
import parkResultsReducer from './data/park/results/reducer';
import parkReviewsReducer from './data/park/reviews/reducer';
import parksReducer from './data/parks/reducer';
import unitTypesReducer from './data/unitTypes/reducer';

import pageBrowseOperatorRegionsReducer from './pages/browseOperatorRegions/reducer';
import pageBrowseOperatorsReducer from './pages/browseOperators/reducer';
import pageBrowseParksReducer from './pages/browseParks/reducer';
import pageBrowseRegionsReducer from './pages/browseRegions/reducer';

import parkReducer from './park/reducer';

import searchFormReducer from './search/form/reducer';
import searchResultsReducer from './search/results/reducer';

import userReducer from './user/reducer';

const rootReducer = combineReducers({
    appState: appStateReducer,
    booking: combineReducers({
        booking: bookingReducer,
        form: bookingFormReducer,
        extras: bookingExtrasReducer,
        payment: bookingPaymentReducer,
    }),
    data: combineReducers({
        channels: channelsReducer,
        filters: filtersReducer,
        operators: operatorsReducer,
        parks: parksReducer,
        park: combineReducers({
            details: parkDetailsReducer,
            results: parkResultsReducer,
            reviews: parkReviewsReducer,
        }),
        regions: regionsReducer,
        unitTypes: unitTypesReducer,
    }),
    header: headerReducer,
    homepage: homepageReducer,
    init: initReducer,
    pages: combineReducers({
        browseOperatorRegions: pageBrowseOperatorRegionsReducer,
        browseOperators: pageBrowseOperatorsReducer,
        browseParks: pageBrowseParksReducer,
        browseRegions: pageBrowseRegionsReducer,
    }),
    park: parkReducer,
    requests: requestsReducer,
    search: combineReducers({
        form: searchFormReducer,
        results: searchResultsReducer,
    }),
    user: userReducer,
});

export const store = createStore(rootReducer, composeWithDevTools(persistState(), applyMiddleware(thunk)));

export default store;
