import { GET_PARK_REVIEWS } from './action-types';
import { apiRequest, checkRequestNeeded } from '../../../apiRequest';
import { urls } from '../../../../../config';
import { getIsLoaded, getIsLoading, getHasFailed } from './selectors';

export const getParkReviews = (dispatch, parkId, force = false) => {
    dispatch((d, getState) => {
        const state = getState();
        const isLoaded = getIsLoaded(state, parkId);
        const isLoading = getIsLoading(state, parkId);
        const hasFailed = getHasFailed(state, parkId);

        if (checkRequestNeeded(true, isLoading, isLoaded, hasFailed, force)) {
            return apiRequest( d, GET_PARK_REVIEWS, urls.parkReviews, { parkId }, parkId);
        }
    });
};
