import { useSelector } from 'react-redux';

import {
    bookingHasError,
    bookingIsFetching,
    bookingIsLoaded,
    confirmationId,
    pendingParams,
} from '../../redux/booking/booking/selectors';
import useDataLoader from '../../hooks/useDataLoader';

export function WithBooking(props) {
    const _pendingParams = useSelector(pendingParams);
    const _confirmationId = useSelector(confirmationId);
    const _isNewBooking = _pendingParams.type === 'new';

    const getData = () => {}; // This HOC does not dispatch any actions, it reacts to the booking currently in progress
    const _hasFailed = useSelector((state) => bookingHasError(state));
    const _isLoaded = useSelector((state) => bookingIsLoaded(state));
    const _isLoading = useSelector((state) => bookingIsFetching(state));
    const _isReady = _isNewBooking && !_confirmationId && _isLoading; // Is loading in here is needed as this does not fire off requests for data
    const extraProps = (state) => ({
        isBooking: state.isFetching,
        hasBookingError: _isNewBooking && _hasFailed,
    });

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady, extraProps);
}

export default WithBooking;
