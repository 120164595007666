import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Tab, Tabs } from 'react-bootstrap';

function ParkInformationTabs(props) {
    return (
        <>
            <Tabs
                className="park-page-tabs d-none d-lg-grid mb-3 border"
                defaultActiveKey={props.active}
                onSelect={(k) => props.clicked(k)}
                id="qwerty"
            >
                {props.availableTabs.map((tab, i) =>
                    <Tab
                        key={i}
                        eventKey={tab.key}
                        onClick={() => props.clicked(tab.key)}
                        title={tab.name}
                    />
                )}
            </Tabs>
            {
                (props.availableTabs.filter((obj) => obj.key === props.active).length > 0) ? (
                    <Dropdown className="vertical-tab-menu-sm mb-5 d-block d-lg-none">
                        <Dropdown.Toggle as="button" type="button" className="toggle-popup is-dropdown border" role="button">
                            <span className="">{props.availableTabs.filter((obj) => obj.key === props.active)[0].name}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {props.availableTabs.map((item) => (
                                <Dropdown.Item key={item.key} onClick={() => props.clicked(item.key)}>
                                    {item.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                ) : <></>
            }
        </>
    );
}

ParkInformationTabs.propTypes = {
    active: PropTypes.string,
    clicked: PropTypes.func,
    availableTabs: PropTypes.array,
};

export default ParkInformationTabs;
