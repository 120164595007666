import React from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import InputRadioButton from '../../../FormElements/InputRadioButton';
import { getDayMonthYearFromDate, DDMMYYYYToDate } from '../../../../utils/date';
import t from '../../../../../text';

export const PaymentOptionsForm = (props) => (
    <Form name="paymentOptionsForm" noValidate>
        {props.depositAvailable ? (
            <>
                <p>{t('forms.paymentOptions.introText')}</p>
                <Form.Row className='payment-options'>
                    <Form.Group as={Col}>
                        <div className="d-inline-block mb-2 mr-2 pr-1">
                            <InputRadioButton
                                name="paymentOption"
                                value="full"
                                label={t('forms.paymentOptions.fullPayment', props.totalCostFull.toFixed(2))}
                                checked={props.selectedOption === 'full'}
                                onChange={props.onChange}
                            />
                        </div>
                        <div className="d-inline-block">
                            <InputRadioButton
                                name="paymentOption"
                                value="deposit"
                                label={t('forms.paymentOptions.depositPayment', props.totalCostDeposit.toFixed(2))}
                                checked={props.selectedOption === 'deposit'}
                                onChange={props.onChange}
                            />
                        </div>
                    </Form.Group>
                </Form.Row>
                {props.selectedOption === 'deposit' && (
                    <p className="font-weight-bold">
                        {t(
                            'forms.paymentOptions.outstandingAmountToPay',
                            getDayMonthYearFromDate(DDMMYYYYToDate(props.dueDate)),
                            props.totalCostRemaining.toFixed(2),
                        )}
                    </p>
                )}
            </>
        ) : (
            <>
                <p>{props.depositText}</p>
            </>
        )}
    </Form>
);

PaymentOptionsForm.defaultProps = {
    deposit: 0,
    depositAvailable: false,
    depositText: t('forms.paymentOptions.depositUnavailable'),
    totalCostDeposit: 0,
    totalCostFull: 0,
    totalCostRemaining: 0,
};

PaymentOptionsForm.propTypes = {
    deposit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    depositAvailable: PropTypes.bool,
    depositText: PropTypes.string,
    totalCostDeposit: PropTypes.number,
    totalCostFull: PropTypes.number,
    totalCostRemaining: PropTypes.number,
    selectedOption: PropTypes.oneOf(['full', 'deposit']),
    onChange: PropTypes.func,
};

export default PaymentOptionsForm;
