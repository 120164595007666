import { GET_EXTRA_DATA } from './action-types';
import { apiRequest, checkRequestNeeded } from '../../apiRequest';
import { getExtraDataHasFailed, getExtraDataIsLoading, getExtraDataIsLoaded } from './selectors';
import { urls } from '../../../../config';

const getExtraDataUrl = (parkID) => urls.unitTypesExtraDataPID(parkID);

export const getExtraData = (dispatch, parkId, force = false) => {
    dispatch((d, getState) => {
        const state = getState();
        const isLoaded = getExtraDataIsLoaded(state, parkId);
        const isLoading = getExtraDataIsLoading(state, parkId);
        const hasFailed = getExtraDataHasFailed(state, parkId);
        const url = getExtraDataUrl(parkId);

        if (checkRequestNeeded(true, isLoading, isLoaded, hasFailed, force)) {
            return apiRequest(d, GET_EXTRA_DATA, url, { parkId }, parkId);
        }
    });
};
