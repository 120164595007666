import { GET_INIT_DATA } from '../../init/action-types';
import { channels } from '../../data/channels/selectors';
import { regions, channelRegionIds } from '../../data/regions/selectors';
import { getIsFetching } from '../../requests/selectors';
import { getRegionsIsLoading, getRegionsIsLoaded, getRegionsHasFailed } from '../../data/regions/selectors';

import { dateToDDMMYYYY } from '../../../utils/date';
import { getChannelById } from '../../data/channels/utils';
import { getRegionsByChannelId, getRegionById } from '../../data/regions/utils';
//import { boolToyn } from '../../../utils/utils';

export const channelId = (state) => state.search.form.channelId;
export const regionId = (state) => state.search.form.regionId;
export const arrivalDate = (state) => state.search.form.arrivalDate;
export const duration = (state) => state.search.form.duration;
export const adults = (state) => state.search.form.adults;
export const children = (state) => state.search.form.children;
export const under5s = (state) => state.search.form.under5s;
export const cottageDates = (state) => state.search.form.cottageDates;
export const selectedNewspaper = (state) => state.search.form.selectedNewspaper;
export const availableDates = (state) => state.search.form.availableDates;
export const currentCottageDates = (state) => {
    const cottageDatesFound = cottageDates(state);
    if (cottageDatesFound) return cottageDatesFound[regionId(state)];
};
export const cottageTTL = (state) => {
    const cottageDatesFound = cottageDates(state);
    if (cottageDatesFound) return cottageDatesFound.ttl;
};
export const petFriendly = (state) => {
    return channelId(state) === 'eurocaravan' ? undefined : state.search.form.petFriendly;
};

export const params = (state) => ({
    channelId: channelId(state),
    regionId: regionId(state),
    arrivalDate: arrivalDate(state),
    duration: duration(state),
    adults: adults(state),
    children: children(state),
    under5s: under5s(state),
    petFriendly: petFriendly(state),
});

export const requestParams = (state) => ({
    channel: channelId(state),
    region: regionId(state),
    date: dateToDDMMYYYY(new Date(arrivalDate(state))),
    partysize: adults(state) + children(state) + under5s(state),
    nights: duration(state),
    adults: adults(state),
    children: children(state),
    infants: under5s(state),
    dogs: petFriendly(state) ? 'y' : '',
});

export const channelsLoading = (state) => getIsFetching(state, GET_INIT_DATA);
export const regionsLoading = (state) => getRegionsIsLoading(state, channelId(state));
export const regionsNeedToBeLoaded = (state) => {
    const _channelId = channelId(state);

    return (
        _channelId &&
        !getRegionsIsLoading(state, _channelId) &&
        !getRegionsIsLoaded(state, _channelId) &&
        !getRegionsHasFailed(state, _channelId)
    );
};

export const currentChannel = (state) => {
    return getChannelById(channels(state), channelId(state));
};
export const currentRegions = (state) => {
    return getRegionsByChannelId(regions(state), channelRegionIds(state), channelId(state));
};
export const currentRegion = (state) => {
    return getRegionById(regions(state), regionId(state));
};
