import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils/string';

function TownsList({ towns, region, channel, forwardClassNames = '' }) {
    const RegionName = useSelector((state) => state.data.regions.regions[region]?.name);
    if(RegionName !== undefined){
        return (
            <div className={forwardClassNames}>
                {towns.length > 0 && (
                    <>
                        <h3 className="h4 mt-5">Towns &amp; Cities in {RegionName}</h3>
                        <p>Use the links below to view results for a specific area.</p><ul className="towns-list list-unstyled">
                            {towns.map((t, i) => (
                                <li key={i} className="mb-2">
                                    <Link to={`/area/${channel}/${region}/${t.id}/${slugify(t.name)}`}>{t.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        )
    } else {
        return (<></>);
    }
}

export default TownsList;
