import { connect } from 'react-redux';
import Extras from './Extras';

import { stepCompleted } from '../../../redux/booking/booking/actions';
import { bookingId, message } from '../../../redux/booking/booking/selectors';

import { BOOKING_STEPS } from '../../../Constants';

const mapStateToProps = (state) => ({
    bookingId: bookingId(state),
    message: message(state)
});

const mapDispatchToProps = (dispatch) => ({
    stepCompleted: () => stepCompleted(dispatch, BOOKING_STEPS.EXTRAS),
});

export const ExtrasConnected = connect(mapStateToProps, mapDispatchToProps)(Extras);
export default ExtrasConnected;
