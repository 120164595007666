import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../../components/PageTitle';
import RedEye from '../../components/RedEye/RedEye';
import RegionCard from '../../components/Browse/RegionCard';
import ScrollToTop from '../../components/ScrollToTop';
import SearchBox from '../../components/SearchBox/SearchBox';
import WithLoadingStates from '../../components/HOC/WithLoadingStates';
import WithRegions from '../../components/HOC/WithRegions';
import { selectChannel } from '../../redux/pages/browseRegions/actions';
import { selectChannel as search_selectChannel } from '../../redux/search/form/actions';
import { channel, channelId, channelRegions } from '../../redux/pages/browseRegions/selectors';

import t from '../../../text';
import useSeoText from '../../hooks/useSeoText';
import TruncatedTextAlt from '../../components/TruncatedTextAlt';
import { bookingMode } from '../../redux/booking/booking/selectors';

export const Regions = () => {
    const dispatch = useDispatch();
    const { channelCode } = useParams();
    const _channelId = useSelector(channelId);
    const _channel = useSelector(channel);
    const _channelRegions = useSelector(channelRegions);
    const [topSeoText, bottomSeoText] = useState(useSeoText());
    const { pathname } = useLocation();
    const isPetFriendly = /^\/dog-friendly/.test(pathname);
    const _bookingMode = useSelector(bookingMode);

    useEffect(() => {
        selectChannel(dispatch, channelCode);
        search_selectChannel(dispatch, channelCode);
    }, [dispatch, channelCode]);

    useLayoutEffect(() => {
        if (_channelRegions && isPetFriendly) {
            _channelRegions.filter((r) => r.dogs === 'Y');
        }
    }, [_channelRegions, isPetFriendly]);

    return (
        <>
            <Helmet>
                <title>{t('browse.regions.title', isPetFriendly, _channel?.name)}</title>
                <link
                    rel="canonical"
                    href={`https://www.breakfreeholidays.co.uk${pathname}`}
                />
            </Helmet>
            <ScrollToTop />
            <div className="channel-regions-page">
                <Container fluid className="bg-dark py-4">
                    <Container>
                        <SearchBox />
                    </Container>
                </Container>

                <WithRegions channelId={_channelId}>
                    <WithLoadingStates loadingErrorMessage={t('browse.regions.errorLoading')} scrollToTop useContainer>
                        <PageTitle title={t('browse.regions.title', isPetFriendly, _channel?.name)} />

                        <Container>
                            <TruncatedTextAlt text={topSeoText} className="mb-4" />
                            <Row xs={1} md={2} lg={3}>
                                {_channelRegions &&
                                    _channelRegions.map((region) => (
                                        <RegionCard
                                            key={region.id}
                                            channelCode={channelCode}
                                            {...region}
                                            url={channelCode === 'boats' ? '/boating-holidays' : '/holidays'}
                                            showPetFriendly={isPetFriendly}
                                            bookingMode={_bookingMode}
                                        />
                                    ))}
                            </Row>
                            <TruncatedTextAlt text={bottomSeoText} className="mb-4" />
                        </Container>

                        <RedEye
                            params={{
                                nourl: `www.breakfreeholidays.co.uk/region/${channelCode}`,
                                view_category: channelCode,
                            }}
                        />
                    </WithLoadingStates>
                </WithRegions>
            </div>
        </>
    );
};

export default Regions;
