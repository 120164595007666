import { useDispatch, useSelector } from 'react-redux';

import { validateFields } from './PartyDetailsForm';
import { stepCompleted } from '../../../../redux/booking/booking/actions';
import { changePartyDetail, selectAreYouGoing, setPartyDetails } from '../../../../redux/booking/form/actions';

import { bookingId } from '../../../../redux/booking/booking/selectors';
import { details, loggedIn } from '../../../../redux/user/selectors';
import {
    partyAreYouGoing,
    partyValues,
    partyHasSaveError,
    partyIsSaving,
    partySaveError,
} from '../../../../redux/booking/form/selectors';
import { adults, children, under5s } from '../../../../redux/search/form/selectors';

import useReduxFormState from '../../../../hooks/useReduxFormState';

import { BOOKING_STEPS } from '../../../../Constants';
import { calculateAge } from '../../../../utils/date';

export const usePartyDetailsFormActions = () => {
    const dispatch = useDispatch();
    return {
        selectAreYouGoing: (key, value, userInfo) => selectAreYouGoing(dispatch, key, value, userInfo),
        setPartyDetails: (bookId, size, details) => setPartyDetails(dispatch, bookId, size, details),
        stepComplete: () => stepCompleted(dispatch, BOOKING_STEPS.PARTY),
    };
};

export const usePartyDetailsFormState = () => {
    const _details = useSelector(details);
    const _loggedIn = useSelector(loggedIn);
    const ageDate = new Date();
    ageDate.setFullYear(parseInt(_details.dobYr), parseInt(_details.dobMnth) - 1, parseInt(_details.dobDay));

    return {
        ...useReduxFormState(partyValues, changePartyDetail, validateFields),
        adults: useSelector(adults),
        areYouGoing: useSelector(partyAreYouGoing),
        bookingId: useSelector(bookingId),
        children: useSelector(children),
        loggedIn: _loggedIn,
        partyHasSaveError: useSelector(partyHasSaveError),
        partyIsSaving: useSelector(partyIsSaving),
        partySaveError: useSelector(partySaveError),
        under5s: useSelector(under5s),
        user: _loggedIn
            ? {
                pt1: _details.title,
                pi1: _details.firstName[0].toUpperCase(),
                pn1: _details.lastName,
                pa1: calculateAge(ageDate),
                dob1: `${_details.dobDay}/${_details.dobMnth}/${_details.dobYr}`,
            }
            : undefined,
    };
};

export default usePartyDetailsFormState;
