import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Card } from 'react-bootstrap';

import NoImage from '../../assets/images/no-image-640-320.png';
import { slugify } from '../../utils/string';
import t from '../../../text';
import SearchPopup from './SearchPopup';

export const RegionCard = ({ name, desc, url, id, channelCode, opId, showPetFriendly, bookingMode }) => {
    const imgUrl = showPetFriendly
        ? `//content.breakfreeholidays.co.uk/images/pet-friendly/${id}.jpg`
        : `//content.breakfreeholidays.co.uk/assets/images/regions/${id}/hero.jpg`;

    return (
        <Col>
            <Card>
                <Card.Img
                    src={imgUrl}
                    alt={name}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = NoImage;
                    }}
                />
                <Card.Body>
                    <Card.Title className="text-primary" as="h4">
                        {name}
                    </Card.Title>
                    <Card.Text className="text-dark">{desc}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    {/* bookingMode && <SearchPopup variant="alt-primary" regionId={id} className="smallText mb-3" /> */}
                    <Link
                        to={`${url}/${channelCode}/${id}/${slugify(name)}${opId ? '?operators=' + opId : ''}${
                            showPetFriendly ? '?dogs=Y' : ''
                        }`}
                        className="btn btn-primary py-2 btn-block smallText"
                    >
                        {t('buttons.viewX', name)}
                    </Link>
                </Card.Footer>
            </Card>
        </Col>
    );
};

RegionCard.defaultProps = {
    url: '/holidays',
};

RegionCard.propTypes = {
    name: PropTypes.string,
    desc: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.string,
    channelCode: PropTypes.string,
    opId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RegionCard;
