import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

export const TruncatedTextAlt = (props) => {
    const [disableTruncate, setDisableTruncate] = useState(false)
    const copyBox = useRef(null);

    const removeTruncate = () => {
        copyBox.current.classList.remove('truncated');
        setDisableTruncate(true);
    };

    const isIOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform);

    useLayoutEffect(() => {
        if (props.text == null) return;
        if (!disableTruncate) copyBox.current.classList.add('truncated');
        let diff = copyBox.current.scrollHeight - copyBox.current.getClientRects()[0].height;
        if (diff < 2 && props.text !== null) removeTruncate();
    }, [copyBox, props.text]);

    useEffect(() => {
        setDisableTruncate(isIOS);
    }, []);

    return (
        props.text !== null && (
            <div className={`truncated-text-alt ${props.className || ''}`}>
                {props.isHTML ? (
                    <div
                        className={`copy ${disableTruncate ? '' : 'truncated'}`}
                        ref={copyBox}
                        dangerouslySetInnerHTML={{ __html: props.text }}
                        style={{ WebkitLineClamp: props.lines && !disableTruncate ? props.lines.toString() : disableTruncate ? "999" : "3" }}
                    />
                ) : (
                    <div
                        className={`copy ${disableTruncate ? '' : 'truncated'}`}
                        ref={copyBox}
                        style={{ WebkitLineClamp: props.lines && !disableTruncate ? props.lines.toString() : disableTruncate ? "999" : "3" }}
                    >
                        {props.text}
                    </div>
                )}
                <Button size="sm" variant="alt-primary" onClick={removeTruncate} className="mt-2">
                    Read more
                </Button>
            </div>
        )
    );
};

TruncatedTextAlt.defaultProps = {
    lines: 3,
    text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pulvinar, diam ut blandit efficitur, augue arcu congue urna, eu hendrerit magna tortor sit amet sem. Nunc ultrices, tellus at pretium rutrum, ex nulla ornare nibh, nec finibus nunc lacus vel eros. Quisque commodo turpis id odio facilisis, quis blandit odio rhoncus. Sed faucibus dignissim nisi vel vulputate. Duis consequat laoreet quam in sagittis. Quisque et felis ut magna lobortis elementum semper dignissim nibh. Curabitur eu convallis sem. Pellentesque non lacus nibh. Duis sed aliquam nunc. Fusce tristique, lacus sit amet viverra accumsan, tortor sem finibus dolor, quis eleifend massa ligula et orci.',
};

export default TruncatedTextAlt;
