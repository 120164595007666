import { connect } from 'react-redux';
import { loggedIn } from '../../redux/user/selectors';
import Login from './Login';

const mapStateToProps = (state) => ({
    loggedIn: loggedIn(state),
});

const mapDispatchToProps = () => ({});

export const LoginConnected = connect(mapStateToProps, mapDispatchToProps)(Login);
export default LoginConnected;
