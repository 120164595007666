import React, { useEffect } from 'react';

import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import CardPanel from '../../../components/CardPanel';
import ExtrasForm from '../../../components/Forms/Booking/ExtrasForm/ExtrasForm';
import WithHolidaySummary from '../../../components/Booking/WithHolidaySummary';
import WithLoadingStates from '../../../components/Booking/WithLoadingStates';

import { arrayToObject } from '../../../utils/utils';
import { useExtrasFormActions, useExtrasFormState } from '../../../components/Forms/Booking/ExtrasForm/useExtrasForm';
import t from '../../../../text';
import useNextStep from '../../../components/Booking/useNextStep';

import PartyDetailsForm from '../../../components/Forms/Booking/PartyDetailsForm/PartyDetailsForm';
import {
    usePartyDetailsFormState,
    usePartyDetailsFormActions,
} from '../../../components/Forms/Booking/PartyDetailsForm/usePartyDetailsForm';
import { channelId } from '../../../redux/booking/booking/selectors';

export const Extras = ({ history, bookingId, stepCompleted, message }) => {
    const { setExtras, getExtras } = useExtrasFormActions();
    const { selectAreYouGoing, setPartyDetails } = usePartyDetailsFormActions();
    const party = usePartyDetailsFormState();
    const _channelId = useSelector(channelId);
    const {
        values: extrasValues,
        onChange,
        isFetching,
        isSaving,
        hasErrorFetching,
        hasErrorSaving,
        errorDataSaving
    } = useExtrasFormState();
    const nextStep = useNextStep();

    const loadExtras = () => {
        getExtras(bookingId);
    }

    const partyForm = (
        <PartyDetailsForm
            adults={party.adults}
            // eslint-disable-next-line react/no-children-prop
            children={party.children}
            under5s={party.under5s}
            areYouGoing={party.loggedIn ? party.areYouGoing : 'N'}
            values={party.values}
            errors={party.errors}
            onChangePartyDetail={party.onChange}
            onSelectAreYouGoing={(key, value) => selectAreYouGoing(key, value, party.user)}
            showAreYouGoing={party.loggedIn}
            dob={_channelId === "eurocaravan"}
        />
    );

    const removeFields = (values, partySize, field) => {
        const output = { ...values };
        let i = 0;

        for (i; i <= partySize; i++) {
            delete output[`${field}${i}`];
        }

        return output;
    };

    const handleNext = () => {
        party.validate();
        if (party.isValid()) {
            const size = party.adults + party.children + party.under5s;
            let partyValues;

            partyValues = _channelId === "eurocaravan" ? party.values : removeFields(party.values, size, 'dob');
            setPartyDetails(party.bookingId, size, partyValues)
                .request.then(() => {
                    const extrasObject = arrayToObject(extrasValues, 'code', 'value');
                    setExtras(bookingId, extrasObject)
                        .request.then(() => {
                            stepCompleted();
                            history.push(`/booking/${nextStep}`);
                        })
                        .catch(() => {
                            // Do nothing upon error, we'll react to error flags in redux state
                        });
                })
                .catch(() => {
                    // Do nothing upon error, we'll react to error flags in redux state
                });
        }
    };

    useEffect(loadExtras, [bookingId]); // Reload extras on mount / if the booking ID changes

    return (
        <>
            {message && (
                <Container>
                    <div
                        className='important-box p-3'
                        dangerouslySetInnerHTML={{ __html: message }}
                    ></div>
                </Container>
            )}
            <WithHolidaySummary
                nextButtonText="Continue"
                onNext={handleNext}
                disableNext={isFetching}
                isSaving={isSaving}
            >
                <WithLoadingStates
                    isExtras
                    theExtras={extrasValues}
                    errorResponse={party.partySaveError || errorDataSaving}
                    hasErrorSaving={party.partyHasSaveError || hasErrorSaving}
                    isSaving={party.partyIsSaving || isSaving}
                    loadingErrorMessage={t('booking.party.errorLoading')}
                    savingErrorMessage={t('booking.party.errorSaving')}
                    hasErrorFetching={hasErrorFetching}
                    isFetching={isFetching}
                    onRetry={handleNext}
                >
                    <CardPanel accordion opened accordionId="party-details" className="overflow-visible" title="Your Party Details">
                        {partyForm}
                    </CardPanel>
                    {extrasValues.length > 0 && (
                        <CardPanel accordion opened accordionId="extras" title="Optional Holiday Extras">
                            <ExtrasForm extras={extrasValues} selectExtra={onChange} loading={isFetching} />
                        </CardPanel>
                    )}
                </WithLoadingStates>
            </WithHolidaySummary>
        </>
    );
};

export default Extras;
