import React, { useState, useEffect } from 'react';
import { Accordion, Card, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../../components/PageTitle';
import ScrollToTop from '../../components/ScrollToTop';
import { urls, config } from '../../../config';
import { t } from '../../../text';

function TermsConditions() {
    const [termData, setTermData] = useState([]);
    const [active, setActive] = useState('0');

    useEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/terms/?_fields=title.rendered,content.rendered,acf.order&status=publish&per_page=99&filter[meta_key]=use_on_site&filter[meta_compare]=LIKE&filter[meta_value]=${config.siteCode}`,
            { signal: controller.signal },
        )
            .then((response) => response.json())
            .then((data) => {
                data.sort((a, b) => {
                    return a.acf.order - b.acf.order;
                });
                setTermData(data);
            })
            .catch((e) => {
                console.error('Could not fetch the content.', e);
            });

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Terms &amp; Conditions</title>
                <link rel="canonical" href={`${config['global.siteUrl']}/terms`}/>
            </Helmet>
            <ScrollToTop />
            <PageTitle title={`${config['global.brandName']} Terms & Conditions`} />
            <Container className="mb-5">
                {/* Delete these from here and text.js after mid-June or so 
                <h2>{t('termsConditions.firstHeading', config)}</h2>
                <p>{t('termsConditions.firstText')}</p>
                */}
                <h2>{t('termsConditions.secondHeading', config)}</h2>
                <p>{t('termsConditions.secondText')}</p>
                <Accordion activeKey={active}>
                    {termData.map((term, i) => {
                        return (
                            <Card key={i} className={active === '' + i ? 'active' : ''} data-order={term.acf.order}>
                                <Accordion.Toggle as={Card.Header} eventKey={'' + i} onClick={() => setActive('' + i)}>
                                    {term.title.rendered}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={'' + i}>
                                    <Card.Body
                                        dangerouslySetInnerHTML={{ __html: term.content.rendered }}
                                        className="pt-3 px-3"
                                    ></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        );
                    })}
                </Accordion>
            </Container>
        </>
    );
}

export default TermsConditions;
