import { createSelector } from 'reselect';

import { operators } from '../../data/operators/selectors';
import { parks, regionParkIds } from '../../data/parks/selectors';
import { regions } from '../../data/regions/selectors';

import { getOperatorById } from '../../data/operators/utils';
import { getParksByRegionId } from '../../data/parks/utils';
import { getRegionById } from '../../data/regions/utils';

// Selectors
export const channelId = (state) => state.pages.browseParks.channelId;
export const operatorFilters = (state) => state.pages.browseParks.operatorFilters;
export const regionId = (state) => state.pages.browseParks.regionId;

export const processedResults = createSelector(
    operators,
    parks,
    regionParkIds,
    regionId,
    channelId,
    (_operators, _parks, _regionParkIds, _regionId) => {
        return (getParksByRegionId(_parks, _regionParkIds, _regionId) || []).map((park) => ({
            park,
            operator: getOperatorById(_operators, park.opId),
        }));
    },
);

export const region = createSelector(regions, regionId, (_regions, _regionId) => {
    return getRegionById(_regions, _regionId);
});
