import React, { memo } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { getDayMonthYearFromDate, DDMMYYYYToDate } from '../../utils/date';
import t from '../../../text';

export const PriceBreakdown = ({
    className,
    extras,
    title,
    total,
    totalRemaining,
    unitPrice,
    useDeposit,
    dueDate,
    channel,
    discValue,
    bookingFee
}) => {
    const isDiscounted = discValue > 0 ? true : false;
    return (
        <Card className={`bf-filter price-breakdown overflow-hidden ${className || ''}`}>
            <Card.Header className="h4">{title}</Card.Header>
            <Card.Body>
                <p>{t('booking.breakdown.introText')}</p>
                <table className="price-breakdown">
                    <tbody>
                        <tr className="py-1">
                            <td><strong className="d-inline-block mr-1">{t('booking.breakdown.accommodationPrice')} </strong></td>
                            <td><strong className="d-inline-block">£{unitPrice.toFixed(2)}</strong></td>
                        </tr>
                        {extras.map((extra, i) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        <strong className="d-inline-block mr-1">
                                            {extra.name}
                                            {extra.value > 1 && ` (${extra.value})`}
                                        </strong>
                                    </td>
                                    <td>
                                        <strong className="d-inline-block">{extra.totalCost > 0 ? `£${extra.totalCost.toFixed(2)}` : ''}</ strong>
                                    </td>
                                </tr>
                            )
                        })}
                        {bookingFee && (
                            <tr className="py-1">
                                <td><strong className="d-inline-block mr-1">{t('booking.breakdown.bookingFee')} </strong></td>
                                <td><strong className="d-inline-block">£{bookingFee.toFixed(2)}</strong></td>
                            </tr>
                        )}
                        {isDiscounted && (
                            <tr>
                                <td>
                                    <strong className="d-block mr-1">Discount Amount<span className="float-right">-</span></strong>
                                </td>
                                <td>
                                    <strong className="d-inline-block">£{discValue.toFixed(2)}</strong>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Card.Body>
            <Card.Body className={`bg-${isDiscounted ? 'success' : 'secondary'}`}>
                <p className={`h4 font-weight-normal${isDiscounted ? ' text-white' : ''}`}>
                    {t('booking.breakdown.amountToPayToday')}
                </p>
                <p className={`h1 ${isDiscounted ? ' text-white' : ''}`}>
                    £{total.toFixed(2)}
                </p>
                {useDeposit && (
                    <p className="h6 font-weight-bold">
                        {t(
                            'booking.breakdown.outstandingAmount',
                            getDayMonthYearFromDate(DDMMYYYYToDate(dueDate)),
                            totalRemaining.toFixed(2),
                        )}
                    </p>
                )}
            </Card.Body>
        </Card>
    );
};

PriceBreakdown.defaultProps = {
    extras: [],
    title: t('booking.breakdown.currentPriceBreakdown'),
    total: 0,
    totalRemaining: 0,
    unitPrice: 0,
    useDeposit: false,
};

PriceBreakdown.propTypes = {
    className: PropTypes.string,
    deposit: PropTypes.number,
    dueDate: PropTypes.string,
    extras: PropTypes.array,
    title: PropTypes.string,
    total: PropTypes.number,
    totalRemaining: PropTypes.number,
    unitPrice: PropTypes.number,
    useDeposit: PropTypes.bool,
};

export default memo(PriceBreakdown);
