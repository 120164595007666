import React from 'react';
import { useSelector } from 'react-redux';

import RedEye from './RedEye';

import { regions } from '../../redux/data/regions/selectors';
import { details, operator, processedResults as parkResults } from '../../redux/park/selectors';
import { params } from '../../redux/search/form/selectors';
import { processedResults as searchResults } from '../../redux/search/results/selectors/results';

import { getRegionById } from '../../redux/data/regions/utils';
import { dateToDDMMYYYY, getDepartureDate } from '../../utils/date';
import { stripProtocol, to2dp } from '../../utils/string';
import { getOperatorParkNameString } from '../../utils/tracking';
import { config } from '../../../config';

export const RedEyeParkResultsTag = () => {
    const _details = useSelector(details);
    const _operator = useSelector(operator);
    const _parkResults = useSelector(parkResults);
    const _region = getRegionById(useSelector(regions), _details.regn);
    const _searchParams = useSelector(params);
    const _searchResult = useSelector(searchResults).find((result) => result.parkUrn === _details.parkId);

    const facilitiesTags = {};
    if (Array.isArray(_details?.facilities)) {
        _details.facilities
            .filter((facility, i) => i < 4)
            .forEach((facility, i) => {
                facilitiesTags[`select_attribute${String.fromCharCode(97 + i)}`] = facility;
            });
    }

    const unitInfoTags = {};
    if (Array.isArray(_parkResults?.selectedDate?.units)) {
        _parkResults.selectedDate.units
            .filter((unitInfo, i) => i < 5)
            .forEach((unitInfo, i) => {
                unitInfoTags[`select_accomodation${i + 1}`] = unitInfo.unitDesc;
                unitInfoTags[`select_sleeps${i + 1}`] = unitInfo.unitMaxOccupancy;
                unitInfoTags[`select_accom_value${i + 1}`] = to2dp(unitInfo.unitPrice);
            });
    }

    return (
        <RedEye
            params={{
                nourl: 'select-confirm',
                select_event: 'selected_event',
                select_url: stripProtocol(window.location.href)
                    .replace('/park-result/', '/park/')
                    .replace('/boat-result/', '/boat/'),
                select_destinationa: getOperatorParkNameString(_operator.name, _details.name),
                select_destinationb: _region?.name,
                select_destinationc: _details.sAdd,
                select_val: to2dp(_searchResult?.minPrice || _searchResult?.altDateMinPrice),
                select_image: stripProtocol(_details.imgs?.[0] || _details.imgs),
                ...facilitiesTags,
                ...unitInfoTags,
                select_nights: _searchParams.duration,
                select_datea: dateToDDMMYYYY(new Date(_searchParams.arrivalDate)),
                select_dateb: dateToDDMMYYYY(getDepartureDate(_searchParams.arrivalDate, +_searchParams.duration)),
                last_select: config['siteCode'] === 'BF' ? 'direct' : config['siteCode'],
            }}
        />
    );
};

export default RedEyeParkResultsTag;
