import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import { getDayNameFromDate, getDayAndMonthNameFromDate } from '../utils/date';

const getCriteria = (regionName, date, duration) => {
    const _date = new Date(date);
    let output = '';

    if (regionName) output += `${regionName}, `;
    if (date) output += `${getDayNameFromDate(_date)} ${getDayAndMonthNameFromDate(_date)} ${_date.getFullYear()}`;
    if (duration) output += ` for ${duration} nights`;

    return output;
};

function Loading(props) {
    const _criteria = getCriteria(props.regionName, props.date, props.duration);

    return (
        <Container className="text-center my-5 d-flex flex-column align-items-center justify-content-center" style={{minHeight: '50vh'}}>
            <i className="icon-logo text-secondary h1"></i>
            <p className="h2">We are now finding your perfect holiday</p>
            {_criteria && (
                <p className="h4">
                    <strong>{_criteria}</strong>
                </p>
            )}
            <div className="progress-bar my-5">
                <span className="bar" data-status={props.status}>
                    <span className="progress"></span>
                </span>
            </div>
        </Container>
    );
}

Loading.defaultProps = {
    count: 0,
    status: '...',
};

Loading.propTypes = {
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    regionName: PropTypes.string,
    status: PropTypes.string,
};

export default Loading;
