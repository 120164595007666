import React, { useEffect, useState } from 'react';
import { Alert, Modal, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import t from '../../../text';

import { requestParams } from '../../redux/search/form/selectors';

export const ToolsModal = () => {
    const location = useLocation();
    const _requestParams = useSelector(requestParams);

    const [show, setShow] = useState(false);

    const onClose = () => {
        setShow(false);
    };

    const onKeyPress = (e) => {
        if (e.keyCode === 84 && e.altKey) setShow(true); // ALT + t to show modal
    };

    useEffect(() => {
        document.addEventListener('keydown', onKeyPress);
        return () => {
            document.removeEventListener('keydown', onKeyPress);
        };
    });

    function getParkFromSearchLink(dealOfTheWeek, internal) {
        const DOTWParams = {
            ..._requestParams,
            adults: 2,
            children: 2,
            infants: 0,
        };
        const searchQuery = Object.keys(DOTWParams)
            .filter((key) => typeof DOTWParams[key] !== 'undefined')
            .filter((key) => key !== 'channel' && key !== 'region' && key !== 'partysize')
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(DOTWParams[key])}`)
            .join('&');

        let route = location.pathname.split('/');
        route[1] = dealOfTheWeek ? 'deal-of-the-week' : 'park-from-search';
        route = route.join('/');
        if (internal) return `${route}?${searchQuery}`;

        return `${window.location.origin}${route}?${searchQuery}`;
    }

    return (
        <Modal show={show} onHide={onClose} centered size="lg">
            <Modal.Header closeButton className="bg-lighter">
                <Modal.Title>
                    <strong>{t('park.tools.tools')}</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="h3">{t('park.tools.dealOfTheWeekLink')}</p>

                <Tabs defaultActiveKey="internal">
                    <Tab eventKey="internal" title="Internal Link">
                        <Alert variant="warning" className="p-3 mt-3">
                            <span role="img" aria-label="Attention">
                                ⚠️
                            </span>{' '}
                            Use this for internal links, i.e Wordpress CMS pages or DOTW on homepage.
                        </Alert>
                        <code>{getParkFromSearchLink(true, true)}</code>
                    </Tab>
                    <Tab eventKey="external" title="External Link">
                        <Alert variant="warning" className="p-3 mt-3">
                            <span role="img" aria-label="Attention">
                                ⚠️
                            </span>{' '}
                            Use this for external links, i.e emails or social.
                        </Alert>
                        <code>{getParkFromSearchLink(true)}</code>
                    </Tab>
                </Tabs>

                <hr className="my-5" />
                <p className="h3">{t('park.tools.parkFromSearchLink')}</p>
                <code>{getParkFromSearchLink()}</code>
            </Modal.Body>
        </Modal>
    );
};

export default ToolsModal;
