export const selfCancellingAction = (actionFunc) => {
    let lastRequest;

    const cancelLastRequest = () => {
        if (lastRequest) lastRequest.cancel();
    };

    const actionFuncWrapper = (...args) => {
        cancelLastRequest();
        lastRequest = actionFunc(...args);
        return lastRequest;
    };

    return [actionFuncWrapper, cancelLastRequest];
};

export default selfCancellingAction;
