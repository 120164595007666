import { GET_OPERATORS } from './action-types';

import { apiRequest, checkRequestNeeded } from '../../apiRequest';
import { urls } from '../../../../config';
import { getHasFailed, getIsLoading, getIsLoaded } from './selectors';

export const getOperators = (dispatch, channelId, force = false) => {
    return dispatch((d, getState) => {
        const state = getState();
        const isLoaded = getIsLoaded(state, channelId);
        const isLoading = getIsLoading(state, channelId);
        const hasFailed = getHasFailed(state, channelId);

        if (checkRequestNeeded(true, isLoading, isLoaded, hasFailed, force)) {
            return apiRequest(d, GET_OPERATORS, urls.operators, { channel: channelId }, channelId);
        }
    });
};
