import { SELECT_CHANNEL, SELECT_OPERATOR } from './action-types';

const initialState = {
    channelId: undefined,
    operatorId: undefined,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_CHANNEL:
            return {
                ...state,
                channelId: action.payload,
            };
        case SELECT_OPERATOR:
            return {
                ...state,
                operatorId: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
