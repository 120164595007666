import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import CardPanel from '../CardPanel';

import { getShortDateFormat } from '../../utils/date';
import t from '../../../text';

export const HolidaySummary = (props) => {
    const arrivalDate = props.arrivalDate ? new Date(props.arrivalDate) : undefined;
    const departureDate = props.arrivalDate ? new Date(props.arrivalDate) : undefined;
    const isNewBooking = props.bookingType === 'new';

    if (departureDate) {
        departureDate.setDate(departureDate.getDate() + parseInt(props.duration, 10));
    }

    return (
        <CardPanel title={props.title}>
            {props.confirmation && (
                <>
                    <p className="mb-3">{t('booking.summary.confirmationOf', isNewBooking)}</p>
                    <Button className="mb-4 d-block w-100" variant="primary" onClick={window.print}>
                        {t('booking.summary.printBookingRef')}
                    </Button>
                </>
            )}
            <p className="h4 font-weight-normal text-dark">
                {props.park.name}
            </p>
            <p className="d-flex">
                <i className="icon-map-marker text-primary mr-1"></i>
                <span>{props.park.sAdd}</span>
            </p>
            <div className="d-flex align-items-center mb-3">
                <p className="h6 m-0 font-weight-bold">
                    {props.channel !== "ukcottages" && props.unit.unitDesc}
                    {typeof props.unit.unitPrice !== 'undefined' && (
                        <>
                            <br />£{parseFloat(props.unit.unitPrice).toFixed(2)}
                        </>
                    )}
                </p>
            </div>
            {arrivalDate && (
                <p className="h4 mb-3 font-weight-bold">
                    {t('booking.summary.checkInAt', getShortDateFormat(arrivalDate))}
                </p>
            )}
            {departureDate && (
                <p className="h4 mb-3 font-weight-bold">
                    {t('booking.summary.checkOutAt', getShortDateFormat(departureDate))}
                </p>
            )}
            <p>{t('booking.summary.xNightStay', props.duration)}</p>
        </CardPanel>
    );
};

HolidaySummary.defaultProps = {
    confirmation: false,
    title: 'Your Holiday Summary',
    park: {},
    operator: {},
    unit: {},
};

HolidaySummary.propTypes = {
    confirmation: PropTypes.bool,
    title: PropTypes.string,
    park: PropTypes.object,
    operator: PropTypes.object,
    unit: PropTypes.object,
    arrivalDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(HolidaySummary);
