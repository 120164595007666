import { useDispatch, useSelector } from 'react-redux';

import { getExtras, setExtras } from '../../../../redux/booking/extras/actions';

import { selectExtra } from '../../../../redux/booking/form/actions';
import { extrasWithValues } from '../../../../redux/booking/form/selectors';
import {
    isFetching,
    isSaving,
    hasErrorFetching,
    hasErrorSaving,
    errorDataSaving,
} from '../../../../redux/booking/extras/selectors';

import useReduxFormState from '../../../../hooks/useReduxFormState';

export const useExtrasFormActions = () => {
    const dispatch = useDispatch();
    return {
        getExtras: (bookingId) => getExtras(dispatch, bookingId, true),
        setExtras: (bookingId, extras) => {
            return setExtras(dispatch, bookingId, extras);
        },
    };
};

export const useExtrasFormState = () => ({
    ...useReduxFormState(extrasWithValues, selectExtra),
    errorDataSaving: useSelector(errorDataSaving),
    hasErrorFetching: useSelector(hasErrorFetching),
    hasErrorSaving: useSelector(hasErrorSaving),
    isFetching: useSelector(isFetching),
    isSaving: useSelector(isSaving),
});

export default useExtrasFormState;
