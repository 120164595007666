import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button, Row } from 'react-bootstrap';

import TextInput from '../../../FormElements/TextInput';
import { required, email, password, integer } from '../../../../utils/validation';
import RequestErrors from '../../../RequestErrors';

import t from '../../../../../text';

export const validateFields = (values) => {
    const errors = {};
    if (!required(values.email) || !email(values.email)) {
        errors.email = t('forms.user.invalidEmail');
    }
    if (!required(values.email)) {
        errors.email = t('forms.user.blankEmail');
    }
    if (!required(values.passwd) || !password(values.passwd)) {
        errors.passwd = t('forms.user.invalidPassword');
    }
    if (!required(values.passwd)) {
        errors.passwd = t('forms.user.blankPassword');
    }
    if (!required(values.passwd2) || values.passwd !== values.passwd2) {
        errors.passwd2 = t('forms.changePassword.passwordMismatch');
    }
    if (!required(values.passwd2)) {
        errors.passwd2 = t('forms.changePassword.confirmPassword');
    }
    if (!required(values.resetCode) || !integer(values.resetCode)) {
        errors.resetCode = t('forms.changePassword.invalidResetCode');
    }
    if (!required(values.resetCode)) {
        errors.resetCode = t('forms.changePassword.blankResetCode');
    }
    return errors;
};

export const ChangePasswordForm = (props) => (
    <Form name="changePasswordForm" noValidate>
        <TextInput
            name="email"
            type="email"
            placeholder={t('forms.user.emailAddress')}
            value={props.values.email}
            onChange={(e) => props.onChange(e.target.name, e.target.value)}
            invalid={!!props.errors.email}
            validationMessage={props.errors.email}
            attrs={{ 'aria-label': t('forms.user.emailAddress'), disabled: props.busy, autoComplete: 'username' }}
        />
        <TextInput
            name="passwd"
            type="password"
            placeholder={t('forms.changePassword.newPassword')}
            value={props.values.passwd}
            onChange={(e) => props.onChange(e.target.name, e.target.value)}
            invalid={!!props.errors.passwd}
            validationMessage={props.errors.passwd}
            attrs={{
                'aria-label': t('forms.changePassword.newPassword'),
                disabled: props.busy,
                autoComplete: 'new-password',
            }}
        />
        <TextInput
            name="passwd2"
            type="password"
            placeholder={t('forms.changePassword.confirmNewPassword')}
            value={props.values.passwd2}
            onChange={(e) => props.onChange(e.target.name, e.target.value)}
            invalid={!!props.errors.passwd2}
            validationMessage={props.errors.passwd2}
            attrs={{
                'aria-label': t('forms.changePassword.confirmNewPassword'),
                disabled: props.busy,
                autoComplete: 'new-password',
            }}
        />
        <TextInput
            name="resetCode"
            type="text"
            placeholder={t('forms.changePassword.resetCode')}
            value={props.values.resetCode}
            onChange={(e) => props.onChange(e.target.name, e.target.value)}
            invalid={!!props.errors.resetCode}
            validationMessage={props.errors.resetCode}
            attrs={{
                'aria-label': t('forms.changePassword.resetCode'),
                maxLength: 6,
                disabled: props.busy,
            }}
        />

        <Row noGutters className="d-flex align-items-center">
            <Col>
                <Button type="submit" block onClick={props.onSubmit} disabled={props.busy}>
                    {props.busy ? t('global.pleaseWait') : t('buttons.submit')}
                </Button>
            </Col>
        </Row>

        {props.failure && <RequestErrors requestError={props.failure} />}
    </Form>
);

ChangePasswordForm.defaultProps = {
    busy: false,
    errors: {},
    values: {},
};

ChangePasswordForm.propTypes = {
    busy: PropTypes.bool,
    errors: PropTypes.object,
    failure: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onViewRegister: PropTypes.func,
    onViewReset: PropTypes.func,
    values: PropTypes.object,
};

export default ChangePasswordForm;
