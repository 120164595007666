import { defaultConfig, getApi, getContentRoutes, getFooterLinks, getHeaderLinks } from '../../base/defaultConfig';
import { slugify } from '../../base/utils/string';

import logoDark from './images/logo-dark.svg';
import logoLight from './images/logo-new-style.svg';

export const warnerLeisureHotelsURL = "";
export const boatingBreaksURL = "";

export const config = {
    ...defaultConfig,
    'api.url': '',
    'awin.enable': false,
    'cms.url': '/wp-json/wp/v2',
    'global.brandName': 'Breakfree Holidays',
    'global.email': 'enquiries@breakfreeholidays.co.uk',
    'global.siteUrl': 'https://www.breakfreeholidays.co.uk',
    'google.gtm': 'GTM-5DWRGDS',
    'searchBox.allowFiveDays': true,
    'socialMedia.facebook': 'https://www.facebook.com/breakfreeholidays/',
    'socialMedia.instagram': 'https://www.instagram.com/breakfreeholidaysofficial/',
    'socialMedia.telephone': 'tel:03448484534',
    'socialMedia.twitter': 'https://twitter.com/BreakFreeHols',
    'socialMedia.youtube': 'https://www.youtube.com/user/BreakFreeHolidays',
    'user.optin.smsOpt': false,
    'user.optin.thirdOpt': false,
    'header.showOffersLink': false,
    'header.showPromoLink': true,
    'header.showFAQLink': true,
    'header.showFerriesLink': true,
    'header.showMoreLink': true,
    'payment.enable3DSecure': true,
    //'payment.payit': true,
    siteCode: 'BS',
};

export const contentRoutes = [
    ...getContentRoutes(config),
    { path: '/privacy-policy', url: 'age/3' },
    { path: '/customer-charter', url: 'age/84' },
    { path: '/offers', url: 'age/1939' },
    { path: `/${slugify(config['global.brandName'])}-offers`, url: 'age/221' },
    { path: '/about-us', url: 'age/254' },
    { path: '/cottages', url: 'age/662' },
    { path: '/european-holiday-parks', url: 'age/1572' },
    { path: '/holiday-home-sales', url: 'age/1386' },
    { path: '/hotels', url: 'age/1942' },
    { path: '/cheap-caravan-holidays-uk-europe', url: 'age/225' },
    { path: '/frequently-asked-questions', url: 'age/3932'},
    { path: '/how-it-works', url: 'age/3935'},
];

export const footerLinks = {
    ...getFooterLinks(config),
};

export const images = {
    headerLogoDark: logoDark,
    headerLogoLight: logoLight,
    logoDark: logoDark,
    logoLight: logoLight,
};

export const urls = {
    ...getApi(config, 'api'),
};

export const HeaderLinks = getHeaderLinks([1, 2, 3, 4, 5, 6, 7]);
