import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { loggedIn } from '../../redux/user/selectors';

export const WithLogin = (props) => {
    const history = useHistory();
    const location = useLocation();
    const _loggedIn = useSelector(loggedIn);

    if (!_loggedIn && location.pathname !== '/login') {
        const redirectPath = props.redirect || location.pathname;
        history.replace(`/login?redirect=${redirectPath}`);
        return null;
    }

    return props.children;
};

WithLogin.propTypes = {
    redirect: PropTypes.string,
};

export default WithLogin;
