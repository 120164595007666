import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import FilterPanel from '../FilterPanel';

import { setState } from '../../redux/appState/actions';

export const AppStateFilterPanel = (props) => {
    const dispatch = useDispatch();

    const onToggleFilter = (key, arrPos) => {
        //if(window.innerWidth >= 992) {
        //    props.scrollTo.current.scrollIntoView({
        //        behavior: "smooth",
        //        block: "start",
        //        inline: "nearest"
        //    });
        //}

        if (typeof arrPos === 'number') {
            const nFiltLength = props.nFiltLength;
            key = parseInt(key);

            const nFilt = props.selectedFilters;

            const arrayPosIsKey = nFilt[arrPos] === key;
            nFilt[arrPos] = arrayPosIsKey ? -1 : key;

            // Ensure array is correct length and values are integer or -1
            for (let i = 0; i < nFiltLength; i++) {
                nFilt[i] = nFilt[i] || -1;
            }

            const newValue = [...nFilt];

            setState(dispatch, props.stateKey, newValue);
        }

        if (typeof arrPos === 'undefined') {
            if (props.selectedFilters.includes(key)) {
                setState(
                    dispatch,
                    props.stateKey,
                    props.selectedFilters.filter((val) => val !== key),
                );
            } else {
                setState(dispatch, props.stateKey, [...props.selectedFilters, key]);
            }
        }
    };

    const onClearFilters = () => {
        setState(dispatch, props.stateKey, []);
    };

    // If there are params given in the URL, set them
    useEffect(() => {
        if (props.urlKey) {
            const urlFilterValues = new URLSearchParams(document.location.search).get(props.urlKey);
            if (urlFilterValues) {
                setState(dispatch, props.stateKey, urlFilterValues.toLowerCase().split(','));
            }
        }
    }, [dispatch, props.urlKey, props.stateKey]);

    return <FilterPanel {...props} onToggleFilter={onToggleFilter} onClearFilters={onClearFilters} />;
};

AppStateFilterPanel.propTypes = {
    ...FilterPanel.propTypes,
    selectedFilters: PropTypes.array,
    stateKey: PropTypes.string,
};

AppStateFilterPanel.defaultProps = {
    selectedFilters: [],
};

export default AppStateFilterPanel;
