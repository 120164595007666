import React from 'react';
import { useSelector } from 'react-redux';

import {
    arrivalDate,
    confirmationId,
    duration,
    operator,
    park,
    region,
    unit,
} from '../../redux/booking/booking/selectors';
import { selectedExtrasWithValues, totalCostFull } from '../../redux/booking/form/selectors';
import { adults, children, under5s } from '../../redux/search/form/selectors';

import { dateToDDMMYYYY, getDepartureDate } from '../../utils/date';
import { stripProtocol, to2dp } from '../../utils/string';
import { config } from '../../../config';

import RedEye from './RedEye';

export const RedEyeBookingConfirmationTag = () => {
    const _adults = useSelector(adults);
    const _arrivalDate = useSelector(arrivalDate);
    const _children = useSelector(children);
    const _confirmationId = useSelector(confirmationId);
    const _duration = useSelector(duration);
    const _operator = useSelector(operator);
    const _park = useSelector(park);
    const _region = useSelector(region);
    const _selectedExtras = useSelector(selectedExtrasWithValues);
    const _totalCostFull = useSelector(totalCostFull);
    const _under5s = useSelector(under5s);
    const _unit = useSelector(unit);

    const _insuranceExtra = _selectedExtras.find((extra) => {
        return extra.name.toLowerCase().search('insurance') !== -1; // There isn't a better way AFAIK :(
    });

    return (
        <RedEye
            params={{
                nourl: 'sale-confirm',
                sale_conf: 'yes',
                sale_destinationa: `${_operator.name} - ${_park.name}`,
                sale_destinationb: _region.name,
                sale_destinationc: _park.sAdd,
                sale_accomodation: _unit.unitDesc,
                items: _duration,
                total_val: to2dp(_totalCostFull),
                order_ref: _confirmationId,
                sale_image: stripProtocol(Array.isArray(_park.imgs) ? _park.imgs[0] : _park.imgs),
                sale_datea: dateToDDMMYYYY(new Date(_arrivalDate)),
                sale_dateb: dateToDDMMYYYY(getDepartureDate(new Date(_arrivalDate), _duration)),
                sale_nights: _duration,
                sale_adults: _adults,
                sale_children: _children,
                sale_under5s: _under5s,
                sale_pax: _adults + _children + _under5s,
                sale_extras: _insuranceExtra ? 'insurance' : undefined,
                sale_extras_val: _insuranceExtra ? to2dp(_insuranceExtra.cost) : undefined,
                last_sale: config['siteCode'] === 'BF' ? 'direct' : config['siteCode'],
            }}
        />
    );
};

export default RedEyeBookingConfirmationTag;
