import React from 'react';
import { BOOKING_STEPS } from '../../Constants';
import { useRouteMatch } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

export const ProgressBar = () => {
    const matches = useRouteMatch();

    const steps = [
        {
            index: 0,
            name: BOOKING_STEPS.EXTRAS,
        },
        {
            index: 1,
            name: BOOKING_STEPS.DETAILS,
        },
        {
            index: 2,
            name: BOOKING_STEPS.PAYMENT,
        },
        {
            index: 3,
            name: BOOKING_STEPS.PAYMENT_ACTION,
        },
        {
            index: 4,
            name: BOOKING_STEPS.CONFIRMATION,
        }
    ];


    const currentStep = steps.filter((step) => step.name === matches.params.step)[0];

    const getStepTitle = (step) => {
        switch (step.name) {
            case BOOKING_STEPS.EXTRAS:
                return "Step 1 of 3 - Party Details and Extras";
            case BOOKING_STEPS.DETAILS:
                return "Step 2 of 3 - Your Booking Details";
            case BOOKING_STEPS.PAYMENT:
            case BOOKING_STEPS.PAYMENT_ACTION:
                return "Step 3 of 3 - Payment Details";
            case BOOKING_STEPS.CONFIRMATION:
                return "Your Holiday Confirmation";
            default:
                return "";
        }
    }

    return (
        <div className="progressBar p-3 pl-4">
            <Container>
                {currentStep ? (
                    <Row className='align-items-center steps-container'>
                        <Col>
                            <h2 className="mb-0 step-title">{getStepTitle(currentStep)}</h2>
                        </Col>
                        {currentStep.name !== BOOKING_STEPS.CONFIRMATION && (
                            <Col md="auto">
                                <div className="steps">
                                    {steps.slice(0, 3).map((_, index) => {
                                        let stepStatus;
                                        if (index < currentStep.index) {
                                            stepStatus = "completed"
                                        } else if (index === currentStep.index) {
                                            stepStatus = "active"
                                        } else {
                                            stepStatus = ""
                                        }
                                        return (
                                            <div key={index}>
                                                <i className={`icon-check step ${stepStatus}`}></i>
                                                {index !== 2 && <div className='divider'></div>}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                        )}
                    </Row>
                ) : null}
            </Container>
        </div >
    )
};


export default ProgressBar;
