import { useSelector } from 'react-redux';
import { selectPaymentOption } from '../../../../redux/booking/form/actions';
import { deposit, depositAvailable, depositText, dueDate } from '../../../../redux/booking/booking/selectors';
import {
    totalCostDeposit,
    totalCostFull,
    totalCostRemaining,
    paymentOption,
} from '../../../../redux/booking/form/selectors';

import useReduxFormState from '../../../../hooks/useReduxFormState';

export const usePaymentOptionsFormActions = () => {};

export const usePaymentOptionsFormState = () => ({
    ...useReduxFormState(paymentOption, selectPaymentOption),
    deposit: useSelector(deposit),
    depositAvailable: useSelector(depositAvailable),
    depositText: useSelector(depositText),
    dueDate: useSelector(dueDate),
    totalCostDeposit: useSelector(totalCostDeposit),
    totalCostFull: useSelector(totalCostFull),
    totalCostRemaining: useSelector(totalCostRemaining),
});

export default usePaymentOptionsFormState;
