import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../../redux/user/actions';
import { loggedIn, loggingIn, loginError, loginFailed } from '../../../../redux/user/selectors';
import { validateFields } from './LoginForm';
import useLocalFormState from '../../../../hooks/useLocalFormState';

export const useLoginFormActions = () => {
    const dispatch = useDispatch();
    return {
        login: (email, passwd) => {
            return login(dispatch, email, passwd);
        },
    };
};

export const useLoginFormState = () => ({
    ...useLocalFormState(validateFields),
    loggedIn: useSelector(loggedIn),
    loggingIn: useSelector(loggingIn),
    loginError: useSelector(loginError),
    loginFailed: useSelector(loginFailed),
});

export default useLoginFormState;
