import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

export const RequestErrors = (props) => (
    <Row noGutters>
        <Col>
            {props.requestError && !props.requestError.errors && (
                <p className={props.classes}>Network error. Please check your internet connection and try again.</p>
            )}
            {props.requestError && Array.isArray(props.requestError.errors) && (
                <ul className={props.classes}>
                    {props.requestError.errors.map((error) => (
                        <li key={error.code}>
                            {error.message}
                            {props.showCodes && ` (code ${error.code})`}
                        </li>
                    ))}
                </ul>
            )}
        </Col>
    </Row>
);

RequestErrors.propTypes = {
    redText: PropTypes.bool,
    requestError: PropTypes.object, // Get this by using getError selector in redux/requests/selectors.js
    classes: PropTypes.string,
    showCodes: PropTypes.bool,
};

RequestErrors.defaultProps = {
    classes: 'text-danger mt-2 mb-0 list-unstyled',
    redText: true,
};

export default RequestErrors;
