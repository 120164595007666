import { useDispatch, useSelector } from 'react-redux';

import {
    selectGuestsAdults,
    selectGuestsChildren,
    selectGuestsUnder5s,
    startSearch,
} from '../../../../redux/search/form/actions';
import { cancelGetResults } from '../../../../redux/search/results/actions';
import { adults, children, params, requestParams, under5s } from '../../../../redux/search/form/selectors';

export const usePartyMembersFormActions = () => {
    const dispatch = useDispatch();

    return {
        cancelGetResults: () => cancelGetResults(dispatch),
        startSearch: (params, requestParams) => startSearch(dispatch, params, requestParams),
        selectGuestsAdults: (num) => selectGuestsAdults(dispatch, num),
        selectGuestsChildren: (num) => selectGuestsChildren(dispatch, num),
        selectGuestsUnder5s: (num) => selectGuestsUnder5s(dispatch, num),
    };
};

export const usePartyMembersFormState = () => {
    return {
        adults: useSelector(adults),
        children: useSelector(children),
        params: useSelector(params),
        requestParams: useSelector(requestParams),
        under5s: useSelector(under5s),
    };
};

export default usePartyMembersFormState;
