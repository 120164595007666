import { SELECT_CHANNEL, SELECT_REGION } from './action-types';

const initialState = {
    channelId: undefined,
    regionId: undefined,
    operatorFilters: [],
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_CHANNEL:
            return {
                ...state,
                channelId: action.payload,
            };
        case SELECT_REGION:
            return {
                ...state,
                regionId: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
