import { useSelector } from 'react-redux';

import { getResults } from '../../redux/search/results/actions';
import {
    hasError,
    isFetching,
    isSearching,
    isSearchRequestPending,
    isWaiting,
} from '../../redux/search/results/selectors/data';
import useDataLoader from '../../hooks/useDataLoader';

export function WithSearchResults(props) {
    const _hasError = useSelector(hasError);
    const _isFetching = useSelector(isFetching);
    const _isSearching = useSelector(isSearching);
    const _isSearchRequestPending = useSelector(isSearchRequestPending);
    const _isWaiting = useSelector(isWaiting);

    const getData = (dispatch, force) => getResults(dispatch, force);
    const _hasFailed = _hasError;
    const _isLoaded = !_isSearchRequestPending && !_isSearching;
    const _isLoading = _isSearchRequestPending || _isFetching;
    const _isReady = !_isSearchRequestPending && _isSearching;
    const extraProps = () => ({
        isSearching: _isSearching,
        isSearchRequestPending: _isSearchRequestPending,
        isWaiting: _isWaiting,
    });

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady, extraProps);
}

export default WithSearchResults;
