import React from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import RequestErrors from '../../../RequestErrors';
import SelectInput from '../../../FormElements/SelectInput';
import TextInput from '../../../FormElements/TextInput';

import countries from '../../../../Data/Countries.json';
import months from '../../../../Data/Months.json';
import titles from '../../../../Data/Titles.json';

import { calculateAge, DDMMYYYYToDate } from '../../../../utils/date';
import { required, email, telephone, postcode } from '../../../../utils/validation';
import t from '../../../../../text';

const generateDayOptions = () => {
    const values = [...new Array(31)].map((num, i) => (i + 1 < 10 ? '0' : '') + (i + 1).toString());
    return values.map((value) => (
        <option key={value} value={value}>
            {value}
        </option>
    ));
};

const generateYearOptions = () => {
    const end = new Date().getFullYear();
    return [...new Array(120)].map((item, i) => (
        <option key={end - i} value={end - i}>
            {end - i}
        </option>
    ));
};

export const validateFields = (values = {}) => {
    const errors = {};
    if (!required(values.title)) {
        errors.title = t('forms.forms.noValueSelected');
    }
    if (!required(values.firstName)) {
        errors.firstName = t('forms.user.blankFirstName');
    }
    if (!required(values.lastName)) {
        errors.lastName = t('forms.user.blankSurname');
    }
    if (!required(values.email) || !email(values.email)) {
        errors.email = t('forms.user.invalidEmail');
    }
    if (!required(values.email)) {
        errors.email = t('forms.user.blankEmail');
    }
    if (!required(values.tel) || !telephone(values.tel)) {
        errors.tel = t('forms.user.invalidTelephone');
    }
    if (!required(values.dobDay)) {
        errors.dobDay = t('forms.forms.noValueSelected');
    }
    if (!required(values.dobMnth)) {
        errors.dobMnth = t('forms.forms.noValueSelected');
    }
    if (!required(values.dobYr)) {
        errors.dobYr = t('forms.forms.noValueSelected');
    }
    if (!required(values.addrLn1)) {
        errors.addrLn1 = t('forms.address.blankAddress');
    }
    if (!required(values.town)) {
        errors.town = t('forms.address.blankTown');
    }
    if (!required(values.postCode) || !postcode(values.postCode)) {
        errors.postCode = t('forms.address.invalidPostcode');
    }
    if (!required(values.county)) {
        errors.county = t('forms.address.blankCounty');
    }
    if (!required(values.country)) {
        errors.country = t('forms.forms.noValueSelected');
    }

    if (!errors.dobDay && !errors.dobMnth && !errors.dobYr) {
        const age = calculateAge(DDMMYYYYToDate(`${values.dobDay}/${values.dobMnth}/${values.dobYr}`));
        if (age < 18) {
            errors.dobDay = t('forms.user.mustBeOver18');
            errors.dobMnth = ' ';
            errors.dobYr = ' ';
        }
    }

    return errors;
};

export const isValid = (values = {}) => !Object.keys(validateFields(values)).length;

export const UpdateUserForm = (props) => (
    <Form name="detailsForm" noValidate>
        <Form.Row>
            <SelectInput
                groupClassName="col-12 col-sm-4 col-lg-2"
                label={t('forms.user.title')}
                name="title"
                value={props.values.title || ''}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.title}
                validationMessage={props.errors.title}
                attrs={{ disabled: props.busy }}
            >
                <option value="" disabled></option>
                {Object.keys(titles).map((key) => (
                    <option key={key} value={key}>
                        {titles[key]}
                    </option>
                ))}
            </SelectInput>

            <TextInput
                groupClassName="col-12 col-sm-4 col-lg-5"
                label={t('forms.user.firstName')}
                name="firstName"
                value={props.values.firstName}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.firstName}
                validationMessage={props.errors.firstName}
                attrs={{ disabled: props.busy }}
            />

            <TextInput
                groupClassName="col-12 col-sm-4 col-lg-5"
                label={t('forms.user.surname')}
                name="lastName"
                value={props.values.lastName}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.lastName}
                validationMessage={props.errors.lastName}
                attrs={{ disabled: props.busy }}
            />
        </Form.Row>

        <Form.Row>
            <TextInput
                groupClassName="col"
                label={t('forms.user.emailAddress')}
                name="email"
                type="email"
                value={props.values.email}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.email}
                validationMessage={props.errors.email}
                attrs={{ disabled: props.busy }}
            />
        </Form.Row>

        <Form.Row>
            <TextInput
                groupClassName="col"
                label={t('forms.user.telephone')}
                name="tel"
                type="tel"
                value={props.values.tel}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.tel}
                validationMessage={props.errors.tel}
                attrs={{ disabled: props.busy }}
            />
        </Form.Row>

        <Form.Row>
            <SelectInput
                groupClassName="col-12 col-sm-4"
                label={t('forms.user.dob')}
                name="dobDay"
                value={props.values.dobDay || ''}
                attrs={{ 'aria-label': t('forms.user.dobDay'), disabled: props.busy }}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.dobDay}
                validationMessage={props.errors.dobDay}
            >
                <option value="" disabled>
                    {t('forms.user.day')}
                </option>
                {generateDayOptions()}
            </SelectInput>

            <SelectInput
                groupClassName="col-12 col-sm-4"
                label={t('forms.user.dobMonth')}
                labelClassName="d-none d-sm-block"
                hideLabel
                name="dobMnth"
                value={props.values.dobMnth || ''}
                attrs={{ 'aria-label': t('forms.user.dobMonth'), disabled: props.busy }}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.dobMnth}
                validationMessage={props.errors.dobMnth}
            >
                <option value="" disabled>
                    {t('forms.user.month')}
                </option>
                {months.map((month) => (
                    <option key={month.value} value={month.value}>
                        {month.name}
                    </option>
                ))}
            </SelectInput>

            <SelectInput
                groupClassName="col-12 col-sm-4"
                label={t('forms.user.dobYear')}
                labelClassName="d-none d-sm-block"
                hideLabel
                useAriaLabel
                name="dobYr"
                value={props.values.dobYr || ''}
                attrs={{ 'aria-label': t('forms.user.dobYear'), disabled: props.busy }}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.dobYr}
                validationMessage={props.errors.dobYr}
            >
                <option value="" disabled>
                    {t('forms.user.year')}
                </option>
                {generateYearOptions()}
            </SelectInput>
        </Form.Row>

        <Form.Row>
            <TextInput
                groupClassName="col"
                label={t('forms.address.address')}
                name="addrLn1"
                value={props.values.addrLn1}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.addrLn1}
                validationMessage={props.errors.addrLn1}
                attrs={{ disabled: props.busy }}
            />
        </Form.Row>

        <Form.Row>
            <TextInput
                groupClassName="col"
                label={t('forms.address.town')}
                name="town"
                value={props.values.town}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.town}
                validationMessage={props.errors.town}
                attrs={{ disabled: props.busy }}
            />
            <TextInput
                groupClassName="col"
                label={t('forms.address.county')}
                name="county"
                value={props.values.county}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.county}
                validationMessage={props.errors.county}
                attrs={{ disabled: props.busy }}
            />
        </Form.Row>

        <Form.Row>
            <TextInput
                groupClassName="col"
                label={t('forms.address.postcode')}
                name="postCode"
                value={props.values.postCode}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                attrs={{ maxLength: 8, disabled: props.busy }}
                invalid={!!props.errors.postCode}
                validationMessage={props.errors.postCode}
            />

            <SelectInput
                groupClassName="col"
                label={t('forms.address.country')}
                name="country"
                value={props.values.country || ''}
                onChange={(e) => props.onChange(e.target.name, e.target.value)}
                invalid={!!props.errors.country}
                validationMessage={props.errors.country}
                attrs={{ disabled: props.busy }}
            >
                <option value="" disabled>
                    {t('forms.address.selectCountry')}
                </option>
                {Object.keys(countries).map((key) => (
                    <option key={key} value={key}>
                        {countries[key].label}
                    </option>
                ))}
            </SelectInput>
        </Form.Row>

        {(props.onBack || props.onSubmit) && (
            <Form.Row noGutters>
                {props.onBack && (
                    <Col xs={'auto'}>
                        <Button variant="outline-primary" onClick={props.onBack} disabled={props.busy}>
                            {t('buttons.back')}
                        </Button>
                    </Col>
                )}
                {props.onSubmit && (
                    <Col>
                        <Button block onClick={props.onSubmit} disabled={props.busy}>
                            {props.busy ? t('global.pleaseWait') : t('buttons.update')}
                        </Button>
                    </Col>
                )}
            </Form.Row>
        )}

        {props.failure && <RequestErrors requestError={props.failure} />}
    </Form>
);

UpdateUserForm.defaultProps = {
    busy: false,
    errors: {},
    values: {},
};

UpdateUserForm.propTypes = {
    busy: PropTypes.bool,
    errors: PropTypes.object,
    failure: PropTypes.object,
    onBack: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    values: PropTypes.object,
};

export default UpdateUserForm;
