import { GET_INIT_DATA } from '../../init/action-types';
import { GET_OPERATOR_REGIONS, GET_REGIONS } from './action-types';
import { SUCCESS } from '../../constants';

const initialState = {
    regions: {},
    channelRegionIds: {},
    operatorChannelRegionIds: {},
};

const getRegionsFromChannel = (regions) => {
    const output = {};
    Object.keys(regions).forEach((regionId) => {
        output[regionId] = {
            id: regionId,
            ...regions[regionId],
        };
    });
    return output;
};

const getRegions = (initRegionData) => {
    let output = {};
    Object.keys(initRegionData).forEach((channelId) => {
        output = {
            ...output,
            ...getRegionsFromChannel(initRegionData[channelId]),
        };
    });
    return output;
};

const getChannelRegionIds = (regions) => {
    const output = {};
    Object.keys(regions).forEach((channelId) => {
        output[channelId] = Object.keys(regions[channelId]);
    });
    return output;
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INIT_DATA + SUCCESS:
        case GET_REGIONS + SUCCESS:
            if (action.payload.response.data.data.regions) {
                return {
                    ...state,
                    regions: {
                        ...state.regions,
                        ...getRegions(action.payload.response.data.data.regions),
                    },
                    channelRegionIds: {
                        ...state.channelRegionIds,
                        ...getChannelRegionIds(action.payload.response.data.data.regions),
                    },
                };
            }
            return state;
        case GET_OPERATOR_REGIONS + SUCCESS:
            if (action.payload.response.data.data.regions) {
                return {
                    ...state,
                    regions: {
                        ...state.regions,
                        ...getRegions(action.payload.response.data.data.regions),
                    },
                    operatorChannelRegionIds: {
                        ...state.operatorChannelRegionIds,
                        [action.payload.params.opId]: {
                            ...(state.operatorChannelRegionIds[action.payload.params.opId] || {}),
                            ...getChannelRegionIds(action.payload.response.data.data.regions),
                        },
                    },
                };
            }
            return state;
        default:
            return state;
    }
};

export default reducer;
