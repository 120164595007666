import { GET_EXTRA_DATA } from './action-types';
import { getRequestStatus } from '../../requests/selectors';

export const extraData = (state) => state.data.unitTypes.extraData;
export const parkExtraDataIds = (state) => state.data.unitTypes.parkExtraDataIds;
export const unitTypes = (state) => state.data.unitTypes.unitTypes;

// Utils to be used by other selectors
export const [getExtraDataIsLoading, getExtraDataHasFailed, getExtraDataError] = getRequestStatus(GET_EXTRA_DATA);
export const getExtraDataIsLoaded = (state, parkId) => !!parkExtraDataIds(state)[parkId];
