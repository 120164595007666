import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parkId, processedResults } from '../../redux/park/selectors';
import { duration } from '../../redux/search/form/selectors';
import { createBooking } from '../../redux/booking/booking/actions';
import DealOfTheWeekBanner from '../../components/ParkPage/DealOfTheWeekBanner/DealOfTheWeekBanner';
import ParkInformation from './ParkInformation';
import SearchBox from '../../components/SearchBox/SearchBox';
import ToolsModal from '../../components/ParkPage/ToolsModal';
import WithLoadingStates from '../../components/HOC/WithLoadingStates';
import WithOperators from '../../components/HOC/WithOperators';
import WithParkDetails from '../../components/HOC/WithParkDetails';
import WithParkSelected from '../../components/ParkPage/WithParkSelected';
//import WithPartyDetailsViaModal from '../../components/ParkPage/WithPartyDetailsViaModal';
//import WithLoginViaModal from '../../components/HOC/WithLoginViaModal';
import WithRegions from '../../components/HOC/WithRegions';
import t from '../../../text';
import ParkTitleAndGallery from './ParkTitleAndGallery';

function ParkPage(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { channelId, regionId } = useParams();
    const _parkId = useSelector(parkId);
    const _processedResults = useSelector(processedResults);
    const isBoat = channelId === 'boats';
    const searchResult = /(park-result|boat-result|deal-of-the-week|park-from-search)/.test(location.pathname);
    const handleCreateBooking = (data) => {
        createBooking(
            dispatch,
            channelId,
            _parkId,
            data.unit.unitId,
            data.unit.unitType,
            data.unit.unitPrice,
            _processedResults.selectedDate?.date,
            parseInt(_processedResults.selectedDate?.nights),
            data.party,
        );
        history.push('/booking', { prevPath: window.location.pathname });
    };

    const parkInfoSection = useRef(null);
    const scrollToParkInfo = () => {
        window.scrollTo({top: parkInfoSection.current.getBoundingClientRect().top + window.pageYOffset - 88, behavior: "smooth"});
    };

    return (
        <>
            <Container fluid className="py-4 bg-dark">
                <SearchBox />
            </Container>

            <WithParkSelected>
                <WithParkDetails parkId={_parkId} regionId={regionId}>
                    <WithRegions channelId={channelId}>
                        <WithOperators channelId={channelId}>
                            <WithLoadingStates
                                loadingErrorMessage={t('park.errorLoading', isBoat)}
                                spinnerMessage={t('park.loading', isBoat)}
                                scrollToTop
                                useContainer
                            >
                                {props.dealOfTheWeek && <DealOfTheWeekBanner />}
                                <ParkTitleAndGallery
                                    searchResult={searchResult}
                                    scrollToParkInfo={scrollToParkInfo}
                                />
                                {/* OLD METHOD
                                <WithLoginViaModal onNext={handleCreateBooking}>
                                    <WithPartyDetailsViaModal>
                                        <ParkInformation
                                            searchResult={searchResult}
                                            ref={parkInfoSection}
                                        />
                                    </WithPartyDetailsViaModal>
                                </WithLoginViaModal>
                                */}
                                <ParkInformation
                                    onNext={handleCreateBooking}
                                    searchResult={searchResult}
                                    ref={parkInfoSection}
                                />
                            </WithLoadingStates>
                        </WithOperators>
                    </WithRegions>
                </WithParkDetails>
            </WithParkSelected>

            <ToolsModal />
        </>
    );
}

ParkPage.propTypes = {
    dealOfTheWeek: PropTypes.bool,
};

export default ParkPage;
