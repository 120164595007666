import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { bookingId, bookingErrorData } from '../../redux/booking/booking/selectors';

import RequestErrors from '../RequestErrors';
import Spinner from '../Spinner';
import t from '../../../text';

const wrap = (content) => (
    <Container className="my-5">
        <Row>
            <Col xs={12}>{content}</Col>
        </Row>
    </Container>
);

export const WithBookingLoadingStates = ({
    children,
    hasBookingError,
    hasErrorFetching,
    isBooking,
    isFetching,
    onRetry,
    prevPath,
}) => {
    const _bookingId = useSelector(bookingId);
    const _errorData = useSelector(bookingErrorData);

    if (!_bookingId && !isBooking && !hasBookingError) {
        return wrap(<p>{t('booking.booking.noBookingInProgress')}</p>);
    }

    if (hasBookingError) {
        return wrap(
            <>
                <h2 className="mb-3">{t('booking.booking.failed')}</h2>
                <p>{t('booking.booking.unableToBookHoliday', prevPath)}</p>
                <RequestErrors requestError={_errorData} classes="mb-3" showCodes />
            </>,
        );
    }

    if (hasErrorFetching) {
        return wrap(
            <>
                <p>
                    <i className="icon-close text-danger mr-2"></i>
                    {t('booking.booking.errorLoadingData')}
                </p>
                <button className="btn btn-primary" onClick={onRetry}>
                    {t('buttons.retry')}
                </button>
            </>,
        );
    }

    if (isFetching) {
        return wrap(<Spinner message={t('booking.booking.loading')} />);
    }

    return children;
};

WithBookingLoadingStates.propTypes = {
    isFetching: PropTypes.bool,
    hasBookingError: PropTypes.bool,
    hasErrorFetching: PropTypes.bool,
    onRetry: PropTypes.func,
    prevPath: PropTypes.string,
};

WithBookingLoadingStates.defaultProps = {
    prevPath: '/',
};

export default WithBookingLoadingStates;
