import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectDate } from '../../redux/park/actions';
import { parkId, processedResults } from '../../redux/park/selectors';

import AlternativeDates from '../../components/ParkPage/AlternativeDates/AlternativeDates';
import WithParkResults from '../../components/HOC/WithParkResults';

function AlternativeDatesSection(props) {
    const dispatch = useDispatch();
    const _parkId = useSelector(parkId);
    const _processedResults = useSelector(processedResults);

    const showAlternativeDates = !!_processedResults?.alternativeDates;
    const selectedResult = _processedResults?.selectedDate;

    const changeSelectedResultHandler = (result) => {
        selectDate(dispatch, result.date);
        props.clicked('accommodation');
    };

    return (
        <WithParkResults parkId={_parkId}>
            {showAlternativeDates && (
                <AlternativeDates
                    parkId={_parkId}
                    results={_processedResults.all}
                    selectedResult={selectedResult}
                    onSelect={(result) => changeSelectedResultHandler(result)}
                    channel={props.channel}
                />
            )}
        </WithParkResults>
    );
}

export default AlternativeDatesSection;
