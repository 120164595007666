import { useDispatch, useSelector } from 'react-redux';

import { prePayment } from '../../../../redux/booking/payment/actions';

import { bookingId } from '../../../../redux/booking/booking/selectors';
import { paymentAmount } from '../../../../redux/booking/form/selectors';
import {
    paymntRecId,
    paypal,
    prePaymentErrorData,
    prePaymentHasError,
    prePaymentIsFetching,
    prePaymentNeedsLoading,
} from '../../../../redux/booking/payment/selectors';

import { validateFields } from './PaypalCardPaymentForm';
import useLocalFormState from '../../../../hooks/useLocalFormState';

export const usePaypalCardPaymentFormActions = () => {
    const dispatch = useDispatch();

    return {
        prePayment: (bookId, amount) => prePayment(dispatch, bookId, amount),
    };
};

export const usePaypalCardPaymentFormState = (paypalForm) => {
    const validator = (values) => validateFields(values, paypalForm?.getState());

    return {
        ...useLocalFormState(validator),
        amount: useSelector(paymentAmount),
        bookingId: useSelector(bookingId),
        paypal: useSelector(paypal),
        paymntRecId: useSelector(paymntRecId),
        prePaymentErrorData: useSelector(prePaymentErrorData),
        prePaymentHasError: useSelector(prePaymentHasError),
        prePaymentIsFetching: useSelector(prePaymentIsFetching),
        prePaymentNeedsLoading: useSelector(prePaymentNeedsLoading),
    };
};

export default usePaypalCardPaymentFormState;
