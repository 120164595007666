import React from 'react';
import PropTypes from 'prop-types';

import { isSingleResult } from '../../utils/utils';
import t from '../../../text';

export const ParkResultCountPrice = ({
    dateMatchCount,
    altDateCount,
    minPrice,
    altDateMinPrice,
    specialTextClass,
    priceOnly,
    countOnly,
}) => (
    <>
        {!priceOnly && (
            <>
                <p className="m-0 text-black">
                    <strong className={`${specialTextClass}`}>{dateMatchCount || altDateCount}</strong>
                    &nbsp;
                    {dateMatchCount ? t('searchResults.accomodationLeft', isSingleResult(dateMatchCount, altDateCount)) : t('searchResults.holidaysFoundOn', dateMatchCount, isSingleResult(dateMatchCount, altDateCount))}
                </p>
                {!countOnly && (
                    <p className="m-0">
                        {t('searchResults.from')}
                        <span className={`h2 ${specialTextClass} font-weight-bold`}>
                            &nbsp;&pound;{dateMatchCount ? minPrice : altDateMinPrice}
                        </span>
                    </p>
                )}
            </>
        )}
        {priceOnly && (
            <>
                <p className="m-0 mt-n2">
                    <small>{t('searchResults.from')}</small>
                </p>
                <p className={`m-0 mt-n2 h2 ${specialTextClass} font-weight-bold`}>
                    &pound;{dateMatchCount ? minPrice : altDateMinPrice}
                </p>
            </>
        )}
    </>
);

ParkResultCountPrice.propTypes = {
    dateMatchCount: PropTypes.number,
    altDateCount: PropTypes.number,
    minPrice: PropTypes.string,
    altDateMinPrice: PropTypes.string,
    specialTextClass: PropTypes.string,
};

ParkResultCountPrice.defaultProps = {
    specialTextClass: 'text-primary',
};

export default ParkResultCountPrice;
