import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccommodationListing from './AccommodationListing';

import { getDayNameFromDate, getDayAndMonthNameFromDate } from '../../../utils/date';
import t from '../../../../text';
import { Col, Container, Form, FormControl, Row } from 'react-bootstrap';

export const Accommodation = (props) => {
    const sortOptions = [
        {
            label: 'Price Low to High',
            value: 'asc',
        },
        {
            label: 'Price High to Low',
            value: 'desc',
        },
    ];
    const shortDate = true;
    const [sortOption, setSortOption] = useState(sortOptions[0]);

    const onChange = (val) => {
        const correctOption = sortOptions.find((option) => option.value === val);
        setSortOption(correctOption);
    };

    const sortResults = (accomodations) => {
        const isItAscending = sortOption.value === 'asc';
        return accomodations.sort((a, b) => (isItAscending ? a.unitPrice - b.unitPrice : b.unitPrice - a.unitPrice));
    };

    // Get lowest price
    let lowestPrice = '';
    if(props.result){
        lowestPrice = props.result.units.reduce((prev, curr) => {
            return (+prev.unitPrice <= +curr.unitPrice) ? prev : curr
        });
    }

    return props.result ? (
        <>
            <Container>
                <Row className="bg-secondary py-3 mb-3 align-items-center">
                    {props.result && (
                        <Col xs={12} md={6} lg={8}>
                            <h2 className="h3 mb-0 text-center text-md-left">{props.result.units.length} {props.result.units.length > 1 ? "Holidays" : "Holiday"} found from &pound;{lowestPrice.unitPrice}</h2>
                            <p className="h4 text-darker mb-0 text-center text-md-left">{`${getDayNameFromDate(props.result.date, shortDate)} ${getDayAndMonthNameFromDate(props.result.date, shortDate)} for ${props.result.nights} nights`}</p>
                        </Col>
                    )}
                    <Col xs={12} md={6} lg={4} className="d-flex align-items-center">
                        <Form.Group className="flex-grow-1 m-0 mt-3 mt-md-0">
                            <FormControl as="select" className="m-0 w-100" onChange={(e) => onChange(e.target.value)}>
                                {sortOptions.map((opt) => (
                                    <option key={`${opt.value}`} value={`${opt.value}`}>
                                        {opt.label}
                                    </option>
                                ))}
                            </FormControl>
                        </Form.Group>
                    </Col>
                </Row>                
            </Container>

            {sortResults(props.result.units).map((unit) => (
                <AccommodationListing
                    key={unit.unitId}
                    holiday={unit}
                    parkDetails={props.parkDetails}
                    onBook={() => props.onBook(unit)}
                />
            ))}
        </>
    ) : (
        <div className="content-box p-4">
            <h2 className="mb-4">{t('park.accommodation.accommodation')}</h2>
            {t('park.accommodation.noResults')}
        </div>
    );
};

Accommodation.propTypes = {
    onBook: PropTypes.func,
    parkDetails: PropTypes.object,
    result: PropTypes.object,
};

Accommodation.defaultProps = {};

export default Accommodation;
