import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import CardPanel from '../../../components/CardPanel';
import CardPayment from './CardPayment';
import PaypalPayment from './PaypalPayment';
import WithHolidaySummary from '../../../components/Booking/WithHolidaySummary';
import WithLoadingStates from '../../../components/Booking/WithLoadingStates';
import useNextStep from '../../../components/Booking/useNextStep';
import RequestErrors from '../../../components/RequestErrors';

import { stepCompleted } from '../../../redux/booking/booking/actions';
import { prePayment, setPaymentUsed } from '../../../redux/booking/payment/actions';
import { bookingId } from '../../../redux/booking/booking/selectors';
import { paymentAmount } from '../../../redux/booking/form/selectors';
import { paypal, payit, prePaymentHasError, prePaymentIsFetching, prePaymentErrorData } from '../../../redux/booking/payment/selectors';

import { BOOKING_STEPS } from '../../../Constants';
import t from '../../../../text';
import { config } from '../../../../config';
import PayItPayment from './PayItPayment';

export const Payment = ({ history }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const _amount = useSelector(paymentAmount);
    const _bookingId = useSelector(bookingId);
    const _paypal = useSelector(paypal);
    const _payit = useSelector(payit);
    const _prePaymentHasError = useSelector(prePaymentHasError);
    const _prePaymentErrorData = useSelector(prePaymentErrorData);
    const _prePaymentIsFetching = useSelector(prePaymentIsFetching);

    const nextStep = useNextStep();
    const [prepaymentLoaded, setPrepaymentLoaded] = useState(false);

    const paypalProviderOptions = {
        'client-id': _paypal.clientId,
        components: 'buttons,hosted-fields,funding-eligibility',
        currency: 'GBP',
        'data-client-token': _paypal.clientToken,
    };

    // Initial data load
    const loadPrePaymentData = () => {
        prePayment(dispatch, _bookingId, Math.round(_amount * 100) / 100)
            .request.then(() => setPrepaymentLoaded(true))
            .catch(() => {
                /* do nothing, redner will react to failed flag */
            });
    };

    const onPaypalPaymentMethodAdded = (method) => {
        setPaymentUsed(dispatch, 'paypal', method);
        stepCompleted(dispatch, BOOKING_STEPS.PAYMENT);
        history.push(`/booking/${nextStep}`);
    };

    // Load new pre-payment data if needed / on page refresh
    useEffect(loadPrePaymentData, [dispatch, _amount, _bookingId]);

    return (
        <WithHolidaySummary payment>
            <WithLoadingStates
                isPayment
                hasErrorFetching={_prePaymentHasError}
                isFetching={!_prePaymentHasError && (!prepaymentLoaded || _prePaymentIsFetching)}
                loadingErrorMessage={<RequestErrors requestError={_prePaymentErrorData} classes="mb-3" />}
                onRetry={loadPrePaymentData}
                spinnerMessage={t('booking.payment.loading')}
            >
                <CardPanel accordion opened accordionId="payment" title={t('booking.payment.makeYourPayment')}>
                    {paypalProviderOptions?.['client-id'] && (
                        <PayPalScriptProvider options={paypalProviderOptions}>
                            <Tabs
                                defaultActiveKey="direct"
                                id="paymentTabs"
                                variant="pills"
                                className="full-width mb-4"
                            >
                                {config['payment.paypalCard'] && (
                                    <Tab eventKey="direct" title={t('booking.payment.creditOrDebitCard')}>
                                        <CardPayment onSuccess={(resp) => onPaypalPaymentMethodAdded('card', resp)} />
                                    </Tab>
                                )}
                                {config['payment.paypal'] && (
                                    <Tab eventKey="paypal" title={t('booking.payment.paypal')}>
                                        <PaypalPayment
                                            onSuccess={(resp) => onPaypalPaymentMethodAdded('buttons', resp)}
                                        />
                                    </Tab>
                                )}
                                {config['payment.payit'] && (
                                    <Tab eventKey="payit" title={t('booking.payment.payit')}>
                                        <PayItPayment redirectUrl={_payit.redirectLink} />
                                    </Tab>
                                )}
                            </Tabs>
                        </PayPalScriptProvider>
                    )}
                </CardPanel>
                {location.state?.error && (
                    <p className="text-danger mt-3">{t('booking.payment.errorProcessingPayment')}</p>
                )}
            </WithLoadingStates>
        </WithHolidaySummary>
    );
};

export default Payment;
