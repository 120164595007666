import { createSelector } from 'reselect';

import { channelId, regionId, results } from './selectors';
import { channels } from '../../../data/channels/selectors';
import { parks, regionParkIds } from '../../../data/parks/selectors';
import { operators } from '../../../data/operators/selectors';
import { regions } from '../../../data/regions/selectors';

import { getChannelById } from '../../../data/channels/utils';
import { getParkById, getParksByRegionId } from '../../../data/parks/utils';
import { getOperatorById } from '../../../data/operators/utils';
import { getRegionById } from '../../../data/regions/utils';

// Selectors
export const currentParks = createSelector(parks, regionParkIds, regionId, (_parks, _regionParkIds, _regionId) => {
    return getParksByRegionId(_parks, _regionParkIds, _regionId);
});

export const currentRegion = createSelector(regions, regionId, (_regions, _regionId) => {
    return getRegionById(_regions, _regionId);
});

export const processedResults = createSelector(
    channelId,
    channels,
    regions,
    operators,
    parks,
    results,
    (_channelId, _channels, _regions, _operators, _parks, _results) => {
        return _results.map((result) => {
            const park = getParkById(_parks, result.parkUrn);

            return {
                ...result,
                channel: getChannelById(_channels, _channelId),
                park,
                operator: getOperatorById(_operators, park?.opId),
                region: getRegionById(_regions, park?.regn),
            };
        });
    },
);
