const namespace = 'BOOKING/FORM/';

export const CHANGE_DETAIL = namespace + 'CHANGE_DETAIL';
export const CHANGE_PARTY_DETAIL = namespace + 'CHANGE_PARTY_DETAIL';
export const SELECT_ARE_YOU_GOING = namespace + 'SELECT_ARE_YOU_GOING';
export const SELECT_EXTRA = namespace + 'SELECT_EXTRA';
export const SELECT_PAYMENT_OPTION = namespace + 'SELECT_PAYMENT_OPTION';
export const SELECT_OFFERS_OPTION = namespace + 'SELECT_OFFERS_OPTION';
export const SET_PARTY_DETAILS = namespace + 'SET_PARTY_DETAILS';

