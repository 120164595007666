import { connect } from 'react-redux';
import Home from './Home';
import { content } from '../../redux/homepage/selectors';

const mapStateToProps = (state) => ({
    content: content(state),
});

const mapDispatchToProps = () => ({});

export const HomeConnected = connect(mapStateToProps, mapDispatchToProps)(Home);
export default HomeConnected;
