import { GET_OPERATORS } from './action-types';
import { GET_INIT_DATA } from '../../init/action-types';
import { SUCCESS } from '../../constants';
import { arrayToObject } from '../../../utils/utils';

const initialState = {
    operators: {},
    channelOperatorIds: {},
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INIT_DATA + SUCCESS:
            if (action.payload.response.data.data.operators) {
                let operators = { ...state.operators };
                let channelOperatorIds = { ...state.channelOperatorIds };

                Object.keys(action.payload.response.data.data.operators).forEach((channel) => {
                    channelOperatorIds[channel] = [];
                    action.payload.response.data.data.operators[channel].forEach((operator) => {
                        operators[operator.opId] = operator;
                        channelOperatorIds[channel].push(operator.opId);
                    });
                });

                return {
                    ...state,
                    operators,
                    channelOperatorIds,
                };
            }
            return state;
        case GET_OPERATORS + SUCCESS:
            return {
                ...state,
                operators: {
                    ...state.operators,
                    ...arrayToObject(action.payload.response.data.data.operators, 'opId'),
                },
                channelOperatorIds: {
                    ...state.channelOperatorIds,
                    [action.payload.params.channel]: action.payload.response.data.data.operators.map((op) => op.opId),
                },
            };
        default:
            return state;
    }
};

export default reducer;
