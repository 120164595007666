import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useReduxFormState = (selector, updateAction, validator, initialErrors = {}) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({ ...initialErrors });
    const values = useSelector(selector);

    function isValid() {
        const _errors = typeof validator === 'function' ? validator(values) : {};
        return Object.keys(_errors).length === 0;
    }

    function onChange(key, value) {
        if (typeof updateAction === 'function') {
            updateAction(dispatch, key, value);
        }
    }

    function validate() {
        const _errors = typeof validator === 'function' ? validator(values) : {};
        setErrors(_errors);
        return _errors;
    }

    return {
        values,
        onChange,
        validate,
        isValid,
        errors,
    };
};

export default useReduxFormState;
