import { connect } from 'react-redux';
import Confirmation from './Confirmation';

import { confirmationId, type } from '../../../redux/booking/booking/selectors';

const mapStateToProps = (state) => ({
    bookingType: type(state),
    confirmationId: confirmationId(state),
});

const mapDispatchToProps = () => ({});

export const ConfirmationConnected = connect(mapStateToProps, mapDispatchToProps)(Confirmation);
export default ConfirmationConnected;
