import { GET_INIT_DATA } from '../../init/action-types';
import { GET_CHANNELS } from './action-types';
import { SUCCESS } from '../../constants';

const initialState = {
    channels: [],
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INIT_DATA + SUCCESS:
        case GET_CHANNELS + SUCCESS:
            if (action.payload.response.data.data.channels) {
                return {
                    channels: [...action.payload.response.data.data.channels],
                };
            }
            return state;
        default:
            return state;
    }
};

export default reducer;
