import React from 'react';
import { Link } from 'react-router-dom';
import A from '../A';
import { useDispatch } from 'react-redux';
import { selectChannel, selectRegion } from '../../redux/search/form/actions';

function MegaMenuColumn({section, setExpanded, channelId}) {
    const dispatch = useDispatch();

    const getLink = (text, url) => {
        let path = url || false;
        if (!path) return text;
        if (/^http.*/gi.test(path)) return <A href={path}>{text}</A>;
        
        const numberRegex = /\d+/g;
        const regionIdFromUrl = url.match(numberRegex);
       
        return (
            <Link to={path} onClick={() => {
                selectChannel(dispatch, channelId);
                if (regionIdFromUrl && regionIdFromUrl.length > 0) {
                    selectRegion(dispatch, regionIdFromUrl[0])
                }
                setExpanded(false)}
            }>
                {text}
            </Link>
        );
    };

    return  (
        <>
            <p className="lead mb-2 megamenu-heading">{section.title}</p>
            <ul className="list-unstyled">
                {section.links.map((link, i) => {
                    return (
                        <li key={i} className="mb-3">{getLink(link.text, link.url)}</li>
                    );
                })}
            </ul>
        </>
    );
}

export default MegaMenuColumn;
