import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getParks } from '../../redux/data/parks/actions';
import { getHasFailed, getIsLoading, getIsLoaded } from '../../redux/data/parks/selectors';

import useDataLoader from '../../hooks/useDataLoader';

export function WithParks(props) {
    const getData = (dispatch, force) => getParks(dispatch, props.regionId, force);
    const _hasFailed = useSelector((state) => getHasFailed(state, props.regionId));
    const _isLoaded = useSelector((state) => getIsLoaded(state, props.regionId));
    const _isLoading = useSelector((state) => getIsLoading(state, props.regionId));
    const _isReady = !!props.regionId;

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady);
}

WithParks.propTypes = {
    regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WithParks;
