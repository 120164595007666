import { SELECT_CHANNEL, SELECT_DATE, SELECT_PARK } from './action-types';

export const selectChannel = (dispatch, channelId) => {
    dispatch({ type: SELECT_CHANNEL, payload: channelId });
};

export const selectDate = (dispatch, date) => {
    dispatch({ type: SELECT_DATE, payload: date });
};

export const selectPark = (dispatch, parkId) => {
    dispatch({ type: SELECT_PARK, payload: parkId });
};
