import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Icon from '../../Icon';
import t from '../../../../text';

//import styles from './DealOfTheWeekBanner.module.scss';

export const DealOfTheWeekBanner = () => (
    <Container fluid className="bg-primary text-white border-bottom p-0 py-4">
        <Container>
            <Row className="align-items-center">
                <Col>
                    <div className="d-flex text-left">
                        <div className="mr-3 mt-4">
                            <Icon className="bg-secondary p-4 rounded" icon="beach" size="h1" theme="white" />
                        </div>
                        <div className="flex-grow-1">
                            <p className="h2 text-white">{t('dotw.dealOfTheWeek')}</p>
                            <p className="mb-0 lead">{t('dotw.allPricesBasedUpon')}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </Container>
);

export default DealOfTheWeekBanner;
