import {
    SET_STATE,
    RESET_N_FILTERS,
    RESET_B_FILTERS,
    SET_CODE_WORDS_VALIDATED,
} from './action-types';
import { GET_RESULTS } from '../../redux/search/results/action-types';
import { SELECT_CHANNEL, SELECT_REGION } from '../../redux/pages/browseParks/action-types';
import { SUCCESS } from '../../redux/constants';

const defaultState = {
    bFiltersStateArray: [],
    nFiltersStateArray: [],
    codeWordsValidated: false,
};

function deleteFromState(state, key) {
    delete state[key];
    return state;
}

//const resetNFilters = (state) => {
//    if (!state || !state.length) return;
//    return Array(state.length).fill(-1);
//}

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_STATE:
            return {
                ...state,
                [action.payload.key]: action.payload.data,
            };
        case SET_CODE_WORDS_VALIDATED:
            return {
                ...state,
                codeWordsValidated: action.payload,
            };
        case RESET_N_FILTERS:
            return {
                ...state,
                nFiltersStateArray: [],
            };
        case RESET_B_FILTERS:
            return {
                ...state,
                bFiltersStateArray: [],
            };
        case GET_RESULTS + SUCCESS:
            return deleteFromState({ ...state }, 'searchResultsOperatorFilters');
        case SELECT_CHANNEL:
        case SELECT_REGION:
            return deleteFromState({ ...state }, 'parksOperatorFilters');
        default:
            return state;
    }
};

export default reducer;
