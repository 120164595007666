import { useState, useEffect } from 'react';
import { urls } from '../../../../config';

export const useFetchData = (data, destination) => {
    let [returnData, setReturnData] = useState([]);

    useEffect(() => {
        if (destination === 'expert-insight' && data && data.length === 0) return;
        let url = `${urls.cms}/${destination}/?_fields=title,acf`;
        data.forEach((pl) => {
            url += '&include[]=' + pl;
        });
        const controller = new AbortController();
        fetch(url, { signal: controller.signal })
            .then((response) => response.json())
            .then((data) => {
                setReturnData(data);
            });
        return () => {
            controller.abort();
        };
    }, [data, destination]);

    return returnData || [];
};

export default useFetchData;
