/**
 * Takes the given HTML string and Returns a new one with all of the HTML tags stripped
 * @param {string} html - HTML string to strip the tags from
 */
export function decodeHtml(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}

/**
 * Takes a string and applies various conversions, returning a new slug-friendly string
 * @param {*} string - The string to generate the slug-friendly string from
 */
export function slugify(string) {
    return string
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
        .replace('-amp-', '-'); // -and-amp- now says -and-
}

/**
 * Takes a URL string and returns a new one with the protocol (E.G. https://) removed
 * @param {string} url
 */
export function stripProtocol(url) {
    if (typeof url !== 'string') return url;
    return url.split('//')[1] || url;
}

/**
 * Takes the given number / number string and converts it to a 2DP number string (useful for prices)
 * @param {string|number} stringNum - Number or number string to convert
 */
export function to2dp(stringNum) {
    return parseFloat(stringNum).toFixed(2);
}
