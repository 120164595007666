import React from 'react';
import { useSelector } from 'react-redux';

import RedEye from './RedEye';

import { processedResults } from '../../redux/search/results/selectors/results';
import { stripProtocol, to2dp } from '../../utils/string';

export const RedEyeSearchResultsTag = () => {
    const _results = useSelector(processedResults);
    const results = _results.slice(0, 5);

    const resultsTags = {};
    results.forEach((result, i) => {
        resultsTags[`search_r_name${i + 1}`] = result.park.name;
        // resultsTags[`search_r_destinationa${i + 1}`] = result.channel.code;
        resultsTags[`search_r_destinationb${i + 1}`] = result.region.name;
        resultsTags[`search_r_destinationc${i + 1}`] = result.park.sAdd;
        resultsTags[`search_r_price_from${i + 1}`] = to2dp(result.minPrice || result.altDateMinPrice);
        resultsTags[`search_r_image${i + 1}`] = stripProtocol(result.park?.img);
    });

    return (
        <RedEye
            params={{
                nourl: 'search-confirm-results',
                ...resultsTags,
            }}
        />
    );
};

export default RedEyeSearchResultsTag;
