import { useDispatch, useSelector } from 'react-redux';

import { setDiscount } from '../../redux/booking/booking/actions';
import { discountCode, settingDiscount, settingDiscountFailed, setDiscountError } from '../../redux/booking/booking/selectors';

import useLocalFormState from '../../hooks/useLocalFormState';
import { validateField } from './DiscountCode';

export const useDiscountCodeActions = () => {
    const dispatch = useDispatch();
    return {
        setDiscount: (bookingId, code) => setDiscount(dispatch, bookingId, code)
    };
};

export const useDiscountCodeState = () => ({
    ...useLocalFormState(validateField, discountCode),
    settingDiscount: useSelector(settingDiscount),
    settingDiscountFailed: useSelector(settingDiscountFailed),
    setDiscountError: useSelector(setDiscountError)
});

export default useDiscountCodeState;
