import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

import { Col, Container, Button, Row, Modal } from 'react-bootstrap';
import TextInput from '../FormElements/TextInput';
import RegionSelect from './RegionSelect';
import NewspaperSelect from './NewspaperSelect';
import DateDurationSelect from './DateDurationSelect';
import GuestsSelect from './GuestsSelect';
import ChannelSelect from './ChannelSelect';
import { channels } from '../../redux/data/channels/selectors';

import {
    channelId,
    regionId,
    currentRegions,
    regionsLoading,
    regionsNeedToBeLoaded,
    adults,
    children,
    under5s,
    arrivalDate,
    duration,
    petFriendly,
    params,
    requestParams,
    selectedNewspaper,
    availableDates,
} from '../../redux/search/form/selectors';
import {
    getPromotion
} from '../../redux/init/selectors';
import {
    selectChannel,
    selectRegion,
    selectNewspaper,
    selectGuestsAdults,
    selectGuestsChildren,
    selectGuestsUnder5s,
    selectDate,
    selectDuration,
    storeAvailableDates,
    startSearch,
    cancelSearch,
    resetArrival,
    selectPetFriendly,
} from '../../redux/search/form/actions';
import { cancelGetResults } from '../../redux/search/results/actions';
import { getRegions } from '../../redux/data/regions/actions';
import t from '../../../text';
import { resetBFilters, resetNFilters, setState, setCodeWordsValidated } from '../../redux/appState/actions';
import { email } from '../../utils/validation';
import { urls } from '../../../config';
import { bookingMode } from '../../redux/booking/booking/selectors';
import { getCodeWordsValidated } from '../../redux/appState/selectors';

const SearchBox = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const selectedChannelId = useSelector(channelId);
    const selectedRegionId = useSelector(regionId);
    const selectedArrivalDate = useSelector(arrivalDate);
    const selectedNewspaperFromRedux = useSelector(selectedNewspaper);
    const selectedInitPromoValues = useSelector(getPromotion);
    const _availableDates = useSelector(availableDates);
    const numberOfPasswords = parseInt(selectedInitPromoValues.keywords ? selectedInitPromoValues.keywords : 0)
    const maxPartySize = parseInt(selectedInitPromoValues.maxparty)

    const _regionsLoading = useSelector(regionsLoading);
    const _regionsNeedToBeLoaded = useSelector(regionsNeedToBeLoaded);
    const _currentRegions = useSelector(currentRegions) || [];
    const _codeWordsValidated = useSelector(getCodeWordsValidated) || [];

    const _adults=useSelector(adults);
    const _children=useSelector(children);
    const _under5s=useSelector(under5s);

    const sortedRegions = _currentRegions.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });

    const _params = useSelector(params);
    const _duration = useSelector(duration);
    const _requestParams = useSelector(requestParams);
    const _petFriendly = useSelector(petFriendly);
    const { parkId } = useParams();

    const _nFilterArray = useSelector((state) => state.appState.nFiltersStateArray);
    const _bFilterArray = useSelector((state) => state.appState.bFiltersStateArray);
    const _bookingMode = useSelector(bookingMode);

    const [allowSearchAll, setAllowSearchAll] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);

    const [promoNewspapers, setPromoNewspapers] = useState()
    const [codeWords, setCodeWords] = useState([]);
    const [codeWordsIterator, setCodeWordsIterator] = useState([]);
    const [emailAddress, setEmailAddress] = useState("");
    const [codeWordsValid, setCodeWordsValid] = useState(false)
    const [codeWordsInvalid, setCodeWordsInvalid] = useState(null)
    const [emailInvalid, setEmailInvalid] = useState(null)
    const [showNewspaperError, setShowNewspaperError] = useState(false)
    const [showDateErrorMessage, setShowDateErrorMessage] = useState(false)
    const [showPartyErrorMessage, setShowPartyErrorMessage] = useState(false)
    const [showPartyAdultErrorMessage, setShowPartyAdultErrorMessage] = useState(false)

    const dealOfTheWeek = location.pathname.search('/deal-of-the-week/') !== -1;
    const doFullSearch = !dealOfTheWeek || allowSearchAll;

    const [show, setShow] = useState(false);
    const handleModal = () => {
        if(_codeWordsValidated === true) {
            setCodeWordsValid(true);
            setShow(!show)
        } else {
            setShow(!show)
        }
    };

    // Util functions
    const getRegionLabel = () => {
        if (selectedChannelId === 'boats') return t('search.route');
        return t('search.region');
    };

    const fetchSearchResults = () => {
        cancelGetResults(); // Cancel last request to get search results
        cancelSearch(); // Cancel last request to start a search
        if (props.parkId) {
            startSearch(dispatch, _params, _requestParams, props.parkId);
        } else {
            startSearch(dispatch, _params, _requestParams, doFullSearch ? undefined : parkId);
        }
    };

    const updateRoute = () => {
        let searchQuery = Object.keys(_requestParams)
            .filter((key) => typeof _requestParams[key] !== 'undefined')
            .filter((key) => key !== 'partysize')
            .filter((key) => doFullSearch || (key !== 'channel' && key !== 'region'))
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(_requestParams[key])}`)
            .join('&');
        if (props.parkId) searchQuery = searchQuery + '&park=' + props.parkId;

        if (doFullSearch) {
            history.push({ pathname: '/results', search: searchQuery });
        } else {
            history.push({ pathname: location.pathname, search: searchQuery });
        }
    };

    const submitAndCloseDropdown = (value, func, selectFunc) => {
        func(dispatch, value);
        setTimeout(() => {
            selectFunc(false);
        }, 650);
    };

    // Channels
    const [showChannelSelect, setShowChannelSelect] = useState(false);
    const handleChannelToggle = () => setShowChannelSelect(!showChannelSelect);
    const handleChannelSelect = (event) => {
        const channelChosen = event.target.value;
        const previousChannel = selectedChannelId;
        submitAndCloseDropdown(channelChosen, selectChannel, setShowChannelSelect);
        calculateShouldResetArrival(previousChannel, channelChosen);
    };

    // Regions
    const [showRegionSelect, setShowRegionSelect] = useState(false);
    const handleRegionToggle = () => setShowRegionSelect(!showRegionSelect);
    const handleRegionSelect = (event) => {
        const previousChannel = selectedChannelId;
        submitAndCloseDropdown(event.target.value, selectRegion, setShowRegionSelect);
        calculateShouldResetArrival(previousChannel);
    };

    // Dates
    const [showDatePanel, setShowDatePanel] = useState(false);
    const handleDateToggle = () => setShowDatePanel(!showDatePanel);
    const handleDateChange = (date, modifiers) => {
        if (modifiers.disabled) return; // Do nothing if date is disabled.
        setShowDateErrorMessage(false);
        if(selectedChannelId === 'ukpromo') {
          if(date.getDay() === 1) {
              selectDuration(dispatch, 4)
          } else if (date.getDay() === 5) {
              selectDuration(dispatch, 3)
          }
        }
        if(selectedChannelId === 'eupromo') {
          if(date.getDay() === 1 && _duration === 3 ) {
              selectDuration(dispatch, 4)
          } else if (date.getDay() === 5 && _duration === 4) {
              selectDuration(dispatch, 3)
          }
        }
        submitAndCloseDropdown(date, selectDate, setShowDatePanel);
    };

    // Newspaper
    const [showNewspaperSelect, setShowNewspaperSelect] = useState(false);
    const handleNewsPaperToggle = () => setShowNewspaperSelect(!showNewspaperSelect);
    const handleNewsPaperSelect = (event) => {
        const previousChannel = selectedChannelId;
        submitAndCloseDropdown(event.target.value, selectNewspaper, setShowNewspaperSelect);
        calculateShouldResetArrival(previousChannel);
    };

    const handleSearch = () => {
        const guestTotal = _adults + _children + _under5s;

        if(selectedArrivalDate === undefined) {
            setShowDateErrorMessage(true);
        } else if (guestTotal <= 0) {
            setShowPartyErrorMessage(true);
        } else if (_adults < 1) {
            setShowPartyAdultErrorMessage(true);
        } else {
            setShowSearchModal(false);
            setShowPartyErrorMessage(false);
            setShowPartyAdultErrorMessage(false);
            setShowDateErrorMessage(false);
            setShow(false);
            updateRoute();
            fetchSearchResults();
            resetNFilters(dispatch, _nFilterArray);
            resetBFilters(dispatch, _bFilterArray);
            setState(dispatch, 'searchResultsPetFriendlyFilters', _petFriendly ? ['y'] : ['n']);
        }
    };

    // Pet friendly
    const handlePetFriendlyToggle = (event) => {
        selectPetFriendly(dispatch, event.target.checked);
    };

    // Load regions, if required
    useEffect(() => {
        if (_regionsNeedToBeLoaded) {
            getRegions(dispatch, selectedChannelId);
        }
    }, [dispatch, _regionsNeedToBeLoaded, selectedChannelId]);

    // Select default region if it has been wiped out (happens after chaning channel)
    useEffect(() => {
        if (
            !_regionsNeedToBeLoaded &&
            !_regionsLoading &&
            Object.keys(_currentRegions).length &&
            selectedChannelId &&
            !selectedRegionId
        ) {
            selectRegion(dispatch, sortedRegions[0].id);
        }
    });

    useEffect(() => {
        if (window.location.pathname === '/') {
            selectChannel(dispatch, 'eupromo');
        }

        const newspapers = [];

        if(selectedInitPromoValues.papercodes) {
            Object.keys(selectedInitPromoValues.papercodes).map((key) => {
                newspapers.push({
                    id: key,
                    name: selectedInitPromoValues.papercodes[key]
                })
            })

            newspapers.sort(function(a, b) {
                var keyA = a.id,
                    keyB = b.id;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        }

        setPromoNewspapers(newspapers)

        setCodeWords(Array.from(Array(numberOfPasswords).keys()).map(_i => ""))
        setCodeWordsIterator(Array.from(Array(numberOfPasswords).keys()))
    }, []);

    const calculateShouldResetArrival = (previousChannel, channelSelected = null) => {
        // reset arrival if user just selected cottages
        if (channelSelected === 'ukcottages') return resetArrival(dispatch);

        // reset arrival if user just came off of cottages or switched regions whilst on ukcottages
        if (selectedChannelId === 'ukcottages') return resetArrival(dispatch);

        // prevents the possibility of a race case bug
        if (previousChannel === 'ukcottages') return resetArrival(dispatch);
    };

    useEffect(() => {
        const hideSearchModalOnResize = () => {
            if (window.outerWidth < 429) return; // Don't do this on mobile phones.
            let timeout;
            window.clearInterval(timeout);
            timeout = setTimeout(() => setShowSearchModal(false), 200); // debounce for performance
        };
        window.addEventListener('resize', hideSearchModalOnResize);
        return () => {
            window.removeEventListener('resize', hideSearchModalOnResize);
        };
    });

    const onChangeCodeWords = (e, i) => {
        const codeWordsClone = [...codeWords];

        codeWordsClone.splice(i, 1, e.target.value)
        setCodeWords(codeWordsClone)
        setCodeWordsInvalid(null);
    }

    const onChangeEmailAddress = (e) => {
        setEmailAddress(e.target.value);
        setEmailInvalid(null);
    }

    const dataFetching = () => {

        let formBody = [];

        if(selectedInitPromoValues.papercapture === 'Y') {
            formBody.push(encodeURIComponent('newspaper') + '=' + encodeURIComponent(selectedNewspaperFromRedux));
        }

        if(selectedInitPromoValues.emailcapture === "Y") {
            formBody.push(encodeURIComponent('email') + '=' + encodeURIComponent(emailAddress));
        }

        if(codeWordsIterator.length > 0){
            codeWords.forEach((word, index) => {
                formBody.push(encodeURIComponent(`code${index+1}`) + '=' + encodeURIComponent(word));
            })
        };

        if( selectedInitPromoValues.emailcapture === "Y" || selectedInitPromoValues.papercapture === 'Y' || codeWordsIterator.length > 0 ){

            formBody = formBody.join("&");

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                body: formBody
              };

            fetch(`${urls.validatecodewords}`, requestOptions )
                .then((response) => response.json())
                .then((data) => {
                    if(data.success === 1) {
                        setCodeWordsValidated(dispatch, true);
                        setCodeWordsValid(!codeWordsValid)
                        fetch(`${urls.getpromodata}`).then((response) => response.json()).then((data) => {
                            storeAvailableDates(dispatch, data.data.DATES)
                        })
                    } else {
                        setCodeWordsValidated(dispatch, false);
                        setCodeWordsInvalid(true)
                    }

                })
                .catch((e) => {
                    console.error('Validation request failed.', e);
            });
        } else {
            setCodeWordsValidated(dispatch, true);
            setCodeWordsValid(!codeWordsValid)
            fetch(`${urls.getpromodata}`).then((response) => response.json()).then((data) => {
                storeAvailableDates(dispatch, data.data.DATES)
            })
        }
    }

    const validateSubmit = () => {
        setCodeWordsValidated(dispatch, false);
        const emailValid = email(emailAddress)
        setEmailInvalid(!emailValid)

        if(selectedInitPromoValues.emailcapture === "Y") {
            if(selectedInitPromoValues.papercapture === "Y") {
                if(emailValid && selectedNewspaperFromRedux !== "") {
                    setShowNewspaperError(false);
                    dataFetching();
                }

                if(selectedNewspaperFromRedux === "") {
                    setShowNewspaperError(true);
                }
            } else {
                if(emailValid) {
                    dataFetching();
                }
            }
        } else {
            if(selectedInitPromoValues.papercapture === "Y") {
                if(selectedNewspaperFromRedux !== "") {
                    setShowNewspaperError(false);
                    dataFetching();
                }

                if(selectedNewspaperFromRedux === "") {
                    setShowNewspaperError(true);
                }
            } else {
                dataFetching();
            }
        }
    }

    return (
        <>
            {selectedInitPromoValues.open && _bookingMode && (
                <Button
                    variant="primary"
                    onClick={handleModal}
                    block
                    className="col-6 col-lg-3"
                    style={{ margin: 'auto' }}
                >
                    Search For Your Holiday
                </Button>
            )}

            <div className="search-box-container-code-entry">
                <Container
                    className={`search-box d-none d-md-block ${showSearchModal ? 'is-open' : ''}`}
                    id="search-modal-code-entry"
                >
                    <Modal show={show} onHide={handleModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Find your holiday</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row style={{ display: `${codeWordsValid ? 'none' : 'flex'}` }}>
                                    {codeWordsIterator.length > 0 && (
                                        <>
                                            {codeWordsIterator.map((i) => (
                                                <TextInput
                                                    key={`code_word_${i}`}
                                                    groupClassName="col-6 col-lg-6"
                                                    name={`Code Word ${i + 1}`}
                                                    label={`Password ${i + 1}`}
                                                    value={codeWords[i]}
                                                    onChange={(e) => onChangeCodeWords(e, i)}
                                                />
                                            ))}
                                            {codeWordsInvalid && (
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    className="col-12 col-lg-12"
                                                    style={{ display: 'block', marginBottom: '16px' }}
                                                >
                                                    Please input correct code words
                                                </Form.Control.Feedback>
                                            )}
                                        </>
                                    )}
                                    {selectedInitPromoValues.papercapture === 'Y' && (
                                        <>
                                            <Col md={12} lg={12}>
                                                <NewspaperSelect
                                                    id="searchbox-newspaper"
                                                    textStyle="black"
                                                    label="Newspaper"
                                                    channelSelected={!!selectedChannelId}
                                                    newspapers={promoNewspapers}
                                                    selectedValue={selectedNewspaperFromRedux}
                                                    onChange={handleNewsPaperSelect}
                                                    onToggle={handleNewsPaperToggle}
                                                    show={showNewspaperSelect}
                                                    disabled={!doFullSearch}
                                                />
                                            </Col>
                                            {showNewspaperError && (
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    className="col-12 col-lg-12"
                                                    style={{ display: 'block', marginBottom: '16px' }}
                                                >
                                                    Please input a newspaper
                                                </Form.Control.Feedback>
                                            )}
                                        </>
                                    )}

                                    <p className="col-12 col-lg-12">
                                        Enter your email address to receive offer updates and exclusive deals from
                                        Breakfree Holidays
                                    </p>
                                    <TextInput
                                        groupClassName="col-12 col-lg-12"
                                        name="Code Words"
                                        label="Email Address"
                                        value={emailAddress}
                                        invalid={emailInvalid}
                                        validationMessage={'Please input valid email'}
                                        onChange={(e) => onChangeEmailAddress(e)}
                                    />
                                    <Button
                                        className="col-11 col-lg-11"
                                        onClick={validateSubmit}
                                        block
                                        style={{ marginLeft: '20px' }}
                                    >
                                        Continue
                                    </Button>
                                </Row>
                                <Row style={{ display: `${codeWordsValid ? 'flex' : 'none'}` }}>
                                    <Col md={12} lg={12}>
                                        <RegionSelect
                                            id="searchbox-region"
                                            textStyle="black"
                                            label={getRegionLabel()}
                                            channelSelected={!!selectedChannelId}
                                            regions={sortedRegions}
                                            regionsLoading={_regionsNeedToBeLoaded || _regionsLoading}
                                            selectedValue={selectedRegionId}
                                            onChange={handleRegionSelect}
                                            onToggle={handleRegionToggle}
                                            show={showRegionSelect}
                                            disabled={!doFullSearch}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ display: `${codeWordsValid ? 'flex' : 'none'}` }}>
                                    <Col md={12} lg={12}>
                                        <DateDurationSelect
                                            id="searchbox-date-duration"
                                            channelId={selectedChannelId}
                                            textStyle="black"
                                            label={t('search.durationAndArrivalDate')}
                                            duration={parseInt(useSelector(duration))}
                                            arrivalDate={useSelector(arrivalDate)}
                                            onDateChange={handleDateChange}
                                            onToggle={handleDateToggle}
                                            show={showDatePanel}
                                            showErrorMessage={showDateErrorMessage}
                                            disabled={!doFullSearch}
                                            availableDates={_availableDates}
                                            selectedRegion={selectedRegionId}
                                            onDurationChange={(e) => selectDuration(dispatch, parseInt(e.target.value))}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ display: `${codeWordsValid ? 'flex' : 'none'}` }}>
                                    <Col md={12} lg={12}>
                                        <GuestsSelect
                                            id="searchbox-guests"
                                            textStyle="black"
                                            adults={_adults}
                                            children={_children}
                                            under5s={_under5s}
                                            onAdultsChange={(num) => selectGuestsAdults(dispatch, num)}
                                            onChildrenChange={(num) => selectGuestsChildren(dispatch, num)}
                                            onUnder5sChange={(num) => selectGuestsUnder5s(dispatch, num)}
                                            selectedChannel={selectedChannelId}
                                            showErrorMessage={showPartyErrorMessage}
                                            showErrorMessageAdult={showPartyAdultErrorMessage}
                                            maxGuests={maxPartySize}
                                        />
                                    </Col>
                                </Row>



                                <Row style={{ display: `${codeWordsValid ? 'flex' : 'none'}` }}>
                                    <Button className="col-12 col-lg-12" onClick={handleSearch} block>
                                        Search
                                    </Button>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </Modal>
                </Container>
            </div>
        </>
    );
};

SearchBox.defaultProps = {
    text: 'white',
    bg: 'dark',
    isInPopup: false,
};

export default SearchBox;

/* <Row style={{ display: `${codeWordsValid ? 'flex' : 'none'}` }}>
    <Col md={12} lg={12}>
        <ChannelSelect
          id="searchbox-channel"
          textStyle="black"
          label={t('search.holidayType')}
          channels={useSelector(channels)}
          selectedValue={selectedChannelId}
          whitelist={[
            'ukpromo',
            'eupromo',
          ]}
        onChange={handleChannelSelect}
        onToggle={handleChannelToggle}
        show={showChannelSelect}
        disabled={!doFullSearch}
    />
    </Col>
</Row> */
