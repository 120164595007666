import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import NoImage from '../../assets/images/no-image.png';
import { DDMMYYYYToDate, getDayMonthYearFromDate, getDepartureDate } from '../../utils/date';
import t from '../../../text';

const formatDate = (date, checkout = 0) => {
    const formatDate = DDMMYYYYToDate(date);
    const departureDate = getDepartureDate(formatDate, checkout);
    return getDayMonthYearFromDate(departureDate);
};

export const BookingCard = (props) => {
    const outstandingPayment = parseFloat(props.booking.outstandingPayment) > 0;

    return (
        <Card>
            <Card.Header>
                <Row className="d-flex align-items-center">
                    <Col md={8}>
                        <h4>{props.booking.parkName}</h4>
                    </Col>
                    <Col md={4} className="text-md-right">
                        {!outstandingPayment && (
                            <p className="text-success h5 m-0">
                                <i className="icon-check mr-2"></i>
                                {t('myAccount.bookings.paid')}
                            </p>
                        )}
                        {/*props.booking.amountPaid > props.booking.totalCost && (
                            <p className="text-danger h5 m-0">
                                <i className="icon-attention mr-2"></i>Overpaid?
                            </p>
                        )*/}
                        {outstandingPayment && (
                            <p className="text-secondary h5 m-0">
                                <i className="icon-attention mr-2"></i>
                                {t('myAccount.bookings.outstandingAmount')}
                            </p>
                        )}
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row className="d-flex align-items-center">
                    <Col xs={4}>
                        <img
                            src={`//content.breakfreeholidays.co.uk/assets/images/operators/${props.booking.opId}/logo.png`}
                            alt="Operator Logo"
                            loading="lazy"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = NoImage;
                            }}
                            className="w-100"
                        />
                    </Col>
                    <Col xs={8}>
                        <p className="h5">
                            <strong>{props.booking.accomodation}</strong>
                        </p>
                        <p className="h5">
                            <strong>&pound;{props.booking.totalCost}</strong>
                        </p>
                        <Row xs={2}>
                            <Col>
                                <strong>{t('myAccount.bookings.holidayBookingRef')}</strong>
                            </Col>
                            <Col>{props.booking.bookId}</Col>
                            <Col>
                                <strong>{t('myAccount.bookings.checkIn')}</strong>
                            </Col>
                            <Col>{formatDate(props.booking.holidayDate)}</Col>
                            <Col>
                                <strong>{t('myAccount.bookings.checkOut')}</strong>
                            </Col>
                            <Col>{formatDate(props.booking.holidayDate, parseInt(props.booking.nights))}</Col>
                            <Col>
                                <strong>{t('myAccount.bookings.nights')}</strong>
                            </Col>
                            <Col>{props.booking.nights}</Col>
                        </Row>

                        {outstandingPayment && (
                            <Button className="mt-3" onClick={() => props.onPayBalance(props.booking)}>
                                {t('myAccount.bookings.payHolidayBalance')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

BookingCard.propTypes = {
    booking: PropTypes.object,
    onPayBalance: PropTypes.func,
};

export default BookingCard;
