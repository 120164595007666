import React, { useState, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

const Slider = React.lazy(() => import('react-slick'));

const ParkGallery = (props) => {
    const videoAttributes = '?byline=0&title=0&transparent=0';
    const [videoUrl, setVideoUrl] = useState(props.video + videoAttributes);
    const [images, setImages] = useState();

    const resetVideo = () => {
        if (!props.video) return;
        setVideoUrl('');
    };

    const gallerySettings = {
        className: "park-page-gallery",
        lazyLoad: true,
        infinite: true,
        centerMode: true,
        centerPadding: "0",
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    className: 'park-page-gallery-unset',
                    centerMode: false,
                    slidesToShow: 1,
                }
            }
        ]
    };

    useEffect(() => {
        setVideoUrl(props.video + videoAttributes);
    }, [videoUrl, props.video]);

    useEffect(() => {
        setImages(
            props.images.map((img, i) => (
                <img key={i + 1} className="img-fluid" src={img} alt={props.parkname} loading="lazy" />
            )),
        );
    }, [props.images, props.parkname]);

    return (
        <Container className="mb-3" fluid>
            <Row>
                <Col onClick={() => resetVideo()} style={{ minHeight: '246px' }}>
                    <Suspense fallback={<img src="/img-loading.svg" width="100%" alt="Loading" />}>
                        <Slider className="full-height-slides" {...gallerySettings}>
                            {images}
                        </Slider>
                    </Suspense>
                </Col>
            </Row>
        </Container>
    );
}

ParkGallery.propTypes = {
    description: PropTypes.string,
    images: PropTypes.array,
    parkname: PropTypes.string,
    video: PropTypes.string,
};

export default ParkGallery;
