import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getParkDetails } from '../../redux/data/park/details/actions';
import { getHasFailed, getIsLoading, getIsLoaded } from '../../redux/data/park/details/selectors';

import useDataLoader from '../../hooks/useDataLoader';

export function WithParkDetails(props) {
    const getData = (dispatch, force) => getParkDetails(dispatch, props.parkId, props.regionId, force);
    const _hasFailed = useSelector((state) => getHasFailed(state, props.parkId));
    const _isLoaded = useSelector((state) => getIsLoaded(state, props.parkId));
    const _isLoading = useSelector((state) => getIsLoading(state, props.parkId));
    const _isReady = !!props.parkId;

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady);
}

WithParkDetails.propTypes = {
    parkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WithParkDetails;
