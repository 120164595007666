import { GET_INIT_DATA } from '../init/action-types';
import { SUCCESS } from '../constants';

const initialState = {
    content: [],
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INIT_DATA + SUCCESS:
            return {
                ...state,
                content: action.payload.response.data.data.homepageLayout,
                feefo: action.payload.response.data.data.feefo,
            };
        default:
            return state;
    }
};

export default reducer;
