import React, { useState } from "react";
import { Button, Modal, Alert, Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { selectRegion } from "../../redux/search/form/actions";
import NoPopupSearchBox from "../SearchBox/NoPopupSearchBox";

export const SearchPopup = (props) => {
    /* TODO:
        1. Props
            - size
            - variant
            - region id (optional)
            - park id (optional)
            - op id?
        2. Update search form params
        3. Use existing search form?
    */

    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const handleButtonClick = () => {
        // Set region if applicable
        if (props.regionId ) selectRegion(dispatch, props.regionId);
        // Set Park ID if applicable

        // Show the popup
        handleModal();
    }
    const handleModal = () => setShow(!show);

    return (
        <>
            <Button
                variant={props.variant}
                onClick={handleButtonClick}
                className={props.className}
                block
            >Check Availability</Button>
            <Modal show={show} onHide={handleModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Check Availability</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.parkId && (
                        <Container>
                            <Row>
                                <Col>
                                <Alert variant="secondary">Checking availability of {props.parkName}.</Alert>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    <NoPopupSearchBox isInPopup={true} text="darker" />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SearchPopup;
