import { useDispatch, useSelector } from 'react-redux';

import { prePayment } from '../../../../redux/booking/payment/actions';

import { bookingId } from '../../../../redux/booking/booking/selectors';
import { paymentAmount } from '../../../../redux/booking/form/selectors';
import {
    paymntRecId,
    paypal,
    prePaymentErrorData,
    prePaymentHasError,
    prePaymentIsFetching,
    prePaymentNeedsLoading,
} from '../../../../redux/booking/payment/selectors';

export const usePaypalPaymentFormActions = () => {
    const dispatch = useDispatch();

    return {
        prePayment: (bookId, amount) => prePayment(dispatch, bookId, amount),
    };
};

export const usePaypalPaymentFormState = () => ({
    amount: useSelector(paymentAmount),
    bookingId: useSelector(bookingId),
    paymntRecId: useSelector(paymntRecId),
    paypal: useSelector(paypal),
    prePaymentErrorData: useSelector(prePaymentErrorData),
    prePaymentHasError: useSelector(prePaymentHasError),
    prePaymentIsFetching: useSelector(prePaymentIsFetching),
    prePaymentNeedsLoading: useSelector(prePaymentNeedsLoading),
});

export default usePaypalPaymentFormState;
