import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'react-bootstrap';

export const NewspaperSelect = (props) => {
    const [NewspaperName, setNewspaperName] = useState('');

    const isIOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform);

    useEffect(() => {
        if (!props.selectedValue) setNewspaperName('Select a newspaper');
        else {
            props.newspapers.map((el) => {
                if(el.id === props?.selectedValue)
                setNewspaperName(el.name);
                return;
            })
        } 
    }, [NewspaperName, props.selectedValue, props.newspapers]);

    let regionOptions;
    regionOptions = props.newspapers.map((newspaper) => (
        <Form.Check
            key={newspaper.id}
            id={newspaper.id}
            type="radio"
            label={newspaper.name}
            name="regionSelections"
            value={newspaper.id}
            onChange={props.onChange}
            disabled={!props.channelSelected}
            className="bf-radio"
            checked={props.selectedValue === newspaper.id}
        />
    ));

    return (
        <Form.Group controlId={props.id}>
            <Form.Label className={`text-${props.textStyle}`}>
                <span>{props.label}</span>
            </Form.Label>
            {isIOS ? (
                <Form.Control
                    as="select"
                    className="p-0 toggle-popup"
                    value={props.selectedValue}
                    onChange={props.onChange}
                    disabled={!props.channelSelected}
                    tabIndex="0"
                >
                    {props.newspapers.map((newspaper) => (
                        <option key={newspaper.id} value={newspaper.id} className="py-2 pl-3">
                            {newspaper.name}
                        </option>
                    ))}
                </Form.Control>
            ) : (
                <Dropdown show={props.show} onToggle={props.onToggle}>
                    <Dropdown.Toggle
                        as="button"
                        type="button"
                        className="toggle-popup is-dropdown"
                        role="button"
                        tabIndex="0"
                        disabled={props.disabled}
                    >
                        <span>{NewspaperName}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="a-popup p-0 pt-3">
                        {regionOptions}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </Form.Group>
    );
};

NewspaperSelect.defaultProps = {
    text: 'black',
    whitelist: [],
};

NewspaperSelect.propTypes = {
    id: PropTypes.string,
    textStyle: PropTypes.string,
    label: PropTypes.string,
    channelSelected: PropTypes.bool,
    newspapers: PropTypes.array,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default NewspaperSelect;
