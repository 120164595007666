import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';

import RequestErrors from '../../components/RequestErrors';
import UpdateUserForm from '../../components/Forms/User/UpdateUserForm/UpdateUserForm';
import {
    useUpdateUserFormActions,
    useUpdateUserFormState,
} from '../../components/Forms/User/UpdateUserForm/useUpdateUserForm';
import t from '../../../text';

export function YourDetails() {
    const details = useUpdateUserFormState();
    const [firstRun, setFirstRun] = useState(true);
    const { updateUser } = useUpdateUserFormActions();

    const handleSubmit = (event) => {
        event.preventDefault();
        details.validate();

        if (details.isValid()) {
            updateUser(details.values).request.finally(() => setFirstRun(false));
        }
    };

    return (
        <Card>
            <Card.Header>
                <h2>{t('myAccount.yourDetails.title')}</h2>
                <p className="m-0">{t('myAccount.yourDetails.text')}</p>
            </Card.Header>
            <Card.Body className="p-3">
                <UpdateUserForm
                    values={details.values}
                    errors={details.errors}
                    busy={details.updateUserPending}
                    onChange={details.onChange}
                />
                <Button onClick={handleSubmit} disabled={details.updateUserPending}>
                    {details.updateUserPending ? 'Please wait...' : 'Update'}
                </Button>
                {!firstRun && (
                    <>
                        {details.updateUserFailed ? (
                            <>
                                <p className="text-danger mt-3 mb-0">There was an error while updating your details:</p>
                                <RequestErrors requestError={details.updateUserError} classes="text-danger mt-2 mb-0" />
                            </>
                        ) : (
                            <p className="text-success mt-3 mb-0">Your details have been updated successfully.</p>
                        )}
                    </>
                )}
            </Card.Body>
        </Card>
    );
}

export default YourDetails;
