import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

import { config } from '../../config';

export const Spinner = (props) => {
    return (
        <div className="d-flex align-items-center">
            <Icon
                icon={config['global.spinnerIcon']}
                size="h1"
                theme={config['global.spinnerTheme']}
                className="spin"
            />
            <p className="ml-2 h3">{props.message}</p>
        </div>
    );
};

Spinner.defaultProps = {
    center: false,
    message: 'Loading, please wait...',
};

Spinner.propTypes = {
    center: PropTypes.bool,
    message: PropTypes.string,
};

export default Spinner;
