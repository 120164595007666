import React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => (
    <i
        className={`${props.className ? props.className + ' ' : ''}icon-${props.icon} ${props.size || ''} text-${
            props.theme || ''
        }`}
    ></i>
);

Icon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'text-sm']),
    theme: PropTypes.oneOf(['primary', 'secondary', 'dark', 'darker', 'white']),
};

export default Icon;
