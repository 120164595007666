import React from 'react';
import PropTypes from 'prop-types';
import { Card, Accordion } from 'react-bootstrap';

export const CardPanel = (props) => (
    <>
        {props.accordion ? (
            <Accordion defaultActiveKey={props.opened ? props.accordionId : null}>
                <Card className={`bf-filter ${props.className}`}>
                    <Accordion.Toggle as={Card.Header} className='px-3' eventKey={props.accordionId}>
                        <h4 className='mb-0 d-inline-block'>{!props.noTitle && props.title}</h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={props.accordionId}>
                        <Card.Body className='p-4'>{props.children}</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        ) : (
            <Card className={`bf-filter ${props.className}`}>
                {!props.noTitle && <Card.Header className="h4 px-3">{props.title}</Card.Header>}

                <Card.Body className='p-4'>{props.children}</Card.Body>
            </Card>
        )}
    </>
);

CardPanel.defaultProps = {
    className: '',
    title: '',
    noTitle: false,
};

CardPanel.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    noTitle: PropTypes.bool,
};

export default CardPanel;
