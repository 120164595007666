import React, { useState } from 'react';
import { GoogleMap, InfoWindow, useLoadScript } from '@react-google-maps/api';
import PropTypes from 'prop-types';

import MapMarker from './MapMarker';
import MapStyle from '../../Data/GoogleMapStyle';

import { config } from '../../../config';

const containerStyle = {
    width: '100%',
    height: '500px',
};

function SingleLocation(props) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: config.googleMapsApiKey,
    });

    // Log errors
    if (loadError) console.error(loadError);

    const [isInfowWindowOpen, setIsInfoWindowOpen] = useState(false);

    const renderMap = () => {
        return (
            <GoogleMap
                id="park-map"
                mapContainerStyle={containerStyle}
                zoom={props.zoom}
                center={props.position}
                options={{
                    styles: MapStyle,
                    streetViewControl: false,
                    mapTypeControlOptions: {
                        mapTypeIds: [],
                    },
                }}
            >
                <MapMarker key={props.id} onClick={() => setIsInfoWindowOpen(true)} position={props.position}>
                    {isInfowWindowOpen && (
                        <InfoWindow anchor={props.id} onCloseClick={() => setIsInfoWindowOpen(false)}>
                            <h5>{props.name}</h5>
                        </InfoWindow>
                    )}
                </MapMarker>
            </GoogleMap>
        );
    };

    return isLoaded ? renderMap() : 'Loading...';
}

SingleLocation.propTypes = {
    id: PropTypes.string,
    position: PropTypes.object,
    name: PropTypes.string,
    zoom: PropTypes.number,
};

SingleLocation.defaultProps = {
    id: 'park-marker',
    position: { lat: 0, lng: 0 },
    name: 'No park name set',
    zoom: 8,
};

export default SingleLocation;
