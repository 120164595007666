import { GET_OPERATOR_REGIONS, GET_REGIONS } from './action-types';
import { getRequestStatus } from '../../requests/selectors';

export const regions = (state) => state.data.regions.regions;
export const channelRegionIds = (state) => state.data.regions.channelRegionIds;
export const operatorChannelRegionIds = (state) => state.data.regions.operatorChannelRegionIds;

// Utils to be used by other selectors
export const [getRegionsIsLoading, getRegionsHasFailed, getRegionsError] = getRequestStatus(GET_REGIONS);
export const getRegionsIsLoaded = (state, channelId) => !!channelRegionIds(state)[channelId];

export const [getOperatorRegionsIsLoading, getOperatorRegionsHasFailed, getOperatorRegionsError] = getRequestStatus(
    GET_OPERATOR_REGIONS,
);
export const getOperatorRegionsIsLoaded = (state, channelId, operatorId) => {
    return !!operatorChannelRegionIds(state)[operatorId]?.[channelId];
};
