import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getParkReviews } from '../../redux/data/park/reviews/actions';
import { getHasFailed, getIsLoading, getIsLoaded } from '../../redux/data/park/reviews/selectors';

import useDataLoader from '../../hooks/useDataLoader';

export function WithParkReviews(props) {
    const getData = (dispatch, force) => getParkReviews(dispatch, props.parkId, force);
    const _hasFailed = useSelector((state) => getHasFailed(state, props.parkId));
    const _isLoaded = useSelector((state) => getIsLoaded(state, props.parkId));
    const _isLoading = useSelector((state) => getIsLoading(state, props.parkId));
    const _isReady = !!props.parkId;

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady);
}

WithParkReviews.propTypes = {
    parkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WithParkReviews;
