import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../../redux/user/actions';
import { details, updateUserError, updateUserFailed, updateUserPending } from '../../../../redux/user/selectors';
import { validateFields } from './UpdateUserForm';
import useLocalFormState from '../../../../hooks/useLocalFormState';

export const useUpdateUserFormActions = () => {
    const dispatch = useDispatch();
    return {
        updateUser: (values) => {
            return updateUser(dispatch, values);
        },
    };
};

export const useUpdateUserFormState = () => {
    const _details = useSelector(details);
    const initialFormValues = {
        title: _details.title,
        firstName: _details.firstName,
        lastName: _details.lastName,
        email: _details.email,
        addrLn1: _details.addrLn1,
        town: _details.town,
        city: _details.city,
        county: _details.county,
        country: _details.country,
        postCode: _details.postCode,
        tel: _details.tel,
        dobDay: _details.dobDay,
        dobMnth: _details.dobMnth,
        dobYr: _details.dobYr,
    };

    return {
        ...useLocalFormState(validateFields, initialFormValues),
        updateUserError: useSelector(updateUserError),
        updateUserFailed: useSelector(updateUserFailed),
        updateUserPending: useSelector(updateUserPending),
    };
};

export default useUpdateUserFormState;
