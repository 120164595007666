import {
    CHANGE_DETAIL,
    CHANGE_PARTY_DETAIL,
    SELECT_ARE_YOU_GOING,
    SELECT_EXTRA,
    SELECT_OFFERS_OPTION,
    SELECT_PAYMENT_OPTION,
    SET_PARTY_DETAILS,
} from './action-types';
import { callbackApiRequest } from '../../apiRequest';
import selfCancellingAction from '../../selfCancellingAction';
import { urls } from '../../../../config';

export const changeDetail = (dispatch, key, value) => {
    dispatch({ type: CHANGE_DETAIL, payload: { key, value } });
};

export const changePartyDetail = (dispatch, key, value) => {
    dispatch({ type: CHANGE_PARTY_DETAIL, payload: { key, value } });
};

export const selectAreYouGoing = (dispatch, key, value, user = {}) => {
    dispatch({ type: SELECT_ARE_YOU_GOING, payload: { value, user } });
};

export const selectExtra = (dispatch, key, value) => {
    dispatch({ type: SELECT_EXTRA, payload: { key, value } });
};

export const selectOffersOption = (dispatch, key, value) => {
    dispatch({ type: SELECT_OFFERS_OPTION, payload: { key, value } });
};

export const selectPaymentOption = (dispatch, key, value) => {
    dispatch({ type: SELECT_PAYMENT_OPTION, payload: value });
};

export const [setPartyDetails, cancelSetPartyDetails] = selfCancellingAction((dispatch, bookId, size, details) => {
    return callbackApiRequest(
        dispatch,
        SET_PARTY_DETAILS,
        urls.bookingSetPartyDetails,
        { bookId, 'party-size': `${size}`, ...details },
        undefined,
        'post',
    );
});
