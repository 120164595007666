import { useDispatch, useSelector } from 'react-redux';
import { createUser } from '../../../../redux/user/actions';
import { createUserError, createUserFailed, createUserPending } from '../../../../redux/user/selectors';
import { validateFields } from './CreateUserForm';
import useLocalFormState from '../../../../hooks/useLocalFormState';

export const useCreateUserFormActions = () => {
    const dispatch = useDispatch();
    return {
        createUser: (data) => {
            return createUser(dispatch, data);
        },
    };
};

export const useCreateUserFormState = () => ({
    ...useLocalFormState(validateFields),
    createUserError: useSelector(createUserError),
    createUserFailed: useSelector(createUserFailed),
    createUserPending: useSelector(createUserPending),
});

export default useCreateUserFormState;
