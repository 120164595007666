import {
    CHANGE_DETAIL,
    CHANGE_PARTY_DETAIL,
    SELECT_ARE_YOU_GOING,
    SELECT_EXTRA,
    SELECT_OFFERS_OPTION,
    SELECT_PAYMENT_OPTION,
} from './action-types';
import { CREATE_BOOKING, GET_DEPOSIT, LOAD_BOOKING } from '../booking/action-types';
import { SUCCESS } from '../../constants';

const initialState = {
    details: {},
    extras: {},
    offers: {
        emailOpt: 'N',
        smsOpt: 'N',
        postOpt: 'N',
        thirdOpt: 'N',
    },
    party: {
        areYouGoing: undefined,
        values: {},
    },
    payment: {
        paymentOption: 'deposit',
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_DETAIL:
            return {
                ...state,
                details: {
                    ...state.details,
                    [action.payload.key]: action.payload.value,
                },
            };
        case CHANGE_PARTY_DETAIL:
            return {
                ...state,
                party: {
                    ...state.party,
                    values: {
                        ...state.party.values,
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case CREATE_BOOKING + SUCCESS:
        case LOAD_BOOKING:
            return {
                ...state,
                extras: {},
            };
        case GET_DEPOSIT + SUCCESS:
            if (action.payload.response.data.data.deposit !== '') {
                return state;
            } else {
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        paymentOption: 'full',
                    },
                };
            }
        case SELECT_ARE_YOU_GOING:
            return {
                ...state,
                party: {
                    ...state.party,
                    areYouGoing: action.payload.value,
                    values:
                        action.payload.value === 'Y'
                            ? {
                                ...state.party.values,
                                ...action.payload.user,
                            }
                            : state.party.values,
                },
            };
        case SELECT_EXTRA:
            return {
                ...state,
                extras: {
                    ...state.extras,
                    [action.payload.key]: action.payload.value,
                },
            };
        case SELECT_OFFERS_OPTION:
            return {
                ...state,
                offers: {
                    ...state.offers,
                    [action.payload.key]: action.payload.value,
                },
            };
        case SELECT_PAYMENT_OPTION:
            return {
                ...state,
                payment: {
                    ...state.payment,
                    paymentOption: action.payload,
                },
            };
        default:
            return state;
    }
};

export default reducer;
