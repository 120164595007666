import { PRE_PAYMENT, POST_PAYMENT, SET_PAYMENT_USED } from './action-types';
import { apiRequest, callbackApiRequest } from '../../apiRequest';
import selfCancellingAction from '../../selfCancellingAction';
import { urls } from '../../../../config';

export const [prePayment, cancelPrePayment] = selfCancellingAction((dispatch, bookId, amount) => {
    return callbackApiRequest(
        dispatch,
        PRE_PAYMENT,
        urls.bookingPrePayment,
        { bookId, amount: `${amount}` },
        undefined,
        'post',
    );
});

export const [postPayment, cancelPostPayment] = selfCancellingAction(
    (dispatch, paymntRecId, bookId, method, orderId) => {
        return apiRequest(
            dispatch,
            POST_PAYMENT,
            urls.bookingPostPayment,
            { paymntRecId, bookId, method, orderId },
            undefined,
            'post',
        );
    },
);

export const setPaymentUsed = (dispatch, service, method) => {
    return dispatch({
        type: SET_PAYMENT_USED,
        payload: { service, method },
    });
};
