import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import CardPanel from '../../../components/CardPanel';
import WithHolidaySummary from '../../../components/Booking/WithHolidaySummary';
import WithLoadingStates from '../../../components/Booking/WithLoadingStates';

import { completeBooking, stepCompleted, stepNotCompleted } from '../../../redux/booking/booking/actions';
import { postPayment } from '../../../redux/booking/payment/actions';
import { bookingId, channelId, park, unitTypeId } from '../../../redux/booking/booking/selectors';
import { paymentAmount } from '../../../redux/booking/form/selectors';
import {
    paymntRecId,
    paymntUsed,
    paypal,
    postPayment as postPaymentData,
    postPaymentErrorData,
    postPaymentHasError,
    postPaymentIsFetching,
} from '../../../redux/booking/payment/selectors';

import { BOOKING_STEPS } from '../../../Constants';
import { tagSaleCompleted } from '../../../utils/tracking';
import t from '../../../../text';

export const PaymentAction = ({ history }) => {
    const dispatch = useDispatch();

    const _amount = useSelector(paymentAmount);
    const _bookingId = useSelector(bookingId);
    const _channelId = useSelector(channelId);
    const _park = useSelector(park);
    const _paymntRecId = useSelector(paymntRecId);
    const _paymntUsed = useSelector(paymntUsed);
    const _paypal = useSelector(paypal);
    const _postPaymentErrorData = useSelector(postPaymentErrorData);
    const _postPaymentHasError = useSelector(postPaymentHasError);
    const _postPaymentIsFetching = useSelector(postPaymentIsFetching);
    const _postPaymentData = useSelector(postPaymentData);
    const _unitTypeId = useSelector(unitTypeId);

    const [firstRun, setFirstRun] = useState(true);
    const hasError = _postPaymentHasError;
    const isLoading = _postPaymentIsFetching || (_postPaymentData && !_postPaymentData?.action);

    // Initial data load
    const loadPostPaymentData = () => {
        const method = _paymntUsed.method === 'buttons' ? 'paypal' : 'direct';
        postPayment(dispatch, _paymntRecId, _bookingId, method, _paypal.orderId);
        setFirstRun(false);
    };

    const checkForNextStep = () => {
        if (!firstRun && !_postPaymentIsFetching) {
            if (!_postPaymentHasError) {
                // If no action, payment was successful
                if (!_postPaymentData.action) {
                    stepCompleted(dispatch, BOOKING_STEPS.PAYMENT_ACTION);
                    completeBooking(dispatch);
                    tagSaleCompleted(_bookingId, _amount, _unitTypeId, _park.opId, _park.regn, _park.name, _channelId);
                    history.push('/booking/confirmation');
                    return;
                }
            } else {
                // If error was from the server (and not a connectivity issue), go back to payment step
                if (_postPaymentErrorData.status > 0) {
                    stepNotCompleted(dispatch, BOOKING_STEPS.PAYMENT);
                    history.replace({
                        pathname: '/booking/payment',
                        state: { error: true },
                    });
                }
            }
        }
    };

    // Load new pre-payment data if paypal order changes / on page refresh
    useEffect(loadPostPaymentData, [dispatch, _bookingId, _paymntRecId, _paypal.orderId, _paymntUsed.method]);

    // When we get new info about the order, check if we need to take any action
    useEffect(checkForNextStep, [
        dispatch,
        firstRun,
        history,
        _postPaymentData?.action,
        _paypal.orderId,
        _postPaymentIsFetching,
        _postPaymentHasError,
        _postPaymentErrorData?.status,
    ]);

    return (
        <WithHolidaySummary>
            <WithLoadingStates
                hasErrorFetching={_postPaymentHasError}
                isFetching={isLoading}
                loadingErrorMessage={t('booking.payment.errorLoading')}
                onRetry={loadPostPaymentData}
                spinnerMessage={t('booking.payment.processing')}
            >
                <CardPanel title={t('booking.payment.AdditionalPaymentSecurity')}>
                    {hasError && !_postPaymentErrorData.errors && (
                        <>
                            <p className="text-danger">{t('booking.payment.errorAccessingServer')}</p>
                            <Button variant="primary" onClick={loadPostPaymentData}>
                                {t('buttons.retry')}
                            </Button>
                        </>
                    )}
                </CardPanel>
            </WithLoadingStates>
        </WithHolidaySummary>
    );
};

export default PaymentAction;
