import React from 'react';
import { Col, Row } from 'react-bootstrap';
import t from '../../../text';
import OperatorCard from '../../components/Browse/OperatorCard';
import WithLoadingStates from '../../components/HOC/WithLoadingStates';
import WithOperators from '../../components/HOC/WithOperators';
import { getOperatorsByChannelId } from '../../redux/data/operators/utils';

const OperatorRow = ({ channelCode, channelName, allChannelOperatorIds, allOperators }) => {
    const results = getOperatorsByChannelId(allOperators, allChannelOperatorIds, channelCode);

    const sortAndFilterResults = (results) => {
        let output = [...results];
        output = output.filter((operator) => operator.list === 'true');
        output.sort((a, b) => +b.seq - +a.seq);
        return output;
    };

    const sortedResults = sortAndFilterResults(results);

    if (sortedResults.length === 0) return null;
    return (
        <Row>
            <Col>
                <h2>{channelName}</h2>
                <WithOperators channelId={channelCode}>
                    <WithLoadingStates
                        loadingErrorMessage={t('browse.operators.errorLoading')}
                        scrollToTop
                        useContainer
                    >
                        <Row xs={1} sm={2} md={3} xl={4}>
                            {sortedResults.map((operator) => (
                                <Col key={operator.opId}>
                                    <OperatorCard channelId={channelCode} operator={operator} />
                                </Col>
                            ))}
                        </Row>
                    </WithLoadingStates>
                </WithOperators>
            </Col>
        </Row>
    );
};

export default OperatorRow;
