import { GET_PARK_REVIEWS } from './action-types';
import { SUCCESS } from '../../../constants';

const initialState = {
    parkReviews: {},
    bubbles: ""
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PARK_REVIEWS + SUCCESS:
            return {
                ...state,
                parkReviews: {
                    ...state.parkReviews,
                    [action.payload.params.parkId]: action.payload.response.data.data.reviews,
                },
                bubbles: action.payload.response.data.data.bubbles
            };
        default:
            return state;
    }
};

export default reducer;
