import React, { memo } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PayPalButtons } from '@paypal/react-paypal-js';
import t from '../../../../../text';

// Please note: this form needs to be wrapped by <PayPalScriptProvider> somewhere for it to work:
// <PayPalScriptProvider options={{ 'client-id': clientId, currency: 'GBP' }}></PayPalScriptProvider>
export const PaypalPaymentForm = ({ onClick, onCreateOrder, onError, onSuccess }) => (
    <Form name="paypalPaymentForm" noValidate>
        <p>{t('forms.paypal.introText')}</p>
        <div style={{ maxWidth: '350px' }}>
            <PayPalButtons
                style={{ layout: 'horizontal' }}
                createOrder={onCreateOrder}
                onApprove={onSuccess}
                onError={onError}
                onClick={onClick}
                shippingPreference="NO_SHIPPING"
            />
        </div>
    </Form>
);

PaypalPaymentForm.defaultProps = {};

PaypalPaymentForm.propTypes = {
    clientId: PropTypes.string,
    onCreateOrder: PropTypes.func,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
};

export default memo(PaypalPaymentForm, (prevProps, nextProps) => prevProps.clientId === nextProps.clientId);
