import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { stepsCompleted } from '../../redux/booking/booking/selectors';

import useNextStep from './useNextStep';

export const WithBookingStepOrder = (props) => {
    const history = useHistory();
    const matches = useRouteMatch();

    const _stepsCompleted = useSelector(stepsCompleted);

    const requestedStep = matches.params.step;
    const requiredStep = useNextStep(true);

    const needRedirect = !requiredStep || (!_stepsCompleted.includes(requestedStep) && requestedStep !== requiredStep);

    useEffect(() => {
        if (needRedirect) {
            history.replace(`/booking/${requiredStep}`);
        }
    });

    return needRedirect ? null : props.children;
};

export default WithBookingStepOrder;
