import { useSelector } from 'react-redux';

import { getBookings } from '../../redux/user/actions';
import { getBookingsFailed, getBookingsLoaded, getBookingsPending } from '../../redux/user/selectors';

import useDataLoader from '../../hooks/useDataLoader';

export function WithUserBookings(props) {
    const getData = (dispatch, force) => getBookings(dispatch, force);
    const _hasFailed = useSelector((state) => getBookingsFailed(state));
    const _isLoaded = useSelector((state) => getBookingsLoaded(state));
    const _isLoading = useSelector((state) => getBookingsPending(state));
    const _isReady = true;

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady);
}

export default WithUserBookings;
