import { Children, cloneElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { selectChannel, selectPark } from '../../redux/park/actions';
import { channelId as selectedChannelId, parkId as selectedParkId } from '../../redux/park/selectors';

function WithParkSelected(props) {
    const dispatch = useDispatch();
    const { channelId, parkId } = useParams();

    const _selectedChannelId = useSelector(selectedChannelId);
    const _selectedParkId = useSelector(selectedParkId);

    const _channelNeedsSelecting = channelId && channelId !== _selectedChannelId;
    const _parkNeedsSelecting = parkId && parkId !== _selectedParkId;

    const updateSelectedParams = () => {
        if (_channelNeedsSelecting) {
            selectChannel(dispatch, channelId);
        }
        if (_parkNeedsSelecting) {
            selectPark(dispatch, parkId);
        }
    };

    useEffect(updateSelectedParams, [dispatch, channelId, parkId, _channelNeedsSelecting, _parkNeedsSelecting]);

    return Children.map(props.children || [], (child) => {
        return cloneElement(child, {
            isFetching: _channelNeedsSelecting || _parkNeedsSelecting || !!props.isFetching,
            hasErrorFetching: !!props.hasErrorFetching,
            onRetry: props.onRetry,
        });
    });
}

WithParkSelected.propTypes = {
    parkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WithParkSelected;
