import {
    SET_STATE,
    RESET_N_FILTERS,
    RESET_B_FILTERS,
    SET_CODE_WORDS_VALIDATED,
} from './action-types';

export const setState = (dispatch, key, data) => {
    dispatch({
        type: SET_STATE,
        payload: { key, data },
    });
};

export const resetNFilters = (dispatch) => {
    dispatch({ type: RESET_N_FILTERS });
};

export const resetBFilters = (dispatch) => {
    dispatch({ type: RESET_B_FILTERS });
};

export const setCodeWordsValidated = (dispatch, payload) => {
    dispatch({ type: SET_CODE_WORDS_VALIDATED, payload });
};
