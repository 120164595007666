import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../../components/PageTitle';
import ScrollToTop from '../../components/ScrollToTop';
import { config } from '../../../config';
import FAQ from '../Content/FAQ';
import ContactUs from './ContactUs';

function Help() {
    return (
        <>
            <Helmet>
                <title>Help</title>
                <link rel="canonical" href={`${config['global.siteUrl']}/help`} />
            </Helmet>
            <ScrollToTop />
            <PageTitle title="Help" />

            <Container>
                <FAQ />
                <Row className="justify-content-center">
                    <Col lg={4}>
                        <a className="btn btn-primary btn-block my-4" href="mailto:enquiries@breakfreeholidays.co.uk?body=Your%20full%20name%3A%09%0AYour%20booking%20number%2C%20if%20you%E2%80%99ve%20booked%3A%09%0AYour%20park%3A%09%0AHoliday%20date%3A%09%0AYour%20address%3A%09%0APostcode%3A%09%0AYour%20message%3A%09">
                            I have another query
                        </a>
                    </Col>
                </Row>
                <ContactUs />
            </Container>
        </>
    );
}

export default Help;
