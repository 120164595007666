import { SUCCESS } from '../../constants';
import {
    COMPLETE_BOOKING,
    CREATE_BOOKING,
    GET_DEPOSIT,
    LOAD_BOOKING,
    SET_DISCOUNT_CODE,
    SET_DISCOUNT_VALUE,
    STEP_COMPLETED,
    STEP_NOT_COMPLETED,
} from './action-types';

const initialState = {
    pendingParams: {},
    type: undefined,
    channelId: undefined,
    unitId: undefined,
    unitTypeId: undefined,
    unitPrice: undefined, // new bookings only
    totalCost: undefined, // existing bookings only
    amountPaid: undefined, // exiting bookings only
    parkId: undefined,
    arrivalDate: undefined,
    duration: undefined,
    bookingId: undefined,
    bookingFee: undefined,
    message: undefined,
    deposit: undefined,
    depositText: undefined,
    discountApplied: false,
    discountCode: '',
    discountValue: 0,
    dueDate: undefined,
    stepsCompleted: [],
    confirmationId: undefined,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_BOOKING:
            return {
                ...initialState,
                pendingParams: action.payload,
            };
        case CREATE_BOOKING + SUCCESS:
            return {
                ...state,
                pendingParams: {},
                ...state.pendingParams,
                message: action.payload.response.data.data.booking.message,
                bookingFee: action.payload.response.data.data.booking.bookingFee,
                bookingId:
                    action.payload.response.data.data.booking.bookId ||
                    action.payload.response.data.data.booking.bookID, // Hack for backend bug where we get bookID instead of bookId
            };
        case LOAD_BOOKING:
            return {
                ...initialState,
                ...action.payload,
            };
        case GET_DEPOSIT + SUCCESS:
            return {
                ...state,
                deposit: parseFloat(action.payload.response.data.data.deposit) || null,
                depositText: action.payload.response.data.data.depositMsg,
                dueDate: action.payload.response.data.data.dueDate,
            };
        case SET_DISCOUNT_CODE:
            return {
                ...state,
                discountCode: action.payload,
            };
        case SET_DISCOUNT_VALUE:
            return {
                ...state,
                discountValue: action.payload,
                discountApplied: action.payload > 0 ? true : false,
            };
        case STEP_COMPLETED:
            return {
                ...state,
                stepsCompleted: [...state.stepsCompleted, action.payload],
            };
        case STEP_NOT_COMPLETED:
            return {
                ...state,
                stepsCompleted: state.stepsCompleted.filter((item) => item !== action.payload),
            };
        case COMPLETE_BOOKING:
            return {
                ...state,
                bookingId: undefined,
                confirmationId: state.bookingId,
            };
        default:
            return state;
    }
};

export default reducer;
