import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import t from '../../../text';

export const MultipleLocationsOperatorKey = (props) => {
    const uniqueOpIds = [...new Set(props.results.map((result) => result.park.opId))];

    // TODO: filter out any id's that don't have a colour

    const operatorPin = (oid) => {
        if (!props.operatorData[oid]?.colour) return;
        return <Col key={oid} lg={3} md={4} sm={6} className="my-1">
            <i
                className="icon-map-marker h2 mr-1"
                style={{ color: props.operatorData[oid]?.colour }}
            />
            {props.operatorData[oid]?.name}
        </Col>;
    }

    return (
        <Card className="pl-3">
            <Card.Body>
                <Row>
                    {uniqueOpIds.map((operatorId) => operatorPin(operatorId))}

                    <Col lg={3} md={4} sm={6} className="my-1">
                        <i className="icon-map-marker h2 mr-1" style={{ color: '#000' }}/>
                        {t('searchResults.clickMarkerForDetails')}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

MultipleLocationsOperatorKey.propTypes = {
    operatorData: PropTypes.object,
    results: PropTypes.array,
};

export default MultipleLocationsOperatorKey;
