import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../../components/PageTitle';
import RegionCard from '../../components/Browse/RegionCard';
import ScrollToTop from '../../components/ScrollToTop';
import SearchBox from '../../components/SearchBox/SearchBox';
import WithLoadingStates from '../../components/HOC/WithLoadingStates';
import WithOperatorRegions from '../../components/HOC/WithOperatorRegions';
import WithOperators from '../../components/HOC/WithOperators';

import { selectChannel, selectOperator } from '../../redux/pages/browseOperatorRegions/actions';
import { channel, operator, processedResults } from '../../redux/pages/browseOperatorRegions/selectors';

import ColumnComponent from '../../components/Common/ColumnComponent';

import useSeoText from '../../hooks/useSeoText';
import t from '../../../text';
import TruncatedTextAlt from '../../components/TruncatedTextAlt';
import useOfferCols from '../../hooks/useOfferCols';

const OperatorRegions = () => {
    const dispatch = useDispatch();
    const { operatorId, channelCode } = useParams();

    const operatorRegions = useSelector(processedResults) || [];
    const thisChannel = useSelector(channel);
    const thisOperator = useSelector(operator);
    const [topSeoText, bottomSeoText] = useSeoText();

    useEffect(() => {
        selectChannel(dispatch, channelCode);
        selectOperator(dispatch, operatorId);
    }, [dispatch, channelCode, operatorId]);

    const [offers, offersExist] = useOfferCols();
    const { pathname } = useLocation();

    return (
        <>
            <Helmet>
                <title>{t('browse.operatorRegions.title', thisChannel?.name, thisOperator?.name)}</title>
                <link
                    rel="canonical"
                    href={`https://www.breakfreeholidays.co.uk/${pathname}`}
                />
            </Helmet>
            <ScrollToTop />
            <div className="channel-regions-page">
                <Container fluid className="bg-dark py-4">
                    <Container>
                        <SearchBox />
                    </Container>
                </Container>

                <WithOperatorRegions channelId={channelCode} operatorId={operatorId}>
                    <WithOperators channelId={channelCode}>
                        <WithLoadingStates useContainer>
                            <PageTitle
                                title={t('browse.operatorRegions.title', thisChannel?.name, thisOperator?.name)}
                            />
                            <Container>
                                <TruncatedTextAlt text={topSeoText} className="mb-4" />
                                {offersExist && <ColumnComponent columns={offers} parent="opRegions" />}
                                <Row xs={1} sm={2} md={3}>
                                    {operatorRegions.length > 0 &&
                                        operatorRegions.map((region) => (
                                            <RegionCard
                                                key={region.id}
                                                {...region}
                                                url={channelCode === 'boats' ? '/boating-holidays' : '/holidays'}
                                                channelCode={channelCode}
                                                opId={operatorId}
                                            />
                                        ))}
                                </Row>
                                <TruncatedTextAlt text={bottomSeoText} className="mb-4" />
                            </Container>
                        </WithLoadingStates>
                    </WithOperators>
                </WithOperatorRegions>
            </div>
        </>
    );
};

OperatorRegions.propTypes = {
    channelRegions: PropTypes.func,
    channelName: PropTypes.func,
    getChannelRegions: PropTypes.func,
};

export default OperatorRegions;
