import React from 'react';
import { Col, Row, Tab } from 'react-bootstrap';
import ParkCard from '../../../components/ParkCard/ParkCard';

const PopularHolidays = ({ data }) => {
    return (
        <Tab.Pane eventKey="popular-holidays">
            <Row xs={1} sm={2} xl={3}>
                {data.map((result) => (
                    <Col key={`${result.channel}-${result.park.parkId}`} className="d-flex">
                        <ParkCard
                            channelId={result.channel}
                            regionId={result.park.regn}
                            park={result.park}
                            parkRoute="holidays"
                            viewMode="grid"
                            {...result}
                        />
                    </Col>
                ))}
            </Row>
        </Tab.Pane>
    );
};

export default PopularHolidays;
