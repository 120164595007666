import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CardPanel from '../../../components/CardPanel';
import t from '../../../../text';
import WithHolidaySummary from '../../../components/Booking/WithHolidaySummary';
import WithLoadingStates from '../../../components/Booking/WithLoadingStates';
import useNextStep from '../../../components/Booking/useNextStep';

import PaymentOptionsForm from '../../../components/Forms/Booking/PaymentOptionsForm/PaymentOptionsForm';
import { usePaymentOptionsFormState } from '../../../components/Forms/Booking/PaymentOptionsForm/usePaymentOptionsForm';

import ContactPreferencesInputs from '../../../components/Forms/User/ContactPreferencesForm/ContactPreferencesInputs';
import {
    useContactPreferencesFormActions,
    useContactPreferencesFormState
} from '../../../components/Forms/User/ContactPreferencesForm/useContactPreferencesForm';

import { useLoginFormState } from '../../../components/Forms/User/LoginForm/useLoginForm';
import { loggedIn, selectedContactPreferencesInBooking } from '../../../redux/user/selectors';

import { config } from '../../../../config';
import { channelId } from '../../../redux/booking/booking/selectors';

import LoginFormView from '../../../components/Login/views/LoginFormView';
import CreateUserFormView from '../../../components/Login/views/CreateUserFormView';

import DiscountCode from '../../../components/Booking/DiscountCode';
import { useDiscountCodeState } from '../../../components/Booking/useDiscountCode';
import { showDiscountBox } from '../../../redux/booking/extras/selectors';

export const Details = ({ history, stepCompleted, bookingId }) => {
    const _channelId = useSelector(channelId);
    const nextStep = useNextStep();
    const payment = usePaymentOptionsFormState();
    const { loggingIn, loginError } = useLoginFormState();
    const _loggedIn = useSelector(loggedIn);
    const _selectedContactPreferencesInBooking = useSelector(selectedContactPreferencesInBooking);
    const { setDiscountError } = useDiscountCodeState();
    const _showDiscountBox = useSelector(showDiscountBox);
    const preferences = useContactPreferencesFormState();
    const { updateUser } = useContactPreferencesFormActions();
    const [emailOptedOut,] = useState(preferences.values.emailOpt === 'N');
    
    const handleNext = () => {
        if (_loggedIn) {
            preferences.validate();
            if (preferences.isValid()) {
                updateUser(preferences.values);
                stepCompleted();
                history.push(`/booking/${nextStep}`);
            }
        }
    };

    const nextButtonTerms = (buttonText) => (
        t(
            'booking.booking.confirmTerms',
            buttonText,
            config['urls.termsAndConditions'],
            config['urls.privacyPolicy']
        )
    );

    useEffect(() => {
        if (emailOptedOut && !_selectedContactPreferencesInBooking) {
            preferences.onChange({ emailOpt: null, smsOpt: null });
        }
    }, []);

    return (
        <WithHolidaySummary
            nextButtonTerms={_channelId === 'eurocaravan' ? nextButtonTerms("Confirm your booking") : nextButtonTerms("Continue")}
            nextButtonText={_channelId === 'eurocaravan' ? 'Confirm your booking' : 'Continue'}
            onNext={handleNext}
            disableNext={!_loggedIn}
        >
            <WithLoadingStates
                errorResponse={loginError}
                isSaving={loggingIn}
                loadingErrorMessage={t('booking.details.errorLoading')}
                savingErrorMessage={t('booking.details.errorSaving')}
            >
                {!_loggedIn && (
                    <>
                        <CardPanel accordion opened accordionId="login" title="Log In to Your Account">
                            <LoginFormView
                                onViewReset={() => window.open("/login?forgot-password")}
                                isBookingPage
                            />
                        </CardPanel>
                        <CardPanel accordion opened accordionId="create-account" title="Or Create an Account">
                            <CreateUserFormView />
                        </CardPanel>
                    </>
                )}
                {_loggedIn && (emailOptedOut || _selectedContactPreferencesInBooking) &&
                    <CardPanel accordion opened accordionId="contact-preferences" title="Your Contact Preferences">
                        <ContactPreferencesInputs
                            values={preferences.values}
                            onChange={preferences.onChange}
                            errors={preferences.errors}
                            hideTitle
                        />
                    </CardPanel>
                }
                {_showDiscountBox && <DiscountCode errorResponse={setDiscountError} bookID={bookingId} />}
                <CardPanel accordion opened accordionId="payment-options" title="Payment Options">
                    <PaymentOptionsForm
                        totalCostDeposit={payment.totalCostDeposit}
                        totalCostFull={payment.totalCostFull}
                        totalCostRemaining={payment.totalCostRemaining}
                        deposit={payment.deposit}
                        depositAvailable={payment.depositAvailable}
                        depositText={payment.depositText}
                        dueDate={payment.dueDate}
                        selectedOption={payment.values}
                        onChange={payment.onChange}
                    />
                </CardPanel>
            </WithLoadingStates>
        </WithHolidaySummary>
    );
};

export default Details;
