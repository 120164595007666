import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

export const ThingsToDo = (props) => (
    <Card className="border">
        <Card.Header className="border-bottom">
            <h2 className="m-0">Things To Do</h2>
        </Card.Header>
        <Card.Body className="p-3">
            <Row xs={1} md={2} noGutters>
                {props.ttdPosts.map((ttd, i) => {
                    return (
                        <Col key={i} className="p-3">
                            <h3 className="h4" dangerouslySetInnerHTML={{ __html: ttd.title.rendered }} />
                            <img src={ttd.acf.image} alt={ttd.title.rendered} className="w-100 mb-2" />
                            <p>{ttd.acf.description}</p>
                            <Button href={ttd.acf.url} target="_blank" block>
                                Visit Site
                            </Button>
                        </Col>
                    );
                })}
            </Row>
        </Card.Body>
    </Card>
);

export default ThingsToDo;
