import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import PartyMembersModal from '../../components/SearchResults/PartyMembersModal';

export const WithPartyMembersModal = (props) => {
    const location = useLocation();
    const [showPartySizeModal, setShowPartySizeModal] = useState(false);

    const partySizeModalNeeded = useCallback(() => {
        const query = new URLSearchParams(location.search);
        return (
            query.has('channel') &&
            query.has('region') &&
            query.has('date') &&
            query.has('nights') &&
            !query.has('adults') &&
            !query.has('children') &&
            !query.has('infants')
        );
    }, [location.search]);

    useEffect(() => {
        setShowPartySizeModal(partySizeModalNeeded());
    }, [partySizeModalNeeded]);

    return (
        <>
            {props.children}
            <PartyMembersModal show={showPartySizeModal} onClose={() => setShowPartySizeModal(false)} />
        </>
    );
};

export default WithPartyMembersModal;
