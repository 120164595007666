import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button } from 'react-bootstrap';
import TextInput from '../../../FormElements/TextInput';
import { required, email, password } from '../../../../utils/validation';
import RequestErrors from '../../../RequestErrors';
import t from '../../../../../text';

export const validateFields = (values) => {
    const errors = {};
    if (!required(values.email) || !email(values.email)) {
        errors.email = t('forms.user.invalidEmail');
    }
    if (!required(values.email)) {
        errors.email = t('forms.user.blankEmail');
    }
    if (!required(values.password) || !password(values.password)) {
        errors.password = t('forms.login.invalidPassword');
    }
    if (!required(values.password)) {
        errors.password = t('forms.login.blankPassword');
    }
    return errors;
};

export const LoginForm = (props) => (
    <Form name="loginForm" noValidate>
        <Form.Row>
            <Col md={props.isBookingPage ? "6" : "12"}>
                <TextInput
                    name="email"
                    type="email"
                    placeholder={t('forms.user.emailAddress')}
                    className="mb-3"
                    value={props.values.email}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.email}
                    validationMessage={props.errors.email}
                    attrs={{ 'aria-label': t('forms.user.emailAddress'), disabled: props.busy, autoComplete: 'username' }}
                />
            </Col>
            <Col md={props.isBookingPage ? "6" : "12"}>
                <TextInput
                    name="password"
                    type="password"
                    placeholder={t('forms.user.password')}
                    className="mb-3"
                    value={props.values.password}
                    onChange={(e) => props.onChange(e.target.name, e.target.value)}
                    invalid={!!props.errors.password}
                    validationMessage={props.errors.password}
                    attrs={{ 'aria-label': t('forms.user.password'), disabled: props.busy, autoComplete: 'current-password' }}
                />
            </Col>
        </Form.Row>
        <Form.Row className="d-flex align-items-center no-gutters">
            <Col sm={12} md={6}>
                <Button type="submit" block onClick={props.onLogin} disabled={props.busy}>
                    {props.busy ? t('global.pleaseWait') : t('buttons.login')}
                </Button>
            </Col>
            <Col md={props.isBookingPage ? "6" : "auto"} sm={12}>
                <Button variant="link" className="pl-1 pr-0" onClick={props.onViewReset}>
                    <small>{t('forms.login.forgotYourPassword')}</small>
                </Button>
            </Col>
        </Form.Row>

        {props.failure && <RequestErrors requestError={props.failure} />}

        {!props.isBookingPage && (
            <>
                <h5 className="mt-3 mb-3">{t('forms.login.notAMember')}</h5>
                <Button block variant="primary" onClick={props.onViewRegister}>
                    {t('buttons.createAnAccount')}
                </Button>
            </>
        )}
    </Form>
);

LoginForm.defaultProps = {
    busy: false,
    errors: {},
    values: {},
};

LoginForm.propTypes = {
    busy: PropTypes.bool,
    errors: PropTypes.object,
    failure: PropTypes.object,
    onChange: PropTypes.func,
    onLogin: PropTypes.func,
    onViewRegister: PropTypes.func,
    onViewReset: PropTypes.func,
    values: PropTypes.object,
};

export default LoginForm;
