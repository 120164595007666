// Selectors
export const channelId = (state) => state.search.results.params.channelId;
export const regionId = (state) => state.search.results.params.regionId;
export const arrivalDate = (state) => state.search.results.params.arrivalDate;
export const duration = (state) => state.search.results.params.duration;
export const adults = (state) => state.search.results.params.adults;
export const children = (state) => state.search.results.params.children;
export const under5s = (state) => state.search.results.params.under5s;
export const petFriendly = (state) => state.search.results.params.petFriendly;

export const requestPayload = (state) => state.search.results.requestPayload;
export const results = (state) => state.search.results.results;
export const count = (state) => state.search.results.count;

export const params = (state) => state.search.results.params;
export const pendingParams = (state) => state.search.results.pendingParams;
