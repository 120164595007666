import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';

import ContactPreferencesForm from '../../components/Forms/User/ContactPreferencesForm/ContactPreferencesForm';
import {
    useContactPreferencesFormActions,
    useContactPreferencesFormState,
} from '../../components/Forms/User/ContactPreferencesForm/useContactPreferencesForm';
import t from '../../../text';

export function PreferencesCentre() {
    const [firstRun, setFirstRun] = useState(true);
    const preferences = useContactPreferencesFormState();
    const { updateUser } = useContactPreferencesFormActions();

    const handleSubmit = (event) => {
        event.preventDefault();
        updateUser(preferences.values).request.finally(() => setFirstRun(false));
    };

    return (
        <Card>
            <Card.Header>
                <h2>{t('myAccount.preferences.title')}</h2>
            </Card.Header>
            <Card.Body className="p-3">
                <ContactPreferencesForm values={preferences.values} onChange={preferences.onChange} />
                <Button onClick={handleSubmit} disabled={preferences.updateUserPending}>
                    {preferences.updateUserPending ? 'Please wait...' : 'Update'}
                </Button>
                {!firstRun && (
                    <>
                        {preferences.updateUserFailed ? (
                            <p className="text-danger mt-3 mb-0">{t('myAccount.preferences.updateFail')}</p>
                        ) : (
                            <p className="text-success mt-3 mb-0">{t('myAccount.preferences.updateSuccess')}</p>
                        )}
                    </>
                )}
            </Card.Body>
        </Card>
    );
}

export default PreferencesCentre;
