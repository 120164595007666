import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getOperatorRegions } from '../../redux/data/regions/actions';
import {
    getOperatorRegionsHasFailed,
    getOperatorRegionsIsLoading,
    getOperatorRegionsIsLoaded,
} from '../../redux/data/regions/selectors';

import useDataLoader from '../../hooks/useDataLoader';

export function WithOperatorRegions(props) {
    const getData = (dispatch, force) => getOperatorRegions(dispatch, props.channelId, props.operatorId, force);
    const _hasFailed = useSelector((state) => getOperatorRegionsHasFailed(state, props.channelId, props.operatorId));
    const _isLoaded = useSelector((state) => getOperatorRegionsIsLoaded(state, props.channelId, props.operatorId));
    const _isLoading = useSelector((state) => getOperatorRegionsIsLoading(state, props.channelId, props.operatorId));
    const _isReady = !!props.channelId && !!props.operatorId;

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady);
}

WithOperatorRegions.propTypes = {
    channelId: PropTypes.string,
    operatorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WithOperatorRegions;
