import React from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import DateInput from '../../../FormElements/DateInput';
import SelectInput from '../../../FormElements/SelectInput';
import TextInput from '../../../FormElements/TextInput';

import titles from '../../../../Data/Titles.json';
import t from '../../../../../text';
import { arrayFromXtoY } from '../../../../utils/utils';

const infantAges = arrayFromXtoY(0, 4);
const childAges = arrayFromXtoY(5, 17);
const adultAges = arrayFromXtoY(18, 99);

export const PartyDetailsFormRow = ({ values, errors, onChange, ageGroup, suffix, locked, dob }) => {
    let ages;

    if (ageGroup === 'under5s') ages = infantAges;
    if (ageGroup === 'children') ages = childAges;
    if (ageGroup === 'adults') ages = adultAges;

    return (
        <Form.Row className="mb-4 mb-sm-0">
            <Col sm={12} md={3}>
                <SelectInput
                    name={`pt${suffix}`}
                    value={values[`pt${suffix}`] || ''}
                    attrs={{ 'aria-label': t('forms.party.title'), disabled: locked }}
                    onChange={onChange ? (e) => onChange(e.target.name, e.target.value) : undefined}
                    invalid={!!errors[`pt${suffix}`]}
                    validationMessage={errors[`pt${suffix}`]}
                >
                    <option value="" disabled></option>
                    {Object.keys(titles).map((key) => (
                        <option key={key} value={key}>
                            {titles[key]}
                        </option>
                    ))}
                </SelectInput>
            </Col>

            <Col sm={12} md={3}>
                <TextInput
                    name={`pi${suffix}`}
                    placeholder={t('forms.party.initial')}
                    value={values[`pi${suffix}`]}
                    attrs={{ 'aria-label': t('forms.party.initial'), maxLength: 1, disabled: locked }}
                    onChange={onChange ? (e) => onChange(e.target.name, e.target.value) : undefined}
                    invalid={!!errors[`pi${suffix}`]}
                    validationMessage={errors[`pi${suffix}`]}
                />
            </Col>

            <Col sm={12} md={3}>
                <TextInput
                    name={`pn${suffix}`}
                    placeholder={t('forms.party.surname')}
                    value={values[`pn${suffix}`]}
                    attrs={{ 'aria-label': t('forms.party.surname'), disabled: locked }}
                    onChange={onChange ? (e) => onChange(e.target.name, e.target.value) : undefined}
                    invalid={!!errors[`pn${suffix}`]}
                    validationMessage={errors[`pn${suffix}`]}
                />
            </Col>

            <Col sm={12} md={3}>
                {dob ? (
                    <DateInput
                        name={`dob${suffix}`}
                        placeholder={t('forms.party.dob')}
                        value={values[`dob${suffix}`]}
                        attrs={{ 'aria-label': t('forms.party.dob'), autoComplete: 'off', disabled: locked }}
                        onChange={onChange}
                        invalid={!!errors[`dob${suffix}`]}
                        validationMessage={errors[`dob${suffix}`]}
                        ageGroup={ageGroup}
                        dob
                    />
                ) : (
                    <SelectInput
                        name={`pa${suffix}`}
                        value={values[`pa${suffix}`] || ''}
                        attrs={{ 'aria-label': t('forms.party.age'), disabled: locked }}
                        onChange={onChange ? (e) => onChange(e.target.name, e.target.value) : undefined}
                        invalid={!!errors.title}
                        validationMessage={errors.title}
                    >
                        <option value="" disabled>
                            {t('forms.party.age')}
                        </option>
                        {ages.map((age) => (
                            <option key={age} value={age}>
                                {age}
                            </option>
                        ))}
                    </SelectInput>
                )}
            </Col>
        </Form.Row>
    );
};

PartyDetailsFormRow.defaultProps = {
    values: {},
    errors: {},
    locked: false,
    ageGroup: 'adults',
};

PartyDetailsFormRow.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ageGroup: PropTypes.string,
    locked: PropTypes.bool,
};

export default PartyDetailsFormRow;
