import React, { memo } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import t from '../../../text';

export const PriceBreakdown = ({ className, title, amountPaid, totalCost, remainingBalance }) => (
    <Card className={`bf-filter price-breakdown overflow-hidden ${className || ''}`}>
        <Card.Header className="h3">{title}</Card.Header>
        <Card.Body>
            <ul className="list-unstyled mb-2">
                <li>
                    <strong className="d-inline-block mr-1">{t('booking.breakdown.totalCost')} </strong>
                    <strong className="d-inline-block">£{parseFloat(totalCost).toFixed(2)}</strong>
                </li>
                <li>
                    <strong className="d-inline-block mr-1">{t('booking.breakdown.amountPaid')} </strong>
                    <strong className="d-inline-block">£{parseFloat(amountPaid).toFixed(2)}</strong>
                </li>
            </ul>
        </Card.Body>
        <Card.Body className="bg-secondary">
            <p className="h4 font-weight-normal">{t('booking.breakdown.amountToPayToday')}</p>
            <p className="h1 font-weight-bold">£{parseFloat(remainingBalance).toFixed(2)}</p>
        </Card.Body>
    </Card>
);

PriceBreakdown.defaultProps = {
    amountPaid: 0,
    remainingBalance: 0,
    title: t('booking.breakdown.priceBreakdown'),
    totalCost: 0,
};

PriceBreakdown.propTypes = {
    amountPaid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    remainingBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    totalCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(PriceBreakdown);
