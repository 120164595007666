import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PageTitle from '../components/PageTitle';
import ScrollToTop from '../components/ScrollToTop';

function NotFound() {
    return (
        <>
            <Helmet>
                <title>Page not found</title>
            </Helmet>
            <ScrollToTop />
            <PageTitle title="Error 404: Page not found..." />
            <Container className="my-5">
                <Row>
                    <Col>
                        <h1>
                            <span role="img" aria-label="Oopsie">
                                &#x1f635;
                            </span>{' '}
                            Oops!
                        </h1>
                        <p>
                            The page you are looking for currently doesn&apos;t exist, has been changed or was removed.
                        </p>
                        <p>But don&apos;t panic, return to the homepage by clicking the button below.</p>
                        <Link to="/" className="btn btn-primary">
                            Return to Homepage
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default NotFound;
