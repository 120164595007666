import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';

import InputNumber from '../../../FormElements/InputNumber';
import t from '../../../../../text';

export const PartyMembersForm = (props) => (
    <Form noValidate autoComplete="off">
        <Form.Row className="mb-4">
            <Col xs={12}>
                <strong>{t('forms.party.checkPartySizeDetails')}</strong>
            </Col>
        </Form.Row>

        <Form.Row className="mb-4 d-flex align-items-center">
            <Col xs={8}>
                <p className="m-0">{t('forms.party.adults')}</p>
            </Col>
            <Col xs={4}>
                <InputNumber
                    id={props.id + '-adults'}
                    value={props.adults}
                    min={1}
                    max={props.maxGuests - (props.children + props.under5s)}
                    onChange={(num) => props.onChangeAdults(num)}
                />
            </Col>
        </Form.Row>

        <Form.Row className="mb-4 d-flex align-items-center">
            <Col xs={8}>
                <p className="m-0">{t('forms.party.children')}</p>
            </Col>
            <Col xs={4}>
                <InputNumber
                    id={props.id + '-children'}
                    value={props.children}
                    max={props.maxGuests - (props.adults + props.under5s)}
                    onChange={(num) => props.onChangeChildren(num)}
                />
            </Col>
        </Form.Row>

        <Form.Row className="mb-4 d-flex align-items-center">
            <Col xs={8}>
                <p className="m-0">{t('forms.party.infants')}</p>
            </Col>
            <Col xs={4}>
                <InputNumber
                    id={props.id + '-under5s'}
                    value={props.under5s}
                    max={props.maxGuests - (props.adults + props.children)}
                    onChange={(num) => props.onChangeUnder5s(num)}
                />
            </Col>
        </Form.Row>
    </Form>
);

PartyMembersForm.propTypes = {
    id: PropTypes.string,
    maxGuests: PropTypes.number,
    adults: PropTypes.number,
    children: PropTypes.number,
    under5s: PropTypes.number,
    onChangeAdults: PropTypes.func,
    onChangeChildren: PropTypes.func,
    onChangeUnder5s: PropTypes.func,
};

PartyMembersForm.defaultProps = {
    id: 'PartyMembersForm',
    maxGuests: 10,
};

export default PartyMembersForm;
