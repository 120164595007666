import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Button, Form, Row } from 'react-bootstrap';
import ChannelSelect from './ChannelSelect';
import RegionSelect from './RegionSelect';
import DateDurationSelect from './DateDurationSelect';
import GuestsSelect from './GuestsSelect';
import { channels } from '../../redux/data/channels/selectors';
import {
    channelId,
    regionId,
    currentRegions,
    regionsLoading,
    regionsNeedToBeLoaded,
    adults,
    children,
    under5s,
    arrivalDate,
    duration,
    petFriendly,
    params,
    requestParams,
} from '../../redux/search/form/selectors';
import {
    selectChannel,
    selectRegion,
    selectGuestsAdults,
    selectGuestsChildren,
    selectGuestsUnder5s,
    selectDate,
    selectDuration,
    selectPetFriendly,
    startSearch,
    cancelSearch,
    resetArrival,
} from '../../redux/search/form/actions';
import { cancelGetResults } from '../../redux/search/results/actions';
import { getRegions } from '../../redux/data/regions/actions';
import t from '../../../text';
import { resetBFilters, resetNFilters, setState } from '../../redux/appState/actions';

const NoPopupSearchBox = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const selectedChannelId = useSelector(channelId);
    const selectedRegionId = useSelector(regionId);

    const _regionsLoading = useSelector(regionsLoading);
    const _regionsNeedToBeLoaded = useSelector(regionsNeedToBeLoaded);
    const _currentRegions = useSelector(currentRegions) || [];

    const sortedRegions = _currentRegions.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });

    const _params = useSelector(params);
    const _requestParams = useSelector(requestParams);
    const _petFriendly = useSelector(petFriendly);
    const { parkId } = useParams();

    const _nFilterArray = useSelector((state) => state.appState.nFiltersStateArray);
    const _bFilterArray = useSelector((state) => state.appState.bFiltersStateArray);

    const [allowSearchAll, setAllowSearchAll] = useState(false);

    const dealOfTheWeek = location.pathname.search('/deal-of-the-week/') !== -1;
    const doFullSearch = !dealOfTheWeek || allowSearchAll;

    // Util functions
    const getRegionLabel = () => {
        if (selectedChannelId === 'boats') return t('search.route');
        return t('search.region');
    };

    const fetchSearchResults = () => {
        cancelGetResults(); // Cancel last request to get search results
        cancelSearch(); // Cancel last request to start a search
        if (props.parkId) {
            startSearch(dispatch, _params, _requestParams, props.parkId);
        } else {
            startSearch(dispatch, _params, _requestParams, doFullSearch ? undefined : parkId);
        }
    };

    const updateRoute = () => {
        let searchQuery = Object.keys(_requestParams)
            .filter((key) => typeof _requestParams[key] !== 'undefined')
            .filter((key) => key !== 'partysize')
            .filter((key) => doFullSearch || (key !== 'channel' && key !== 'region'))
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(_requestParams[key])}`)
            .join('&');
        if (props.parkId) searchQuery = searchQuery + '&park=' + props.parkId;

        if (doFullSearch) {
            history.push({ pathname: '/results', search: searchQuery });
        } else {
            history.push({ pathname: location.pathname, search: searchQuery });
        }
    };

    const submitAndCloseDropdown = (value, func, selectFunc) => {
        func(dispatch, value);
        setTimeout(() => {
            selectFunc(false);
        }, 650);
    };

    // Channels
    const [showChannelSelect, setShowChannelSelect] = useState(false);
    const handleChannelToggle = () => setShowChannelSelect(!showChannelSelect);
    const handleChannelSelect = (event) => {
        const channelChosen = event.target.value;
        const previousChannel = selectedChannelId;
        submitAndCloseDropdown(channelChosen, selectChannel, setShowChannelSelect);
        calculateShouldResetArrival(previousChannel, channelChosen);
    };

    // Regions
    const [showRegionSelect, setShowRegionSelect] = useState(false);
    const handleRegionToggle = () => setShowRegionSelect(!showRegionSelect);
    const handleRegionSelect = (event) => {
        const previousChannel = selectedChannelId;
        submitAndCloseDropdown(event.target.value, selectRegion, setShowRegionSelect);
        calculateShouldResetArrival(previousChannel);
    };

    // Dates
    const [showDatePanel, setShowDatePanel] = useState(false);
    const handleDateToggle = () => setShowDatePanel(!showDatePanel);
    const handleDateChange = (date, modifiers) => {
        if (modifiers.disabled) return; // Do nothing if date is disabled.
        submitAndCloseDropdown(date, selectDate, setShowDatePanel);
    };

    // Pet friendly
    const handlePetFriendlyToggle = (event) => {
        selectPetFriendly(dispatch, event.target.checked);
    };

    const handleSearch = () => {
        updateRoute();
        fetchSearchResults();
        resetNFilters(dispatch, _nFilterArray);
        resetBFilters(dispatch, _bFilterArray);
        setState(
            dispatch,
            'searchResultsPetFriendlyFilters',
            _petFriendly ? ['y'] : ['n'],
        );
    };

    // Load regions, if required
    useEffect(() => {
        if (_regionsNeedToBeLoaded) {
            getRegions(dispatch, selectedChannelId);
        }
    }, [dispatch, _regionsNeedToBeLoaded, selectedChannelId]);

    // Select default region if it has been wiped out (happens after chaning channel)
    useEffect(() => {
        if (
            !_regionsNeedToBeLoaded &&
            !_regionsLoading &&
            Object.keys(_currentRegions).length &&
            selectedChannelId &&
            !selectedRegionId
        ) {
            selectRegion(dispatch, sortedRegions[0].id);
        }
    });

    const calculateShouldResetArrival = (previousChannel, channelSelected = null) => {
        // reset arrival if user just selected cottages
        if (channelSelected === 'ukcottages') return resetArrival(dispatch);

        // reset arrival if user just came off of cottages or switched regions whilst on ukcottages
        if (selectedChannelId === 'ukcottages') return resetArrival(dispatch);

        // prevents the possibility of a race case bug
        if (previousChannel === 'ukcottages') return resetArrival(dispatch);
    };

    return (
        <>


            <Container className="search-box" id="search-modal">
                <Form>
                    <Row xs={1}>
                        <Col lg={props.isInPopup ? 12 : 10}>
                            <Row>
                                <Col
                                    md={props.isInPopup ? 12 : 6}
                                    lg={props.isInPopup ? 12 : 3}
                                >
                                    <ChannelSelect
                                        id="searchbox-channel"
                                        textStyle={props.text}
                                        label={t('search.holidayType')}
                                        channels={useSelector(channels)}
                                        selectedValue={selectedChannelId}
                                        whitelist={[
                                            'ukpromo',
                                            'eupromo',
                                            'ukcaravan',
                                            'eurocaravan',
                                            'uktouring',
                                            'uklodge',
                                            'boats',
                                            'ukcottages',
                                        ]}
                                        onChange={handleChannelSelect}
                                        onToggle={handleChannelToggle}
                                        show={showChannelSelect}
                                        disabled={!doFullSearch}
                                    />
                                </Col>
                                <Col md={props.isInPopup ? 12 : 6} lg={props.isInPopup ? 12 : 3}>
                                    <RegionSelect
                                        id="searchbox-region"
                                        textStyle={props.text}
                                        label={getRegionLabel()}
                                        channelSelected={!!selectedChannelId}
                                        regions={sortedRegions}
                                        regionsLoading={_regionsNeedToBeLoaded || _regionsLoading}
                                        selectedValue={selectedRegionId}
                                        onChange={handleRegionSelect}
                                        onToggle={handleRegionToggle}
                                        show={showRegionSelect}
                                        disabled={!doFullSearch}
                                    />
                                </Col>
                                <Col md={props.isInPopup ? 12 : 6} lg={props.isInPopup ? 12 : 3}>
                                    <DateDurationSelect
                                        id="searchbox-date-duration"
                                        channelId={selectedChannelId}
                                        textStyle={props.text}
                                        label={t('search.durationAndArrivalDate')}
                                        duration={parseInt(useSelector(duration))}
                                        arrivalDate={useSelector(arrivalDate)}
                                        onDurationChange={(e) => selectDuration(dispatch, parseInt(e.target.value))}
                                        onDateChange={handleDateChange}
                                        onToggle={handleDateToggle}
                                        show={showDatePanel}
                                        disabled={!doFullSearch}
                                    />
                                </Col>
                                <Col md={props.isInPopup ? 12 : 6} lg={props.isInPopup ? 12 : 3}>
                                    <GuestsSelect
                                        id="searchbox-guests"
                                        textStyle={props.text}
                                        adults={useSelector(adults)}
                                        children={useSelector(children)}
                                        under5s={useSelector(under5s)}
                                        onAdultsChange={(num) => selectGuestsAdults(dispatch, num)}
                                        onChildrenChange={(num) => selectGuestsChildren(dispatch, num)}
                                        onUnder5sChange={(num) => selectGuestsUnder5s(dispatch, num)}
                                        selectedChannel={selectedChannelId}
                                    />
                                </Col>
                                {_params.channelId !== 'eurocaravan' && (
                                    <Col
                                        xs={12}
                                        className="d-flex flex-column flex-md-row align-items-center justify-content-between"
                                    >
                                        {dealOfTheWeek ? (
                                            <div className="d-flex align-items-center justify-content-end justify-content-md-start w-100 w-sm-auto mb-1 mb-md-0">
                                                <label
                                                    htmlFor="allowSearchAll"
                                                    className="pet-friendly cursor-pointer order-md-1 my-0"
                                                >
                                                    {t('search.letMeSearch')}
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    name="allowSearchAll"
                                                    id="allowSearchAll"
                                                    className="cursor-pointer mr-3 ml-2 my-0 ml-md-0 mr-md-2 order-md-0"
                                                    checked={allowSearchAll}
                                                    onChange={() => setAllowSearchAll(!allowSearchAll)}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}

                                        <div className={`d-flex align-items-center justify-content-${props.isInPopup ? 'start' : 'end'} w-100 w-sm-auto`}>
                                            <label
                                                htmlFor="petFriendly"
                                                className={`pet-friendly mr-2 my-0 cursor-pointer${props.isInPopup ? ' text-darker font-weight-normal' : ''}`}
                                            >
                                                {t('search.showMePetFriendly')}
                                            </label>
                                            <input
                                                type="checkbox"
                                                name="petFriendly"
                                                id="petFriendly"
                                                className="cursor-pointer mr-3 mr-md-0"
                                                checked={_petFriendly}
                                                onChange={handlePetFriendlyToggle}
                                            />
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Col>

                        <Col>
                            <Form.Label>&nbsp;</Form.Label>
                            <Button
                                block
                                variant="secondary"
                                onClick={handleSearch}
                                disabled={
                                    !_params.channelId || !_params.regionId || !_params.arrivalDate || !_params.duration || _params.adults === 0
                                }
                            >
                                {t('search.search')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    );
};

NoPopupSearchBox.defaultProps = {
    text: 'white',
    bg: 'dark',
    isInPopup: false,
};

export default NoPopupSearchBox;
