export const getExtraDataById = (extraData, unitTypeId) => extraData[unitTypeId];
export const getExtraDataByParkId = (extraData, parkExtraDataIds, parkId) => {
    return parkExtraDataIds[parkId]?.map((id) => extraData[id]);
};

export const getUnitTypeById = (unitTypes, unitTypeId) => unitTypes[unitTypeId];

export const getAllUnitTypeDataById = (unitTypes, extraData, unitTypeId) => {
    const _extraData = getExtraDataById(extraData, unitTypeId) || {};
    const _unitTypeData = getUnitTypeById(unitTypes, unitTypeId);
    return { ..._unitTypeData, ..._extraData };
};
