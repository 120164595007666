import React from 'react';
import { config, images } from '../../config';

function Logo(props) {
    return (
        <img
            src={props.variant === 'dark' ? props.logoDark : props.logoLight}
            height={props.height}
            className={props.className}
            alt={props.alt}
        />
    );
}

Logo.defaultProps = {
    alt: config['global.brandName'],
    variant: 'light',
    logoLight: images.logoLight,
    logoDark: images.logoDark,
    size: 'sm',
    height: 32,
    className: '',
};

export default Logo;
