import { useSelector } from 'react-redux';

import { getDeposit } from '../../redux/booking/booking/actions';
import {
    bookingId,
    depositHasError,
    depositIsFetching,
    depositIsLoaded,
    type,
} from '../../redux/booking/booking/selectors';
import useDataLoader from '../../hooks/useDataLoader';

export function WithDeposit(props) {
    const _bookingId = useSelector(bookingId);
    const _bookingType = useSelector(type);

    const getData = (dispatch) => getDeposit(dispatch, _bookingId);
    const _isLoaded = useSelector((state) => depositIsLoaded(state));
    const _isLoading = useSelector((state) => depositIsFetching(state));
    const _hasFailed = useSelector((state) => depositHasError(state));
    const _isReady = _bookingId && _bookingType === 'new';

    return useDataLoader(props, getData, _hasFailed, _isLoaded, _isLoading, _isReady);
}

export default WithDeposit;
