import React from 'react';
import { Card } from 'react-bootstrap';

function Charges(props) {
    return (
        <Card className="border">
            <Card.Header className="border-bottom">
                <h2 className="m-0">Charges</h2>
            </Card.Header>
            <Card.Body className="p-3">
                <div
                    dangerouslySetInnerHTML={{
                        __html: props.content.replace(/&quot;/g, '"').replace(/<a\s/g, '<a target="_blank"'),
                    }}
                ></div>
            </Card.Body>
        </Card>
    );
}

export default Charges;
