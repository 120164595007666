import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { count, params } from '../../redux/search/results/selectors/selectors';
import { currentRegion } from '../../redux/search/results/selectors/results';

import Loading from '../Loading';
import t from '../../../text';

export const WithSearchResultsLoadingStates = (props) => {
    const _count = useSelector(count);
    const _params = useSelector(params);
    const _region = useSelector(currentRegion);

    if (props.hasErrorFetching) {
        return (
            <Container className="mt-3 mb-5">
                <Row>
                    <Col xs={12}>
                        <p className="text-danger">
                            <i className="icon-close text-danger mr-2"></i>
                            {t('searchResults.errorLoadingResults')}
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    } else if (props.isFetching || props.isSearching) {
        return (
            <Loading
                count={props.isSearchRequestPending ? 0 : _count}
                date={props.isSearchRequestPending ? undefined : _params.arrivalDate}
                duration={props.isSearchRequestPending ? undefined : _params.duration}
                regionName={props.isSearchRequestPending ? undefined : _region?.name}
                status={props.isSearchRequestPending ? 'Starting...' : 'Loading...'}
            />
        );
    }
    return props.children;
};

export default WithSearchResultsLoadingStates;
