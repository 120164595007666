import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CardPanel from '../../components/CardPanel';
import ChangePasswordForm from '../../components/Forms/User/ChangePasswordForm/ChangePasswordForm';
import {
    useChangePasswordFormActions,
    useChangePasswordFormState,
} from '../../components/Forms/User/ChangePasswordForm/useChangePasswordForm';

export const ChangePasswordPage = () => {
    const [firstRun, setFirstRun] = useState(false);
    const [success, setSuccess] = useState(false);

    const { changePassword } = useChangePasswordFormActions();
    const {
        values,
        onChange,
        validate,
        isValid,
        errors,
        changePasswordError,
        changePasswordPending,
    } = useChangePasswordFormState();

    const handleSubmit = (event) => {
        event.preventDefault();
        setSuccess(false);
        setFirstRun(false);
        validate();

        if (isValid()) {
            changePassword(values.email, values.passwd, values.resetCode)
                .request.then(() => {
                    setSuccess(true);
                })
                .catch(() => {
                    /* Do nothing on failure, form will react to redux state */
                });
        }
    };

    const resetState = () => {
        setFirstRun(true);
        setSuccess(false);
    };

    // Reset any messages when form is first opened (including re-opened)
    useEffect(resetState, []);

    return (
        <Container className="my-5">
            <Row>
                <Col sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
                    <CardPanel title="Change password">
                        {success ? (
                            <>
                                <div className="text-center my-2">
                                    <i className="icon-check h2 text-success"></i>
                                    <p className="mt-2">
                                        Your password has been changed. Please <Link to="/login">click here</Link> to
                                        log in.
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <p>
                                    Please enter your details below. Your reset code can be found in the email that was
                                    sent to you.
                                </p>
                                <ChangePasswordForm
                                    busy={changePasswordPending}
                                    errors={firstRun ? undefined : errors}
                                    failure={firstRun ? undefined : changePasswordError}
                                    onChange={onChange}
                                    onSubmit={handleSubmit}
                                    values={values}
                                />
                            </>
                        )}
                    </CardPanel>
                </Col>
            </Row>
        </Container>
    );
};

export default ChangePasswordPage;
