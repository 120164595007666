import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import CardPanel from '../CardPanel';
import { storeDiscountCode, storeDiscountValue } from '../../redux/booking/booking/actions';
import { discountCode, discountApplied } from '../../redux/booking/booking/selectors';
import { useDiscountCodeActions, useDiscountCodeState } from './useDiscountCode';
import { required } from '../../utils/validation';
import t from '../../../text';
import TextInput from '../FormElements/TextInput';

export const validateField = (values) => {
    const errors = {};
    if (!required(values.discountCode)) {
        errors.discountCode = t('forms.booking.blankDiscountCode');
    }
    return errors;
};

function DiscountCode(props) {
    const [showDiscountCodeSuccess, setShowDiscountCodeSuccess] = useState(false);
    const [discountAppliedState, setDiscountAppliedState] = useState(false);
    const { setDiscount } = useDiscountCodeActions();
    const { setDiscountError, isValid, validate, values, errors, onChange } = useDiscountCodeState();
    const reduxDiscountCode = useSelector(discountCode);
    const _discountApplied = useSelector(discountApplied);
    const [queryDiscountCode, setQueryDiscountCode] = useState(values.discountCode);
    const dispatch = useDispatch();

    const checkApplyButtton = () => {
        const { discountCode: defaultDiscountCode } = values;
        const changed = reduxDiscountCode !== defaultDiscountCode;
        return changed ? false : true;
    }

    const handleApplyDiscountCode = (code) => {
        validate();
        if (isValid()) {
            storeDiscountCode(dispatch, code);
            setDiscount(props.bookID, code)
                .request.then((resp) => {
                    if (resp.payload.response.data.data !== undefined) {
                        const discountValue = resp.payload.response.data.data.discountValue || 0;
                        if (!_discountApplied) storeDiscountValue(dispatch, discountValue);
                    }
                    setShowDiscountCodeSuccess(true);
                })
                .catch(() => {
                    // Do nothing upon error, we'll react to error flags in redux state
                });
        }
    };

    useEffect(() => {
        setQueryDiscountCode(reduxDiscountCode);
    }, [reduxDiscountCode]);

    useEffect(() => {
        setDiscountAppliedState(_discountApplied)
    }, []);

    return (
        <CardPanel accordion opened accordionId="discount-code" title="Discount Code">
            <Row>
                <Col md={12}>
                    {discountAppliedState && (
                        <Alert variant="success" className="text-success light p-3">Your discount has been applied.</Alert>
                    )}
                </Col>
                <Col sm={12} md={6} className="p-2">
                    <TextInput
                        type="text"
                        name="discountCode"
                        placeholder="Discount Code"
                        disabled={_discountApplied}
                        invalid={!!errors.discountCode}
                        value={queryDiscountCode}
                        onChange={(e) => {
                            onChange(e.target.name, e.target.value);
                            setQueryDiscountCode(e.target.value)
                        }}
                        validationMessage={errors.discountCode}
                    />
                </Col>
                <Col sm={12} md={6} className="align-items-end p-2">
                    <Button
                        block
                        onClick={() => { handleApplyDiscountCode(values.discountCode) }}
                        disabled={_discountApplied || checkApplyButtton()}
                    >
                        Apply Code
                    </Button>
                </Col>
            </Row>
            {setDiscountError && checkApplyButtton() && Array.isArray(setDiscountError.errors) && (
                <Alert variant="danger" className="light text-danger p-3 mt-3">
                    {setDiscountError.errors.map((error) => (
                        <p key={error.code} className="mb-0">
                            {`${error.message}${error.additional ? ' (' + error.additional + ')' : ''}`}
                        </p>
                    ))}
                </Alert>
            )}
            {showDiscountCodeSuccess && (
                <Row>
                    <Col>
                        <Alert variant="success" className="text-success mt-3 mb-0 light p-3">
                            <Row noGutters>
                                <Col md={8}>
                                    Discount code{' '}
                                    <code className="text-success">
                                        <strong>{values.discountCode}</strong>
                                    </code>{' '}
                                    applied.
                                </Col>
                            </Row>
                        </Alert>
                    </Col>
                </Row>
            )}
        </CardPanel>
    );
}

export default DiscountCode;
