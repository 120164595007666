const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^$*.[\]{}()?\-"!@#%&/,><':;|_~`])[A-Za-z\d^$*.[\]{}()?\-"!@#%&/,><':;|_~`]{8,}$/;
const POSTCODE_REGEX = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z][A-Z]/i;
const DATE_REGEX = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/; // tests for dd/mm/yyyy

export const email = (val) => !val || EMAIL_REGEX.test(val);
export const integer = (val) => !val || /^[0-9]+$/.test(val);
export const password = (val) => !val || PASSWORD_REGEX.test(val);
export const postcode = (val) => !val || POSTCODE_REGEX.test(val);
export const required = (val) => !!val;
export const telephone = (val) => !val || /^[0-9 ]+$/.test(val);
export const dateValidator = (val) => !val || DATE_REGEX.test(val);
