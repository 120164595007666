import { Children, cloneElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export function useDataLoader(props, getData, hasFailed, isLoaded, isLoading, isReady, extraProps, allowFailure) {
    const dispatch = useDispatch();

    const isFetching = (isReady && !isLoaded && !hasFailed) || isLoading;

    const load = (ignoreFail = false) => {
        if (isReady && !isLoaded && !isLoading && (!hasFailed || ignoreFail)) {
            getData(dispatch, ignoreFail);
        }
    };

    const retry = () => {
        load(true);
        if (props.onRetry) props.onRetry();
    };

    useEffect(load);

    // Now we need to inject props into the child element(s)
    return Children.map(props.children || [], (child) => {
        return cloneElement(child, {
            ...props,
            ...child.props,
            isFetching: isFetching || !!props.isFetching,
            hasErrorFetching: (hasFailed && !allowFailure) || !!props.hasErrorFetching,
            onRetry: retry,
            ...(extraProps ? extraProps({ hasFailed, isLoaded, isLoading, isReady, isFetching }) : {}),
        });
    });
}

export default useDataLoader;
