import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export const PaypalFieldContainer = (props) => (
    <Form.Group className={props.groupClassName} controlId={props.controlId}>
        {props.label && (
            <div className="form-label" aria-hidden>
                {props.label}
            </div>
        )}

        <div className={`${props.className} ${props.invalid ? 'is-invalid' : ''}`} id={props.containerId}>
            {/* PayPal will insert field here */}
        </div>

        {props.invalid && <Form.Control.Feedback type="invalid">{props.validationMessage}</Form.Control.Feedback>}
    </Form.Group>
);

PaypalFieldContainer.defaultProps = {
    groupClassName: '',
    labelClassName: 'form-label',
    className: 'form-control paypal-field',
};

PaypalFieldContainer.propTypes = {
    groupClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    className: PropTypes.string,
    containerId: PropTypes.string,
    controlId: PropTypes.string,
    label: PropTypes.string,
    invalid: PropTypes.bool,
    validationMessage: PropTypes.string,
};

export default PaypalFieldContainer;
