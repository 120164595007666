import React from 'react';
import PropTypes from 'prop-types';

function Review(props) {
    return (
        <>
            <div className="d-flex align-items-center mb-2">
                <img src={props.bubbles} alt={props.rating} />
                <p className="font-weight-bold mb-0">
                    {props.name}
                    <span className="font-weight-normal text-muted"> - {props.date}</span>
                </p>
            </div>
            <p>{props.review}</p>
        </>
    );
}

Review.propTypes = {
    date: PropTypes.string,
    name: PropTypes.string,
    rating: PropTypes.number,
    review: PropTypes.string,
};

export default Review;
