import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Confirmation from './Confirmation/container';
import Details from './Details/container';
import Extras from './Extras/container';
import Payment from './Payment/Payment';
import PaymentAction from './PaymentAction/PaymentAction';

import ScrollToTop from '../../components/ScrollToTop';
import WithBooking from '../../components/Booking/WithBooking';
import WithBookingLoadingStates from '../../components/Booking/WithBookingLoadingStates';
import WithBookingStepOrder from '../../components/Booking/WithBookingStepOrder';
import WithDeposit from '../../components/Booking/WithDeposit';
import WithParkDetails from '../../components/HOC/WithParkDetails';
import WithUnitTypesExtraData from '../../components/HOC/WithUnitTypesExtraData';

import { parkId } from '../../redux/booking/booking/selectors';
import { BOOKING_STEPS } from '../../Constants';
import ProgressBar from '../../components/Booking/ProgressBar';

const BookingRoutes = () => (
    <Switch>
        <Route path={`/booking/${BOOKING_STEPS.EXTRAS}`} component={Extras} exact />
        <Route path={`/booking/${BOOKING_STEPS.DETAILS}`} component={Details} exact />
        <Route path={`/booking/${BOOKING_STEPS.PAYMENT}`} component={Payment} exact />
        <Route path={`/booking/${BOOKING_STEPS.PAYMENT_ACTION}`} component={PaymentAction} exact />
        <Redirect to={`/booking/${BOOKING_STEPS.EXTRAS}`} />
    </Switch>
);

export const Booking = (props) => {
    const location = useLocation();
    const _parkId = useSelector(parkId);

    return (
        <>
            <Helmet>
                <title>Booking</title>
            </Helmet>
            <ScrollToTop />
            <div className='booking-page'>
                <ProgressBar />

                {props.match.params.step === 'confirmation' ? (
                    <Route path={'/booking/confirmation'} component={Confirmation} exact />
                ) : (
                    <WithBooking>
                        <WithDeposit>
                            <WithParkDetails parkId={_parkId}>
                                <WithUnitTypesExtraData parkId={_parkId}>
                                    <WithBookingLoadingStates prevPath={location.state?.prevPath}>
                                        <WithBookingStepOrder>
                                            <BookingRoutes />
                                        </WithBookingStepOrder>
                                    </WithBookingLoadingStates>
                                </WithUnitTypesExtraData>
                            </WithParkDetails>
                        </WithDeposit>
                    </WithBooking>
                )}
            </div>
        </>
    );
};

export default Booking;
