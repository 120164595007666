import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CreateUserForm from '../../Forms/User/CreateUserForm/CreateUserForm';
import RedEyeUserRegistrationTag from '../../RedEye/RedEyeUserRegistrationTag';
import { useCreateUserFormActions, useCreateUserFormState } from '../../Forms/User/CreateUserForm/useCreateUserForm';

function Login(props) {
    const {
        values,
        onChange,
        validate,
        isValid,
        errors,
        createUserError,
        createUserPending,
    } = useCreateUserFormState();
    const { createUser } = useCreateUserFormActions();
    const [firstRun, setFirstRun] = useState(true); // Used to hide error messages if just opened
    const [doTagging, setDoTagging] = useState(false);
    useEffect(() => {
        setFirstRun(true);
    }, []);

    const handleRegister = (event) => {
        event.preventDefault();
        setFirstRun(false);
        validate();
        if (isValid()) {
            setDoTagging(true);
            fetch('/api/logout') // Fixes 'Already logged in'.
                .then(() => {
                    createUser(values)
                    .request.catch(() => {})
                    .finally(() => {
                        setDoTagging(false);
                    });
                })
        }
    };

    return (
        <>
            <CreateUserForm
                busy={createUserPending}
                errors={firstRun ? undefined : errors}
                failure={firstRun ? undefined : createUserError}
                onBack={props.onBack}
                onChange={onChange}
                onSubmit={handleRegister}
                values={values}
            />
            {doTagging && <RedEyeUserRegistrationTag details={values} />}
        </>
    );
}

Login.propTypes = {
    onBack: PropTypes.func,
};

export default Login;
