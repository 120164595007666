import { GET_PARKS } from './action-types';
import { GET_INIT_DATA } from '../../init/action-types';
import { SUCCESS } from '../../constants';
import { processPark } from './utils';
import { arrayToObject } from '../../../utils/utils';

const initialState = {
    parks: {},
    regionParkIds: {},
    townList: {},
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INIT_DATA + SUCCESS:
            if (action.payload.response.data.data.parks) {
                let parks = { ...state.parks };
                let regionParkIds = { ...state.regionParkIds };

                Object.keys(action.payload.response.data.data.parks).forEach((region) => {
                    regionParkIds[region] = [];
                    action.payload.response.data.data.parks[region].forEach((park) => {
                        parks[park.parkId] = processPark(park);
                        regionParkIds[region].push(park.parkId);
                    });
                });

                return {
                    ...state,
                    parks,
                    regionParkIds,
                };
            }
            return state;
        case GET_PARKS + SUCCESS:
            return {
                ...state,
                parks: {
                    ...state.parks,
                    ...arrayToObject(
                        action.payload.response.data.data.parks.map((park) => processPark(park)),
                        'parkId',
                    ),
                },
                regionParkIds: {
                    ...state.regionParkIds,
                    [action.payload.params.region]: action.payload.response.data.data.parks.map((park) => park.parkId),
                },
                townList: {
                    ...state.townList,
                    ...action.payload.response.data.data.townList,
                },
            };
        default:
            return state;
    }
};

export default reducer;
