import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ExtrasFormOption from './ExtrasFormOption';
import t from '../../../../../text';

const sortExtras = (extras) => {
    const sortByOrder = (a, b) => {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    };

    return extras.sort(sortByOrder);
}

const addOrdersToExtras = (extras) => {
    const groupOrders = new Map([
        ["Insurance", 1],
        ["Pets", 2],
        ["Dining", 3],
        ["Early Check In", 4],
        ["Infant", 5]
    ]);

    return extras.map(value => ({
        ...value,
        order: groupOrders.get(value.group) || 6
    }));
}

const groupExtras = (extras) => {
    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };

    return groupBy(extras, 'group');
}

export const ExtrasForm = (props) => {
    const [groupedExtras, setGroupedExtras] = useState(undefined);

    useEffect(() => {
        const extrasWithOrders = addOrdersToExtras(props.extras);
        const sortedExtras = sortExtras(extrasWithOrders);
        setGroupedExtras(groupExtras(sortedExtras));
    }, [props.extras]);

    return (
        <>
            {props.loading ? (
                t('global.loading')
            ) : (
                <>
                    <p>{t('forms.extras.introText')}</p>
                    {groupedExtras && Object.keys(groupedExtras).map((key) => {
                        const extras = groupedExtras[key];
                        return (
                            <ExtrasFormOption key={extras[0].code} className="mt-4" onChange={props.selectExtra} values={extras} />
                        )
                    })}
                </>
            )}
        </>
    )
};

ExtrasForm.defaultProps = {
    loading: false,
    extras: {},
};

ExtrasForm.propTypes = {
    loading: PropTypes.bool,
    extras: PropTypes.array,
    selectExtra: PropTypes.func,
};

export default ExtrasForm;
