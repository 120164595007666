import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import DayPicker from 'react-day-picker';
import { Form, Dropdown } from 'react-bootstrap';
import { getShortDateFormat, getMonthYearFromDate, getDayAndMonthNameFromDate, DDMMYYYYToDate, getDayNameFromDate } from '../../utils/date';
import t from '../../../text';

import { useDispatch, useSelector } from 'react-redux';
import { channelId, regionId, duration } from '../../redux/search/form/selectors';
import { config, urls } from '../../../config';

const getNightsOptions = (channelId) => {
    switch (channelId) {
        case 'ukpromo':
            return [3, 4];
        case 'eupromo':
            return ["3/4", "7"];
        default:
            return [3, 4];
    }
};

// "2021-04-25" => new Date(2021, 3, 25)
// This is required by the react day picker api
/* const formatStringDatesToNewDates = (dates) => {
    return dates.map((date) => {
        const dateSplit = date.split('-');
        return new Date(+dateSplit[0], +dateSplit[1] - 1, +dateSplit[2]);
    });
}; */
// "13/03/2023" => new Date(2023, 3, 13)
// This is the dates format received from /getpromodata, which then gets reformatted to the data format used by react day picker api
const formatStringDatesToNewDatesV2InString = (dates) => {
    return dates.map((date) => {
        const dateSplit = date.split('/');
        return new Date(+dateSplit[2], +dateSplit[1] - 1, +dateSplit[0]).toDateString();
    });
};

const getUnavailableDaysDefault = (duration = 0) => {
    switch (duration) {
        case 3:
            return [0, 1, 2, 3, 4, 6];
        case 4:
            return [0, 2, 3, 4, 5, 6];
        case 7:
            return [0, 2, 3, 4, 6];
        default:
            return [0, 1, 2, 3, 4, 5, 6]; // Otherwise disable all days
    }
};

export const DateDurationSelect = (props) => {
    const dispatch = useDispatch();

    const [firstRun, setFirstRun] = useState(true);

    const [availableDateStrings, setAvailableDateStrings] = useState([]);

    useEffect(() => {
        for (const key in props.availableDates) {
            if (props.availableDates.hasOwnProperty(key) && props.availableDates[key].length > 0 && key === props.selectedRegion) {
                const availableDatesInSingleArr = props.availableDates[key];
                const formattedStringDates = formatStringDatesToNewDatesV2InString(availableDatesInSingleArr);
                setAvailableDateStrings(formattedStringDates);
            }
        }
    }, [props.availableDates, props.selectedRegion, dispatch]);

    useEffect(() => {
        getDateAndDurationText();
    }, [props.arrivalDate, props.duration]);

    if (firstRun) {
        setFirstRun(false);
    }

    const getDateAndDurationText = (arrivalDate, duration) => {
        if (duration && arrivalDate) {
            if (arrivalDate === 'all-dates') return t('search.allDates');
            return t('search.dateNights', getShortDateFormat(new Date(arrivalDate)), duration);
        } else {
            return t('search.selectADate');
        }
    };

    return (
        <Form.Group controlId={props.id}>
            <Form.Label className={`text-${props.textStyle}`}>{props.label}</Form.Label>
            <Dropdown show={props.show} onToggle={props.onToggle} drop="down">
                <Dropdown.Toggle
                    as="button"
                    type="button"
                    className="toggle-popup is-calendar"
                    role="button"
                    tabIndex="0"
                    disabled={props.disabled} // add || isCalendarDisabled when reactivated
                >
                    <span>{getDateAndDurationText(props.arrivalDate, props.duration)}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="a-popup no-max-height p-0">
                  {props.channelId !== 'ukpromo' && (
                    <div className="bg-light border-bottom p-3">
                        <h5>{t('search.stayingForHowLong')}</h5>
                        <Form.Group controlId={props.id + '-nights'} className="mb-0">
                            <Form.Control
                                as="select"
                                value={parseInt(props.duration)}
                                onChange={props.onDurationChange}
                            >
                                <option value="" disabled>
                                    {t('search.numberOfNights')}
                                </option>
                                {getNightsOptions(props.channelId).map((val) => (
                                    <option key={val} value={val}>
                                        {t('search.xNights', val)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>)}
                    <div className="p-3">
                        <h5>{t('search.selectAnArrivalDate')}</h5>
                        <DayPicker
                            fromMonth={new Date()}
                            onDayClick={props.onDateChange}
                            initialMonth={props.arrivalDate ? new Date(props.arrivalDate) : undefined}
                            selectedDays={new Date(props.arrivalDate)}
                            disabledDays={(day) => availableDateStrings.indexOf(day.toDateString()) === -1}
                        />
                    </div>
                </Dropdown.Menu>
            </Dropdown>
            {props.showErrorMessage && (
                <Form.Control.Feedback
                    type="invalid"
                    className="col-12 col-lg-12"
                    style={{ display: 'block', paddingLeft: "0px"}}
                >
                    Please select a date
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

DateDurationSelect.defaultProps = {};

DateDurationSelect.propTypes = {
    id: PropTypes.string,
    channelId: PropTypes.string,
    textStyle: PropTypes.string,
    label: PropTypes.string,
    duration: PropTypes.number,
    arrivalDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    onDurationChange: PropTypes.func,
    onDateChange: PropTypes.func,
    onToggle: PropTypes.func,
    availableDates: PropTypes.object,
    show: PropTypes.bool,
    disabled: PropTypes.bool,
    showErrorMessage: PropTypes.bool,
    allRegionsSelected: PropTypes.bool
};

export default DateDurationSelect;
