import { SELECT_CHANNEL } from './action-types';

export const initialState = {
    channelId: undefined,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_CHANNEL:
            return {
                ...state,
                channelId: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
