import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { config } from '../../config';

export default function OnRouteChange() {
    const { pathname } = useLocation();

    useEffect(() => {
        const AWINExclusionList = ['/booking/payment', '/booking/confirmation']; // Add to this if AWIN is not to fire.

        if (config['awin.enable']) {
            const triggerAWIN = () => {
                if (!window.AWIN) {
                    console.info('No AWIN on window yet, trying again...');
                    return;
                } else {
                    console.info('Running AWIN...');
                    clearInterval(CheckForAWIN);
                    !AWINExclusionList.includes(pathname) ? window.AWIN.Tracking.run() : console.info('AWIN Disabled');
                }
            };
            let CheckForAWIN = setInterval(triggerAWIN, 99);
        }
    }, [pathname]);

    return null;
}
