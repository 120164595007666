import React from 'react';
import PropTypes from 'prop-types';
import A from '../A';

function AdBanner({ image, link, position }) {
    return (
        <A href={link} className="text-decoration-none d-block mb-4">
            <img src={image} alt="Ad banner" width="100%" height={position === "top" ? "150px" : "auto"} style={{ objectFit: "cover" }} />
        </A>
    );
}


AdBanner.propTypes = {
    image: PropTypes.string,
    link: PropTypes.string,
    position: PropTypes.string,
};

AdBanner.defaultProps = {
    image: "",
    link: "",
    position: "side",
};

export default AdBanner;
