import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import ParkCard from '../../components/ParkCard/ParkCard';
import ParksListFilterView from '../../components/ParksListFilterView/ParksListFilterView';
import RedEyeSearchResultsTag from '../../components/RedEye/RedEyeSearchResultsTag';
import RedEyeStartSearchTag from '../../components/RedEye/RedEyeStartSearchTag';
import ScrollToTop from '../../components/ScrollToTop';
import SearchBox from '../../components/SearchBox/SearchBox';
import WithAutoSearchFromParams from '../../components/SearchResults/WithAutoSearchFromParams';
import WithOperators from '../../components/HOC/WithOperators';
import WithParks from '../../components/HOC/WithParks';
import WithPartyMembersModal from '../../components/SearchResults/WithPartyMembersModal';
import WithRegions from '../../components/HOC/WithRegions';
import WithSearchResults from '../../components/SearchResults/WithSearchResults';
import WithSearchResultsLoadingStates from '../../components/SearchResults/WithSearchResultsLoadingStates';

import { selectDate } from '../../redux/park/actions';
import { currentRegion, processedResults } from '../../redux/search/results/selectors/results';
import { arrivalDate } from '../../redux/search/form/selectors';
import { channelId, params } from '../../redux/search/results/selectors/selectors';
import t from '../../../text';
import { urls } from '../../../config';

const FetchTravelGuide = lazy(() => import('../../components/SearchResults/FetchTravelGuide'));

const SearchResults = () => {
    const [topAdData, setTopAdData] = useState();
    const [sideAdsData, setSideAdsData] = useState();
    const dispatch = useDispatch();
    const _channelId = useSelector(channelId);
    const _params = useSelector(params);
    const originalDate = useSelector(arrivalDate);
    const parkRoute = `${_channelId === 'boats' ? 'boat' : 'park'}-result`;
    const region = useSelector(currentRegion);
    const searchResults = useSelector((state) => processedResults(state)) || [];
    const title = t('searchResults.title', searchResults.length, region?.name);

    useEffect(() => {
        //  If a user comes back to this page, reset the selected holiday date used on the park page
        // (the user may have chosen an alternative date)
        selectDate(dispatch, originalDate);
    });

    useEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/ad-banners?filter[meta_value]=${_channelId}&_fields=acf`,
            { signal: controller.signal },
        )
            .then((response) => response.json())
            .then((data) => {
                setSideAdsData(data.filter((ad) => ad.acf.position === "side"));
                setTopAdData(data.filter((ad) => ad.acf.position === "top")[0]);
            })
            .catch((e) => {
                console.error('Could not fetch the content.', e);
            });
        return () => {
            controller.abort();
        };
    }, [_channelId]);

    return (
        <WithAutoSearchFromParams>
            <WithPartyMembersModal>
                <Helmet>
                    <title>{t('searchResults.page.title', region?.name)}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <ScrollToTop />
                <Container fluid className="bg-dark py-4">
                    <Container>
                        <SearchBox />
                    </Container>
                </Container>

                <WithRegions channelId={_params.channelId}>
                    <WithOperators channelId={_params.channelId}>
                        <WithParks regionId={_params.regionId}>
                            <WithSearchResults>
                                <WithSearchResultsLoadingStates>
                                    <ParksListFilterView
                                        id="searchResults"
                                        title={_channelId ? title : t('searchResults.page.title.alt')}
                                        channelId={_channelId}
                                        regionId={region?.id}
                                        results={searchResults}
                                        topAd={topAdData}
                                        sideAds={sideAdsData}
                                        parkRoute={parkRoute}
                                        generateOutput={(result, viewMode) => (
                                            <ParkCard
                                                key={result.parkUrn}
                                                channelId={_channelId}
                                                regionId={region?.id}
                                                parkRoute={parkRoute}
                                                viewMode={viewMode}
                                                {...result}
                                            />
                                        )}
                                        allowSorting
                                    />
                                    <RedEyeSearchResultsTag />
                                    <Suspense
                                        fallback={
                                            <div className="text-center">{t('travelGuide.checkingForTravelGuide')}</div>
                                        }
                                    >
                                        <FetchTravelGuide region={region} />
                                    </Suspense>
                                </WithSearchResultsLoadingStates>
                            </WithSearchResults>
                        </WithParks>
                    </WithOperators>
                </WithRegions>
            </WithPartyMembersModal>

            <RedEyeStartSearchTag />
        </WithAutoSearchFromParams>
    );
};

export default SearchResults;
