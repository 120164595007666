import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

function Reset() {
    const deleteReduxSessionStorage = () => {
        if (sessionStorage.getItem('redux')) {
            console.info('Deleteing sessionStorage.redux...', sessionStorage.getItem('redux').length);
            sessionStorage.removeItem('redux');
        } else {
            console.info('Deleted sessionStorage.redux. Redirecting...');
            clearInterval(runDelete);
            document.location.href = '/';
        }
    };

    const runDelete = setInterval(deleteReduxSessionStorage, 99);

    return (
        <>
            <Helmet>
                <title>Reset Data</title>
            </Helmet>
            <Container className="my-5">
                <Row>
                    <Col>
                        <h1>Application data reset</h1>
                        <hr />
                        <p>
                            All application data has now been deleted. You will be redirected to the homepage shortly,
                            please wait for the page to refresh.
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Reset;
