import React from 'react';
import RedEye from '../../../components/RedEye/RedEye';
import { useSelector } from 'react-redux';

export const LoggedInView = () => {
    const redEyeUrl = useSelector((state) => state.user.details.redeyeUrl);

    return (
        <>
            <div className="text-center h4 text-success my-5">
                <i className="icon-check h2 text-success"></i>
                <p>You&apos;re logged in!</p>
            </div>
            <RedEye
                src={redEyeUrl}
                params={{
                    login_location: window.location.href.split('//')[1],
                    login_url: window.location.href.split('//')[1],
                }}
            />
        </>
    );
};

export default LoggedInView;
