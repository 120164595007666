import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BookingCard from '../../components/MyAccount/BookingCard';
import WithLoadingStates from '../../components/HOC/WithLoadingStates';
import WithUserBookings from '../../components/MyAccount/WithUserBookings';

import { loadBooking } from '../../redux/booking/booking/actions';
import { DDMMYYYYToDate } from '../../utils/date';
import t from '../../../text';

export const Bookings = ({ bookings }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const onPayBalance = (booking) => {
        loadBooking(
            dispatch,
            booking.bookId,
            booking.channelId,
            booking.parkId,
            booking.unitId,
            booking.unitTypeId,
            booking.totalCost,
            booking.amountPaid,
            DDMMYYYYToDate(booking.holidayDate),
            booking.nights,
        );
        history.push('/booking');
    };

    return (
        <WithUserBookings>
            <WithLoadingStates>
                {bookings.length === 0 ? (
                    <>
                        <h2>{t('myAccount.bookings.noBookings.title')}</h2>
                        <p>{t('myAccount.bookings.noBookings.text')}</p>
                    </>
                ) : (
                    bookings.map((booking) => (
                        <BookingCard booking={booking} onPayBalance={onPayBalance} key={booking.bookId} />
                    ))
                )}
            </WithLoadingStates>
        </WithUserBookings>
    );
};

export default Bookings;
