import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import A from '../A';

export const HeaderNavLink = ({ label, href, external }) => {
    if (external) {
        return (
            <A href={href} className="nav-link">
                {label}
            </A>
        );
    }
    return (
        <NavLink to={href} exact className="nav-link" activeClassName="active">
            {label}
        </NavLink>
    );
};

HeaderNavLink.propTypes = {
    label: PropTypes.string,
    href: PropTypes.string,
    external: PropTypes.bool,
};

export default HeaderNavLink;
