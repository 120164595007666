import { GET_OPERATOR_REGIONS, GET_REGIONS } from './action-types';
import { apiRequest, checkRequestNeeded } from '../../apiRequest';
import { urls } from '../../../../config';
import {
    getOperatorRegionsHasFailed,
    getOperatorRegionsIsLoaded,
    getOperatorRegionsIsLoading,
    getRegionsHasFailed,
    getRegionsIsLoaded,
    getRegionsIsLoading,
} from './selectors';

export const getOperatorRegions = (dispatch, channelId, operatorId, force = false) => {
    dispatch((d, getState) => {
        const state = getState();
        const isLoaded = getOperatorRegionsIsLoaded(state, channelId, operatorId);
        const isLoading = getOperatorRegionsIsLoading(state, `${channelId}/${operatorId}`);
        const hasFailed = getOperatorRegionsHasFailed(state, `${channelId}/${operatorId}`);

        if (checkRequestNeeded(true, isLoading, isLoaded, hasFailed, force)) {
            return apiRequest(
                d,
                GET_OPERATOR_REGIONS,
                urls.regions,
                { channel: channelId, opId: operatorId },
                `${channelId}/${operatorId}`,
            );
        }
    });
};

export const getRegions = (dispatch, channelId, force = false) => {
    dispatch((d, getState) => {
        const state = getState();
        const isLoaded = getRegionsIsLoaded(state, channelId);
        const isLoading = getRegionsIsLoading(state, channelId);
        const hasFailed = getRegionsHasFailed(state, channelId);

        if (checkRequestNeeded(true, isLoading, isLoaded, hasFailed, force)) {
            return apiRequest(d, GET_REGIONS, urls.regions, { channel: channelId }, channelId);
        }
    });
};
