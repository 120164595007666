import React from 'react';
import { useSelector } from 'react-redux';
import { channelId, details, parkId } from '../../redux/park/selectors';
import { operators } from '../../redux/data/operators/selectors';
import { slugify } from '../../utils/string';
import { config } from '../../../config';
import { Helmet } from 'react-helmet-async';
import ParkTitle from '../../components/ParkPage/ParkTitle';
import ParkGallery from '../../components/ParkPage/ParkGallery';

export const ParkTitleAndGallery = ({searchResult, scrollToParkInfo}) => {
    const _channelId = useSelector(channelId);
    const _parkDetails = useSelector(details);
    const _parkId = useSelector(parkId);
    const _operators = useSelector(operators);

    const isBoat = _channelId === 'boats';

    const _parkName = useSelector((state) => state.data.park.details.parkDetails[_parkId].name);
    const _regionId = useSelector((state) => state.data.park.details.parkDetails[_parkId].regn);

    // Images - Fixes loop issue in slider
    let images = [..._parkDetails.imgs];
    if (images.length === 2) images = [...images, ...images];
    if (images.length === 1) images = [...images,...images,...images,...images,];

    // Create JSON Schema
    const structuredJSON = JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': 'LocalBusiness',
        name: _parkDetails.name,
        image: _parkDetails.imgs[0],
        description: _parkDetails.overview,
        address: {
            '@type': 'PostalAddress',
            addressLocality: _parkDetails.sAdd.split(',')[0],
            addressRegion: _parkDetails.sAdd.split(',')[1],
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: _parkDetails.lat,
            longitude: _parkDetails.lon,
        },
        brand: {
            '@type': 'Brand',
            name: _operators[_parkDetails.opId].name,
        },
    });

    return (
        <>
            <Helmet>
                <title>{_parkName}</title>
                <link
                    rel="canonical"
                    href={`https://www.breakfreeholidays.co.uk/${
                        isBoat ? 'boat' : 'park'
                    }/${_channelId}/${_regionId}/${_parkId}/${slugify(_parkName || '')}`}
                />
                <meta name="description" content={_parkDetails.overview} />
                <script type="application/ld+json">{structuredJSON}</script>
            </Helmet>
            <ParkTitle
                name={_parkDetails.name}
                location={_parkDetails.sAdd}
                position={_parkDetails.pos}
                opid={_parkDetails.opId}
                sku={!isBoat ? _parkId : undefined}
                searchResult={searchResult}
                showReviewsBadge={!isBoat && config['global.feefoReviews'] && config['parks.showFeefoRating']}
                scrollToParkInfo={scrollToParkInfo}
            />
            <ParkGallery
                images={images}
                description={_parkDetails.overview}
                video={!isBoat ? _parkDetails.vid : undefined}
                parkname={!isBoat ? _parkDetails.name : undefined}
            />
        </>
    );
};

export default ParkTitleAndGallery;
