import { useState, useEffect } from 'react';
import { urls, config } from '../../config';

export const useOfferCols = () => {
    const [offers, setOffers] = useState([]);

    const currentURLPath = window.location.pathname.slice(1);

    useEffect(() => {
        const controller = new AbortController();
        fetch(
            `${urls.cms}/offers/?filter[meta_key]=use_on_site&filter[meta_key]=url_path&filter[meta_compare]=LIKE&filter[meta_value]=${config.siteCode}&filter[meta_value]=${currentURLPath}&_fields=acf`,
            { signal: controller.signal }
        )
            .then((data) => data.json())
            .then((data) => {
                if (data.length > 0) setOffers(data[0].acf.offers || []);
            })
            .catch((e) => {
                console.error('Could not fetch the content.', e);
            });

        return () => {
            controller.abort();
        };
    }, [currentURLPath]);

    return [offers, !!offers.length];
};

export default useOfferCols;
