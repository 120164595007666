import React from 'react';
import { InfoWindow } from '@react-google-maps/api';
import PropTypes from 'prop-types';

import ParkLink from '../ParkCard/ParkLink';

import { isSingleResult } from '../../utils/utils';
import t from '../../../text';

export const MultipleLocationsInfoWindow = (props) => {
    return (
        <InfoWindow
            anchor={props.anchor}
            position={props.result.park.pos}
            onCloseClick={props.onClose}
            className="gmiw"
        >
            <>
                <p className="lead mb-1">{props.result.park.name}</p>
                <p>{props.result.park.sAdd}</p>
                {!props.removeMapCardMid && (
                    <p className="font-weight-bold">
                        {props.result.dateMatchCount || props.result.altDateCount}{' '}
                        {t(
                            'searchResults.holidaysFoundOn',
                            !!props.result.dateMatchCount,
                            isSingleResult(props.result.dateMatchCount, props.result.altDateCount),
                        )}
                        <br />
                        {t('searchResults.from')} &pound;
                        {props.result.minPrice || props.result.altDateMinPrice}
                    </p>
                )}
                <ParkLink
                    channelId={props.channelId}
                    regionId={props.regionId}
                    park={props.result.park}
                    parkRoute={props.parkRoute}
                    url={props.result.url}
                    specialButtonClass="btn-primary btn-sm"
                />
            </>
        </InfoWindow>
    );
};

MultipleLocationsInfoWindow.propTypes = {
    anchor: PropTypes.object,
    channelId: PropTypes.string,
    regionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    result: PropTypes.object,
    parkRoute: PropTypes.string,
    onClose: PropTypes.func,
    removeMapCardMid: PropTypes.bool,
};

export default MultipleLocationsInfoWindow;
