import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import ScrollToTop from '../ScrollToTop';
import Spinner from '../Spinner';
import t from '../../../text';

const Wrap = ({ children, useContainer }) => (
    <>
        {useContainer ? (
            <Container className="mt-3 mb-5">
                <Row>
                    <Col xs="12">{children}</Col>
                </Row>
            </Container>
        ) : (
            <>{children}</>
        )}
    </>
);

export const WithLoadingStates = (props) => (
    <>
        {props.isFetching ? (
            <Wrap useContainer={props.useContainer}>
                {props.scrollToTop && <ScrollToTop />}
                <Spinner message={props.spinnerMessage} />
            </Wrap>
        ) : (
            <>
                {!props.hasErrorFetching ? (
                    props.children
                ) : (
                    <Wrap useContainer={props.useContainer}>
                        <p className="text-danger">
                            <i className="icon-close text-danger mr-2"></i>
                            {props.loadingErrorMessage || t('global.errorLoading')}
                        </p>
                        <button className="btn btn-primary" onClick={props.onRetry}>
                            {t('buttons.retry')}
                        </button>
                    </Wrap>
                )}
            </>
        )}
    </>
);

WithLoadingStates.defaultProps = {
    hasErrorFetching: false,
    isFetching: false,
    useContainer: false,
};

WithLoadingStates.propTypes = {
    hasErrorFetching: PropTypes.bool,
    isFetching: PropTypes.bool,
    loadingErrorMessage: PropTypes.string,
    onRetry: PropTypes.func,
    scrollToTop: PropTypes.bool,
    spinnerMessage: PropTypes.string,
    useContainer: PropTypes.bool,
};

export default WithLoadingStates;
