import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { selectChannel, selectRegion } from '../../redux/search/form/actions';
import { channelId, regionId } from '../../redux/search/form/selectors';

export const WithUpdatedSearchParams = (props) => {
    const dispatch = useDispatch();
    const [firstRun, setFirstRun] = useState(true);

    const _selectedChannelId = useSelector(channelId);
    const _selectedRegionId = useSelector(regionId);

    useEffect(() => {
        if (firstRun) {
            if (props.channelId && props.channelId !== _selectedChannelId) {
                selectChannel(dispatch, props.channelId);
            }

            if (props.regionId && props.regionId !== _selectedRegionId) {
                selectRegion(dispatch, props.regionId);
            }

            setFirstRun(false);
        }
    }, [_selectedChannelId, _selectedRegionId, props.channelId, props.regionId, dispatch, firstRun]);

    return props.children || null;
};

WithUpdatedSearchParams.propTypes = {
    channelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    regionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default WithUpdatedSearchParams;
